import { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserDetails } from "../reducers/authSlice";
import useIsAuthorized from "../hooks/useIsAuthorized";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

interface ProtectedRouteProps {
  roles: string[];
  children: ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  roles = [],
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useSelector(getUserDetails);
  const isAuthorized = useIsAuthorized(roles);

  useEffect(() => {
    if (!isAuthenticated) {			
      navigate("/login");
    } else if (!isAuthorized) {
      navigate("/")
    } 
  }, [isAuthenticated, isAuthorized]);

  return <>{isAuthorized ? children : <></>}</>;
};

export default ProtectedRoute;
