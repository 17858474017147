import React from 'react';

const Terms = () => {
  return (
    <div style={{ backgroundColor: '#ffffff', padding: '42pt' }}>
      <p style={{ fontSize: '26pt', fontFamily: 'Arial', fontWeight: '700', margin: '0 0 12pt' }}>
        Terms and Conditions
      </p>
      <p style={{ fontSize: '15pt', fontFamily: 'Arial', color: '#666666', margin: '0 0 16pt' }}>
        1. Introduction
      </p>
      <p style={{ fontSize: '11pt', fontFamily: 'Arial', margin: '0 0 12pt' }}>
        Welcome to TutorForSure. By accessing and using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully before using our services.
      </p>
      <p style={{ fontSize: '12pt', fontFamily: 'Arial', color: '#000000', margin: '0 0 12pt', fontWeight: '700' }}>
        2. Services Provided
      </p>
      <p style={{ fontSize: '11pt', fontFamily: 'Arial', margin: '0 0 12pt' }}>
        TutorForSure provides a platform for connecting students and tutors for home tuition services. We act solely as an intermediary to facilitate these connections.
      </p>
      <p style={{ fontSize: '12pt', fontFamily: 'Arial', color: '#000000', margin: '0 0 12pt', fontWeight: '700' }}>
        3. User Responsibilities
      </p>
      <ul style={{ margin: '0 0 12pt', paddingLeft: '20pt', fontSize: '11pt', fontFamily: 'Arial' }}>
        <li>
          <strong>Tutors:</strong> By registering as a tutor, you agree to provide accurate information about your qualifications, experience, and availability. You are responsible for delivering the tuition services agreed upon with the student or their guardian.
        </li>
        <li>
          <strong>Students/Guardians:</strong> By registering as a student or guardian, you agree to provide accurate information regarding your tutoring needs and to communicate clearly with the tutor about expectations and scheduling.
        </li>
      </ul>
      <p style={{ fontSize: '12pt', fontFamily: 'Arial', color: '#000000', margin: '0 0 12pt', fontWeight: '700' }}>
        4. Payment Terms
      </p>
      <ul style={{ margin: '0 0 12pt', paddingLeft: '20pt', fontSize: '11pt', fontFamily: 'Arial' }}>
        <li>
          Payment for tuition services must be made directly between the student/guardian and the tutor. TutorForSure does not handle payments and is not responsible for any disputes regarding payments.
        </li>
      </ul>
      <p style={{ fontSize: '12pt', fontFamily: 'Arial', color: '#000000', margin: '0 0 12pt', fontWeight: '700' }}>
        5. About Results
      </p>
      <p style={{ fontSize: '11pt', fontFamily: 'Arial', margin: '0 0 12pt' }}>
        TutorForSure does not guarantee any specific outcomes or academic results from the tutoring services provided. The effectiveness of the tuition depends on various factors, including the student’s participation and the tutor’s methods.
      </p>
      <p style={{ fontSize: '12pt', fontFamily: 'Arial', color: '#000000', margin: '0 0 12pt', fontWeight: '700' }}>
        6. Limitation of Liability
      </p>
      <ul style={{ margin: '0 0 12pt', paddingLeft: '20pt', fontSize: '11pt', fontFamily: 'Arial' }}>
        <li>
          TutorForSure is not responsible for the character, behavior, or conduct of either the tutor or the student. We do not conduct background checks on tutors or students and are not liable for any incidents or disputes that may arise.
        </li>
        <li>
          TutorForSure is not liable for any direct, indirect, incidental, or consequential damages that may result from the use of our website or the services provided by tutors registered on our platform.
        </li>
      </ul>
      <p style={{ fontSize: '12pt', fontFamily: 'Arial', color: '#000000', margin: '0 0 12pt', fontWeight: '700' }}>
        7. Termination
      </p>
      <p style={{ fontSize: '11pt', fontFamily: 'Arial', margin: '0 0 12pt' }}>
        We reserve the right to terminate or suspend your access to our website at our discretion, without notice, for conduct that we believe violates these terms or is harmful to other users of the site.
      </p>
      <p style={{ fontSize: '12pt', fontFamily: 'Arial', color: '#000000', margin: '0 0 12pt', fontWeight: '700' }}>
        8. Changes to Terms
      </p>
      <p style={{ fontSize: '11pt', fontFamily: 'Arial', margin: '0 0 12pt' }}>
        TutorForSure reserves the right to modify these terms and conditions at any time. Any changes will be posted on this page, and your continued use of the website constitutes acceptance of the revised terms.
      </p>
      <p style={{ fontSize: '12pt', fontFamily: 'Arial', color: '#000000', margin: '0 0 12pt', fontWeight: '700' }}>
        9. Governing Law
      </p>
      <p style={{ fontSize: '11pt', fontFamily: 'Arial', margin: '0 0 12pt' }}>
        These terms and conditions are governed by and construed in accordance with the laws of TutorForSure, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
      </p>
      <p style={{ fontSize: '12pt', fontFamily: 'Arial', color: '#000000', margin: '0 0 12pt', fontWeight: '700' }}>
        10. Contact Information
      </p>
      <p style={{ fontSize: '11pt', fontFamily: 'Arial', margin: '0 0 12pt' }}>
        For any questions about these terms and conditions, please contact us at{' '}
        <a href="mailto:info.tutorforsure@gmail.com" style={{ color: '#1155cc', textDecoration: 'underline' }}>
          info.tutorforsure@gmail.com
        </a>{' '}
        and call us at <span style={{ fontWeight: '700' }}>+919241550824</span>.
      </p>
    </div>
  );
};

export default Terms;