import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Grid, Stack } from "@mui/material";
import CustomAutocomplete from "../../components/CustomAutoComplete";
import { useSelector } from "react-redux";
import { getSubjects } from "../../reducers/homeSlice";
import { BookingDetailsData } from "../../common/types";
import { getTeacherSubjects } from "../../reducers/teacherSlice";

export default function ConfirmBooking({
  bookingDetails,
  captureBookingDetails,
  onCancel,
  onConfirm,
}: {
  bookingDetails: any;
  captureBookingDetails: any;
  onCancel: any;
  onConfirm: any;
}) {
  const {
    requestedAreaName,
    requestedAreaCode,
    teacherFullName,
    requestedSubjectName,
    studentBoard,
    requestedStudentGrade,
    requestedSubjectId,
  } = bookingDetails;
  const subjects = useSelector(getTeacherSubjects);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      px={{ xs: 1, sm: 10, md: 30 }}
      py={1}
    >
      <Grid
        container
        spacing={{ xs: 1.5, sm: 1.5, md: 1.5 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        bgcolor={"primary.main"}
        pl={1}
        pr={2}
        pt={1}
        pb={2}
        borderRadius={1}
      >
        <Stack width={"100%"} px={2} spacing={1.5}>
          <Typography>
            <span style={{ color: "gray" }}>Teacher Name:</span>{" "}
            {teacherFullName}
          </Typography>
          <Typography>
            <span style={{ color: "gray" }}>Board:</span> {studentBoard}
          </Typography>
          <Typography>
            <span style={{ color: "gray" }}>Class:</span>{" "}
            {requestedStudentGrade}
          </Typography>
          <Stack
            maxWidth={"300px"}
            direction={"row"}
            spacing={2}
            alignItems={"center"}
          >
            <Typography>
              <span style={{ color: "gray" }}>Subject:</span>{" "}
              {requestedSubjectName}
            </Typography>
            <CustomAutocomplete
              options={subjects.filter(
                (_: any) =>
                  _.gradeCode === requestedStudentGrade &&
                  _.boardCode === studentBoard
              )}
              getOptionLabel={(option) => option?.subjectName}
              onChange={(e, value) =>
                captureBookingDetails((prev: BookingDetailsData) => {
                  return {
                    ...prev,
                    requestedSubjectName: value?.subjectName,
                    requestedSubjectId: value?.id?.toString(),
                  };
                })
              }
              //label="Select Area"
              variant="outlined"
              //inputLabel={i === 0 ? "Subject" : ""}
              required
              //disabled={!subjectsMapping[i].gradeCode}
            />
          </Stack>
          <Typography>
            <span style={{ color: "gray" }}>Area:</span> {requestedAreaName}
          </Typography>
        </Stack>
        <Grid item xs={4} sm={4} md={6}>
          <Button
            sx={{ mt: 1 }}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onConfirm}
            disabled={!requestedAreaCode || !requestedSubjectId}
          >
            Confirm Booking
          </Button>
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <Button
            sx={{ mt: 1 }}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
