const About = () => {
  return (
    <div style={{ backgroundColor: '#ffffff', padding: '42pt' }}>
      <p style={{ margin: '0', fontSize: '14pt', fontFamily: 'Arial', fontWeight: '700' }}>
        About Us
      </p>
      <br />
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        Welcome to TutorForSure, the premier platform for home tuitions in Patna. We are dedicated to connecting students with highly qualified tutors in the easiest and most efficient way possible. Whether you’re a student looking for personalized guidance or a teacher seeking to share your expertise, our platform is designed with you in mind.
      </p>
      <br />
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial', fontWeight: '700' }}>
        For Students:
      </p>
      <br />
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        Finding the right tutor has never been easier. With TutorForSure, you can connect with a highly qualified tutor within a day. Our platform features a wide range of experienced tutors across various subjects and grade levels, ensuring that you find the perfect match for your educational needs. And the best part? Our service is completely free for students.Whether you're struggling with math, science, languages, or any other subject, our tailored approach ensures that you grasp foundational concepts and excel academically.Boost your understanding, enhance your grades, and build confidence with our flexible and effective online tutoring services.

      </p>
      <br />
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial', fontWeight: '700' }}>
        For Teachers:
      </p>
      <br />
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        TutorForSure offers an easy and hassle-free way for tutors to connect with students in Patna. We understand the importance of flexibility and convenience, so we’ve made the registration process straightforward and free of charge. As a tutor, you can create a profile, list your qualifications, and start connecting with students in need of your expertise.Our intuitive platform ensures that joining TUTOR FOR SURE as an educator is simple and efficient. Whether you're an experienced tutor or new to online teaching, our streamlined registration process and user-friendly interface make it easy to create a profile and start connecting with students.

      </p>
      <br />
      <p style={{ margin: '0', fontSize: '14pt', fontFamily: 'Arial', fontWeight: '700' }}>
        Why Choose Us?
      </p>
      <br />
      <ul style={{ margin: '0', paddingLeft: '20pt', fontSize: '11pt', fontFamily: 'Arial' }}>
        <li>
          <strong>Easy Access:</strong> Our platform is user-friendly, making it simple for both students and teachers to navigate.
        </li>
        <li>
          <strong>Quick Connections:</strong> Students can find the right tutor within a day.
        </li>
        <li>
          <strong>Highly Qualified Tutors:</strong> We feature a diverse pool of highly qualified and experienced teachers.
        </li>
        
      </ul>
      <br />
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        At TutorForSure, we’re committed to fostering educational success by making quality tutoring accessible to everyone in Patna. Join our community today and experience the difference!
      </p>
    </div>
  );
};

export default About;