import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Teacher from "../assets/teacher.jpg";
import TutorAvatar from "../assets/avatar.jpg";
import { Avatar, Button, Rating, Stack } from "@mui/material";
import ArrowButton from "./ArrowButton";
import { ArrowRight, East, Star } from "@mui/icons-material";

interface IUsersCard {
  name: string;
  experience: string;
  rating: number;
  locality: string;
  onClick: any;
  imageSrc?: any;
}

export default function TutorCard({
  imageSrc,
  name,
  experience,
  onClick,
  rating,
  locality,
}: IUsersCard) {
  const theme = useTheme();

  return (
    <Card
      key={name}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        bgcolor: "secondary.main",
        borderRadius: 2,
        boxShadow: "none",
        // border: "1px solid #3d4f58",
        // ":hover": { background: "#061621" },
      }}
    >
      <Stack
        mt={2}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Avatar
          sx={{ width: 60, height: 60, objectFit: "none" }}
          variant="circular"
          src={imageSrc || TutorAvatar}
        />
      </Stack>
      <CardContent
        sx={{
          flex: "1 0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          width={"-webkit-fill-available"}
          overflow={"hidden"}
          align="center"
          fontSize={"16px"}
        >
          {name}
        </Typography>
        <Typography
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          width={"-webkit-fill-available"}
          overflow={"hidden"}
          align="center"
          fontSize={"12px"}
          color="text.secondary"
        >
          Qualifications: {experience}
        </Typography>
        <Typography
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          width={"-webkit-fill-available"}
          overflow={"hidden"}
          align="center"
          fontSize={"12px"}
          color="text.secondary"
        >
          Locality: {locality}
        </Typography>
        <Rating
          size="small"
          name="read-only"
          value={rating}
          readOnly
          precision={0.5}
          emptyIcon={
            <Star
              style={{ opacity: 0.55, color: "white" }}
              fontSize="inherit"
            />
          }
        />
        <Button
          sx={{ mt: 1 }}
          disableRipple
          onClick={onClick}
          variant="text"
          color="primary"
          endIcon={<East />}
        >
          View Details
        </Button>
      </CardContent>
    </Card>
  );
}
