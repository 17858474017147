import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { DialogContent, IconButton, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import CustomInput from "../../components/CustomInput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveBooking, sendMail } from "../../reducers/bookingSlice";
import { addSnackbar } from "../../reducers/snackbarSlice";

const emails = ["username@gmail.com", "user02@gmail.com"];

export interface GuestBookingProps {
  open: boolean;
  board: string;
  subject: string;
  grade: string;
  area: string;
  teacherName: string;
  teacherEmail: string;
  teacherPhoneNumber: string;
  onClose: () => void;
}

export default function GuestBooking({
  open,
  subject,
  board,
  grade,
  area,
  teacherName,
  teacherEmail,
  teacherPhoneNumber,
  onClose,
}: GuestBookingProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
  });
	const [error, setError] = useState(false);

  const captureData = (type: string, value: string) => {
    setData((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value: string) => {
    onClose();
  };

  const confirmBooking = () => {
		if(!data.name || !data.email || !data.phone){
			setError(true);
			return;
		}
		setError(false);
    const emailSubject = "New Booking Request";
    const content1 = `<html>
                      <body>
                          <table>
                              <tr>
                                  <h2>TutorForSure</h2>
                              </tr>
                          </table>
                          <div style="background: linear-gradient(to right, #177694, #9c3); width: 100%; height: 8px;"></div>
                          <p>Hi,</p>
                          <p>We received a new booking request from ${
                            data.name
                          }</p>
                          <p>Booking Details:</p>
                          <p>Booked By: ${data.name}</p>
                          <p>Tutor Name: ${teacherName}</p>
                          <p>Board: ${board}</p>
                          <p>Class: ${grade}</p>
                          <p>Subject: ${subject}</p>
                          <p>Booked On: ${new Date()}</p>
                          </br>
                          <p>Please contact the Support Team to get the student's contact details.</p>
                          <p>+91-9241550824</p>
                          <a href="mailto:info.tutorforsure@gmail.com">info.tutorforsure@gmail.com</a>
                          </br>
                          <p>TutorForSure will contact you shortly to confirm this booking.</p>
                          </br>
                          </br>
                          <p style="font-weight: bold">Thanks & Regards</p>
                          </br>
                          <p style="font-weight: bold">TutorForSure</p>
                      </body>
                  </html>`;

    const content2 = `<html>
                  <body>
                      <table>
                          <tr>
                              <h2>TutorForSure</h2>
                          </tr>
                      </table>
                      <div style="background: linear-gradient(to right, #177694, #9c3); width: 100%; height: 8px;"></div>
                      <p>Hi,</p>
                      <p>There is a new booking request from ${data.name}</p>
                      <p>Booking Details:</p>
                      <p>Booked By: ${data.name}</p>
                      <p>Tutor Name: ${teacherName}</p>
                      <p>Board: ${board}</p>
                      <p>Class: ${grade}</p>
                      <p>Subject: ${subject}</p>
                      <p>Student Email: ${data.email}</p>
                      <p>Student Phone No.: ${data.phone}</p>
                      <p>Tutor Email: ${teacherEmail}</p>
                      <p>Tutor Phone No.: ${teacherPhoneNumber}</p>
                      <p>Booked On: ${new Date()}</p>
                      </br>
                      <p>Please contact the tutor and share the student's contact details to confirm this booking.</p>
                      </br
                      <p>Thank you!</p>
                  </body>
              </html>`;

    const content3 = `<html>
                      <body>
                          <table>
                              <tr>
                                  <h2>TutorForSure</h2>
                              </tr>
                          </table>
                          <div style="background: linear-gradient(to right, #177694, #9c3); width: 100%; height: 8px;"></div>
                          <p>Hi ${data.name},</p>
                          <p>We received a new booking request from you.</p>
                          <p>Booking Details:</p>
                          <p>Booked By: ${data.name}</p>
                          <p>Tutor Name: ${teacherName}</p>
                          <p>Board: ${board}</p>
                          <p>Class: ${grade}</p>
                          <p>Subject: ${subject}</p>
                          <p>Booked On: ${new Date()}</p>
                          </br>
                          <p>Please contact the Support Team in case you have any questions.</p>
                          <p>+91-9241550824</p>
                          <a href="mailto:info.tutorforsure@gmail.com">info.tutorforsure@gmail.com</a>
                          </br>
                          <p>TutorForSure will contact you shortly to confirm this booking.</p>
                          </br>
                          </br>
                          <p style="font-weight: bold">Thanks & Regards</p>
                          </br>
                          <p style="font-weight: bold">TutorForSure</p>
                      </body>
                  </html>`;

    dispatch(
      sendMail({
        email: teacherEmail,
        subject: emailSubject,
        content: content1,
        callback: () => {
          dispatch(
            sendMail({
              email: "info.tutorforsure@gmail.com",
              subject: emailSubject,
              content: content2,
              callback: () => {
                dispatch(
                  sendMail({
                    email: data.email,
                    subject: emailSubject,
                    content: content3,
                    callback: () => {
                      onClose();
											navigate("/");
											dispatch(
												addSnackbar({
													message: "Your booking details has been sent to your email.",
													severity: "success",
												})
											);
                    },
                  }) as any
                );
              },
            }) as any
          );
        },
      }) as any
    );
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialogTitle-root": {
          p: 2,
        },
      }}
      onClose={handleClose}
      open={open}
      fullWidth
    >
      <DialogTitle p={1} borderBottom={"1px solid grey"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography>New Booking as a guest</Typography>
          <IconButton onClick={handleClose} disableRipple sx={{ p: 0 }}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Typography>
          <span style={{ color: "gray" }}>Teacher Name:</span> {teacherName}
        </Typography>
				{board && <Typography>
          <span style={{ color: "gray" }}>Requested Board:</span> {board}
        </Typography>}
        {grade && <Typography>
          <span style={{ color: "gray" }}>Requested Class:</span> {grade}
        </Typography>}
        {subject && <Typography>
          <span style={{ color: "gray" }}>Requested Subject:</span> {subject}
        </Typography>}
        {area && <Typography>
          <span style={{ color: "gray" }}>Area:</span> {area}
        </Typography>}
				<Stack direction={"column"} mt={2} spacing={2}>
        <CustomInput
          inputLabel="Enter Name"
          required
          type="text"
          placeholder="Enter Name"
          onChange={(e) => captureData("name", e.target.value)}
					error={error && !data.name}
        />
        <CustomInput
          inputLabel="Enter Email"
          required
          type="email"
          placeholder="Enter Email"
          onChange={(e) => captureData("email", e.target.value)}
					error={error && !data.email}
        />
        <CustomInput
          inputLabel="Enter Phone No."
          required
          type="number"
          placeholder="Enter Phone No."
          onChange={(e) => captureData("phone", e.target.value)}
					error={error && !data.phone}
        />
        <Button
          sx={{ mt: 1 }}
          fullWidth
          variant="contained"
          color="secondary"
          onClick={confirmBooking}
        >
          Book Now
        </Button></Stack>
      </DialogContent>
    </Dialog>
  );
}
