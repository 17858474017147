import { Facebook, Instagram, Twitter, WhatsApp, X } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const footerNavs = [
  {
    name: "Home",
    link: "/home",
  },
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Contact",
    link: "/contact",
  },
  {
    name: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    name: "Terms & Conditions",
    link: "/terms",
  },
];

function Footer() {
  const navigate = useNavigate();

  return (
    <Stack bgcolor={"#177694"} p={3} direction={"column"} spacing={1}>
      <Stack
        direction={"column"}
        spacing={1}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"center"}
      >
        <Typography color={"white"} >
          Interested to work with us? Drop us a line
        </Typography>
        <Typography color={"#9c3"} >
          info.tutorforsure@gmail.com
        </Typography>
        <Typography color={"white"} >
          or call us at
        </Typography>
        <Typography color={"white"} >
           <span style={{color:"#9c3"}}>(+91)-9241550824</span>
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={1}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"center"}
      >
        <IconButton
          href="https://facebook.com"
          target="blank"
          sx={{ color: "white" }}
        >
          <Facebook />
        </IconButton>
        <IconButton
          href="https://www.instagram.com/tutorforsure"
          target="blank"
          sx={{ color: "white" }}
        >
          <Instagram />
        </IconButton>
        <IconButton
          href="https://x.com/tutorforsure"
          target="blank"
          sx={{ color: "white" }}
        >
          <X />
        </IconButton>
        <IconButton
          href="https://wa.link/xdxfus"
          target="blank"
          sx={{ color: "white" }}
        >
          <WhatsApp />
        </IconButton>
      </Stack>
      <Stack
        direction={"row"}
        spacing={1}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"center"}
      >
        {footerNavs.map((_) => (
          <Button
            key={_.name}
            onClick={() => navigate(_.link)}
            sx={{ color: "white", fontWeight: "normal", px: 1 }}
            variant="text"
          >
            {_.name}
          </Button>
        ))}
      </Stack>
      <Stack
        direction={"row"}
        spacing={1}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"center"}
      >
        <Typography color={"white"} fontSize={"12px"}>
          ©2024 TutorForSure | All Rights Reserved
        </Typography>
      </Stack>
    </Stack>
  );
}

export default Footer;
