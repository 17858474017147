import { Link, createBrowserRouter } from "react-router-dom";
import About from "../pages/about/About";
import Dashboard from "../pages/dashboard/Dashboard";
import Home from "../pages/home/Home";
import Tutor from "../pages/tutor/Tutor";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import UpdateProfile from "../pages/updateProfile/UpdateProfile";
import Profile from "../pages/profile/Profile";
import Student from "../pages/student/Student";
import Admin from "../pages/admin/Admin";
import ProtectedRoute from "../components/ProtectedRoute";
import SearchResults from "../pages/searchResults/SearchResults";
import BookTutor from "../pages/bookTutor/BookTutor";
import AddTutor from "../pages/admin/addTutor/AddTutor";
import EditTutor from "../pages/common/editTutor/EditTutor";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import Terms from "../pages/terms/Terms";
import ContactUs from "../pages/contact/Contact";
import ConfirmEmail from "../pages/auth/ConfirmEmail";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "contact",
        element: <ContactUs />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "book-tutor",
        element: <BookTutor />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "confirm-email",
        element: <ConfirmEmail />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "search-tutors",
        element: <SearchResults />,
      },
      {
        path: "update-profile",
        element: <ProtectedRoute roles={["Student", "Teacher", "Admin"]}><UpdateProfile /></ProtectedRoute>,
      },
      {
        path: "student",
        element: <ProtectedRoute roles={["Student"]}><Student /></ProtectedRoute>,
      },
      {
        path: "tutor",
        element: <ProtectedRoute roles={["Teacher"]}><Tutor /></ProtectedRoute>,
      },
      {
        path: "admin",
        element:<ProtectedRoute roles={["Admin"]}><Admin /></ProtectedRoute> ,
      },
      {
        path: "add-tutor",
        element:<ProtectedRoute roles={["Admin"]}><AddTutor /></ProtectedRoute> ,
      },
      {
        path: "edit-tutor-profile",
        element: <ProtectedRoute roles={["Teacher", "Admin"]}><EditTutor /></ProtectedRoute>,
      },
      {
        path: "*",
        element: <NoMatch />,
      },
    ],
  },
]);

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
