import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button, Divider, Grid, Link, MenuItem, Stack } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { register } from "../../reducers/authSlice";
import { log } from "console";
import { getClasses } from "../../reducers/searchSlice";
import {
  AreaMappingData,
  RegisterationData,
  SubjectsMappingData,
} from "../../common/types";
import CustomAutocomplete from "../../components/CustomAutoComplete";
import { getAreas } from "../../reducers/homeSlice";
import { addSnackbar } from "../../reducers/snackbarSlice";
import SubjectsMapping from "../common/SubjectsMapping";
import MultiSelect from "../../components/multiSelect/MultiSelect";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

export default function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useSelector(getClasses);
  const areas = useSelector(getAreas);
  const [data, setData] = useState<RegisterationData>({
    username: "",
    email: "",
    password: "",
    roles: [],
    phoneNumber: "",
    twoFactorEnabled: false,
    addressLine1: "",
    addressLine2: "",
    pinCode: "",
    state: "Bihar",
    country: "India",
    areaCode: "",
    areaName: "",
    city: "Patna",
    countryCode: "IN",
    roleType: "",
    teacherQualification: "",
    studentGrade: "",
    profilePicture: "",
    resume: "",
    isActive: "Y",
    isApproved: "N",
    dateOfBirth: "01/01/2000",
    gender: "",
    fullName: "",
    boardCode: "CBSE",
  });
  const [subjectsMapping, setSubjectsMapping] = useState<SubjectsMappingData[]>(
    [
      {
        subjects: [],
        gradeCode: "",
        popularityLevel: "9",
        boardCode: "CBSE",
        isDeleted: "N",
      },
    ]
  );

  const [areasMapping, setAreasMapping] = useState<AreaMappingData[]>([]);
  const [error, setError] = useState(false);

  const captureData = (type: string, value: string) => {
    setData((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const captureArea = (areaCode: string, areaName: string) => {
    setData((prev) => {
      return { ...prev, areaCode: `${areaCode}`, areaName };
    });
  };

  const captureRole = (role: string) => {
    setData((prev) => {
      return { ...prev, roles: [role], roleType: role };
    });
  };

  const captureEmailAndUsername = (email: string) => {
    setData((prev) => {
      return { ...prev, email, username: email };
    });
  };

  const addAreaMapping = (data: any[]) => {
    const newData: AreaMappingData[] = data.map((_) => {
      return {
        teachingAreaCode: _.areaCode,
        teachingAreaName: _.areaName,
        teachingAreaPinCode: `${_.areaPinCode}`,
        teachingAreaCity: _.city,
        teachingAreaState: _.state,
        teachingAreaCountry: _.country,
        teachingAreaCountryCode: _.countryCode,
        isDeleted: "N",
        createDate: "2024-06-25T08:48:19.346Z",
        updateDate: "2024-06-25T08:48:19.346Z",
        createUserId: "",
        updateUserId: "",
      };
    });
    setAreasMapping(() => newData);
  };

  const handleClick = () => {
    dispatch(
      addSnackbar({
        message: "This is a success message!",
        severity: "success",
      })
    );
  };

  const registerUser = () => {
    let subjects: any[] = [];
    subjectsMapping.forEach((_) => {
      _.subjects.forEach((s) => {
        subjects.push({
          subjectCode: s.subjectCode,
          gradeCode: _.gradeCode,
          popularityLevel: _.popularityLevel,
          boardCode: _.boardCode,
          isDeleted: "N",
          subjectName: s.subjectName,
        });
      });
    });
    const newData: RegisterationData =
      data.roleType === "Student"
        ? data
        : {
            ...data,
            teacherServiceAreaMappings: areasMapping,
            teacherSubjectMappings: subjects,
          };
    dispatch(
      register({
        userData: newData,
        callBack: () => navigate(`/login?prev=register`),
      }) as any
    );
  };

  const checkForErrors = () => {
    const {
      email,
      fullName,
      password,
      gender,
      boardCode,
      studentGrade,
      dateOfBirth,
      areaCode,
      phoneNumber,
      pinCode,
      addressLine1,
      teacherQualification,
      teacherServiceAreaMappings,
      teacherSubjectMappings,
      roleType,
    } = data;

    if(!roleType) {
      setError(true);
      return;
    }

    if (
      data.roleType === "Student" &&
      (!email ||
        !fullName ||
        !password ||
        !gender ||
        !boardCode ||
        !studentGrade ||
        !dateOfBirth ||
        !areaCode ||
        !phoneNumber ||
        !pinCode ||
        !addressLine1)
    ) {
      setError(true);
      return;
    } else if (
      data.roleType === "Teacher" &&
      (!fullName ||
        !password ||
        !gender ||
        !boardCode ||
        !dateOfBirth ||
        !areaCode ||
        !phoneNumber ||
        !pinCode ||
        !addressLine1 ||
        !teacherQualification ||
        !areasMapping?.length ||
        !subjectsMapping?.length)
    ) {
      setError(true);
      return;
    }
    registerUser();
  };

  const extractDateComponents = (timestamp: any) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${day}-${month}-${year}`;
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      px={{ xs: 1, sm: 10, md: 30 }}
      py={1}
    >
      <Grid
        container
        spacing={{ xs: 1.5, sm: 1.5, md: 1.5 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        bgcolor={"primary.main"}
        pl={1}
        pr={2}
        pt={1}
        pb={2}
        borderRadius={1}
      >
        <Grid item xs={4} sm={8} md={12}>
          <Typography variant="body2">Welcome!</Typography>
          <Typography color="text.secondary" fontSize={"14px"}>
            Sign up to continue.
          </Typography>
        </Grid>
        <Grid item xs={4} sm={8} md={4}>
          <CustomInput
            inputLabel="Select Role"
            required
            select
            //defaultValue={"Student"}
            onChange={(e) => {
              setError(false);
              captureRole(e.target.value);
            }}
            error={error && !data.roleType}
          >
            <MenuItem value={"Student"}>Student</MenuItem>
            <MenuItem value={"Teacher"}>Teacher</MenuItem>
          </CustomInput>
        </Grid>
        <Grid item xs={4} sm={8} md={4}>
          <CustomInput
            sx={{ width: "100%" }}
            fullWidth
            inputLabel="Full Name"
            required
            onChange={(e) => captureData("fullName", e.target.value)}
            error={error && !data.fullName}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={4}>
          <Stack direction={"column"} flex={1} spacing={1}>
            <Typography>
              Date of Birth <span style={{ color: "red" }}>*</span>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  height: "40px",
                  "& .MuiOutlinedInput-root": { height: "inherit" },
                }}
                onChange={(value: any) => {
                  captureData("dateOfBirth", extractDateComponents(value.$d));
                }}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid
          item
          xs={data.roleType === "Teacher" ? 2 : 4 / 3}
          sm={data.roleType === "Teacher" ? 4 : 8 / 3}
          md={data.roleType === "Teacher" ? 6 : 4}
        >
          <CustomInput
            sx={{ width: "100%" }}
            fullWidth
            inputLabel="Gender"
            required
            select
            onChange={(e) => captureData("gender", e.target.value)}
            error={error && !data.gender}
          >
            <MenuItem value={"Male"}>Male</MenuItem>
            <MenuItem value={"Female"}>Female</MenuItem>
          </CustomInput>
        </Grid>
        {data.roleType === "Teacher" && (
          <Grid item xs={2} sm={4} md={6}>
            <CustomInput
              sx={{ width: "100%" }}
              fullWidth
              inputLabel="Qualifications"
              required
              onChange={(e) =>
                captureData("teacherQualification", e.target.value)
              }
              error={error && !data.teacherQualification}
            />
          </Grid>
        )}
        {data.roleType === "Student" && (
          <>
            <Grid item xs={4 / 3} sm={8 / 3} md={4}>
              <CustomInput
                sx={{ width: "100%" }}
                fullWidth
                inputLabel="Board"
                required
                select
                defaultValue={"CBSE"}
                //onChange={(e) => captureData("username", e.target.value)}
                error={error && !data.boardCode}
              >
                <MenuItem value={"CBSE"}>CBSE</MenuItem>
                <MenuItem value={"ICSE"}>ICSE</MenuItem>
                <MenuItem value={"BSEB"}>BSEB</MenuItem>
              </CustomInput>
            </Grid>
            <Grid item xs={4 / 3} sm={8 / 3} md={4}>
              <CustomInput
                inputLabel="Class"
                required
                select
                fullWidth
                defaultValue={"Class 1-5"}
                onChange={(event: any) => {
                  captureData("studentGrade", event.target.value);
                }}
                error={error && !data.studentGrade}
              >
                {classes.map((_) => (
                  <MenuItem value={_}>Class {_}</MenuItem>
                ))}
              </CustomInput>
            </Grid>
          </>
        )}
        {data.roleType === "Teacher" && (
          <Grid item xs={4} sm={8} md={12}>
            <Typography>Add Board, Class and Subject</Typography>
            <SubjectsMapping
              subjectsMapping={subjectsMapping}
              setSubjectsMapping={setSubjectsMapping}
            />
          </Grid>
        )}
        <Grid item xs={4} sm={4} md={6}>
          <CustomInput
            inputLabel="Email"
            required
            placeholder="Enter email"
            onChange={(e) => captureEmailAndUsername(e.target.value)}
            error={error && !data.email}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <CustomInput
            inputLabel="Phone No."
            required
            placeholder="Enter Phone No."
            onChange={(e) => captureData("phoneNumber", e.target.value)}
            error={error && !data.phoneNumber}
          />
        </Grid>

        <Grid item xs={2} sm={4} md={6}>
          <CustomAutocomplete
            options={areas}
            getOptionLabel={(option) =>
              option?.areaName + " " + "(" + option?.areaPinCode + ")"
            }
            onChange={(e, value) =>
              captureArea(value?.areaPinCode, value?.areaName)
            }
            label="Select Area"
            variant="outlined"
            inputLabel="Area"
            required
            //error={error && !data.areaName}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={6}>
          <CustomInput
            inputLabel="Postcode"
            required
            placeholder="Enter postcode"
            onChange={(e) => captureData("pinCode", e.target.value)}
            error={error && !data.pinCode}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={6}>
          <CustomInput
            inputLabel="Address Line 1"
            required
            placeholder="Enter Address Line 1"
            onChange={(e) => captureData("addressLine1", e.target.value)}
            error={error && !data.addressLine1}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={6}>
          <CustomInput
            inputLabel="Address Line 2"
            required={false}
            placeholder="Enter Address Line 2"
            onChange={(e) => captureData("addressLine2", e.target.value)}
          />
        </Grid>

        <Grid item xs={4 / 3} sm={8 / 3} md={4}>
          <CustomInput
            inputLabel="Country"
            defaultValue={"India"}
            disabled
            required
          />
        </Grid>
        <Grid item xs={4 / 3} sm={8 / 3} md={4}>
          <CustomInput inputLabel="State" defaultValue={"Bihar"} disabled />
        </Grid>
        <Grid item xs={4 / 3} sm={8 / 3} md={4}>
          <CustomInput inputLabel="City" defaultValue={"Patna"} disabled />
        </Grid>
        {data.roleType === "Teacher" && (
          <Grid item xs={4} sm={8} md={12}>
            Select all the areas you can teach in
            <MultiSelect
              options={areas}
              fullWidth
              optionField="areaName"
              //value={selectedAreas || []}
              onChange={(e: any, v: any) => {
                addAreaMapping(v);
              }}
              error={error && !data.teacherServiceAreaMappings?.length}
            />
          </Grid>
        )}
        <Grid item xs={2} sm={4} md={6}>
          <CustomInput
            inputLabel="Enter Password"
            required
            placeholder="Enter Password"
            onChange={(e) => captureData("password", e.target.value)}
            error={error && !data.password}
            type="password"
          />
        </Grid>
        <Grid item xs={2} sm={4} md={6}>
          <CustomInput
            inputLabel="Confirm Password"
            required
            placeholder="Confirm Password"
            type="password"
          />
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <Button
            sx={{ mt: 1 }}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={checkForErrors}
          >
            Sign Up
          </Button>
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <Typography marginTop={2} color="text.disabled" fontSize={"14px"}>
            Already have an account?{" "}
            <Button onClick={() => navigate("/login")}>Login</Button>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
