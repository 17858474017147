import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import CustomInput from "../../components/CustomInput";
import CustomAutocomplete from "../../components/CustomAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { getClasses } from "../../reducers/searchSlice";
import { AreaMappingData, SubjectsMappingData } from "../../common/types";
import { Delete, ExpandMoreOutlined } from "@mui/icons-material";
import { getSubjects } from "../../reducers/homeSlice";
import {
  deleteSelectedSubject,
  getTeacherDetails,
  getTeacherSubjects,
} from "../../reducers/teacherSlice";
import { useLocation } from "react-router-dom";
import { getUserDetails } from "../../reducers/authSlice";
import MultiSelect from "../../components/multiSelect/MultiSelect";

export default function SubjectsMapping({
  subjectsMapping,
  setSubjectsMapping,
}: {
  subjectsMapping: SubjectsMappingData[];
  setSubjectsMapping: React.Dispatch<
    React.SetStateAction<SubjectsMappingData[]>
  >;
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useSelector(getClasses);
  const subjects = useSelector(getSubjects);
  const user = useSelector(getUserDetails);
  const selectedSubjects = useSelector(getTeacherSubjects);

  const captureData = (type: string, value: string, index: number) => {
    let data = [...subjectsMapping];
    data[index] = { ...data[index], [type]: value };
    setSubjectsMapping(() => data);
  };

  const captureSubject = (
    subjects: { subjectCode: string; subjectName: string }[],
    index: number
  ) => {
    let data = [...subjectsMapping];
    data[index] = { ...data[index], subjects };
    setSubjectsMapping(() => data);
  };

  const addNewSubject = () => {
    setSubjectsMapping((prev) => {
      return [
        ...prev,
        {
          subjects: [],
          gradeCode: "",
          popularityLevel: "9",
          boardCode: "CBSE",
          isDeleted: "N",
        },
      ];
    });
  };

  const deleteSubject = (index: number) => {
    setSubjectsMapping((prev) => {
      let data = [...prev];
      data.splice(index, 1);
      return data;
    });
  };

  const formattedSubjects = () => {
    const cbse = selectedSubjects.filter((_) => _.boardCode === "CBSE");
    const boards = ["CBSE", "ICSE", "BSEB"];
  };

  const boards = ["CBSE", "ICSE", "BSEB"];

  return (
    <>
      {/* {location.pathname === "/edit-tutor-profile" && (
        <Stack
          useFlexGap
          flexWrap={"wrap"}
          direction={"row"}
          width={"100%"}
          spacing={"10px"}
        >
          {selectedSubjects.map(
            (_, i) =>
              _.isDeleted === "N" && (
                <Chip
                  sx={{
                    background: "#177694",
                    borderRadius: 1,
                    color: "#fff",
                    fontWeight: 600,
                    "& .MuiChip-deleteIcon": { color: "#fff" },
                    height: "auto",
                    py: 1,
                  }}
                  onDelete={() => {
                    dispatch(
                      deleteSelectedSubject({
                        id: _.id,
                        data: {
                          subjectCode: _.subjectCode,
                          gradeCode: _.gradeCode,
                          popularityLevel: _.popularityLevel,
                          boardCode: _.boardCode,
                          isDeleted: "Y",
                          subjectName: _.subjectName,
                        },
                        callback: () => {
                          dispatch(
                            getTeacherDetails({
                              teacherId: user.user.id,
                            }) as any
                          );
                        },
                      }) as any
                    );
                  }}
                  label={
                    <Stack direction={"row"} spacing={2}>
                      <Stack>
                        <Typography fontSize={"10px"}>Board</Typography>
                        <Typography fontSize={"12px"}>{_.boardCode}</Typography>
                      </Stack>
                      <Stack>
                        <Typography fontSize={"10px"}>Class</Typography>
                        <Typography fontSize={"12px"}>{_.gradeCode}</Typography>
                      </Stack>
                      <Stack>
                        <Typography fontSize={"10px"}>Subject</Typography>
                        <Typography fontSize={"12px"}>
                          {_.subjectName}
                        </Typography>
                      </Stack>
                    </Stack>
                  }
                />
              )
          )}
        </Stack>
      )} */}
      {location.pathname === "/edit-tutor-profile" &&
        boards.map((b) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {b}
            </AccordionSummary>
            <AccordionDetails>
              {classes.map((c) => (
                <Stack
                  useFlexGap
                  flexWrap={"wrap"}
                  direction={"row"}
                  width={"100%"}
                  spacing={"10px"}
                  mb={1}
                >
                  {selectedSubjects.filter(
                    (_) => _.boardCode === b && _.gradeCode === c
                  ).length > 0 && (
                    <>
                      <Typography display={"flex"} alignItems={"center"}>
                        Class {c}:
                      </Typography>
                      {selectedSubjects
                        .filter((_) => _.boardCode === b && _.gradeCode === c)
                        .map(
                          (_, i) =>
                            _.isDeleted === "N" && (
                              <Chip
                                sx={{
                                  background: "#177694",
                                  borderRadius: 1,
                                  color: "#fff",
                                  fontWeight: 600,
                                  "& .MuiChip-deleteIcon": { color: "#fff" },
                                  height: "auto",
                                  py: 1,
                                }}
                                onDelete={() => {
                                  dispatch(
                                    deleteSelectedSubject({
                                      id: _.id,
                                      data: {
                                        subjectCode: _.subjectCode,
                                        gradeCode: _.gradeCode,
                                        popularityLevel: _.popularityLevel,
                                        boardCode: _.boardCode,
                                        isDeleted: "Y",
                                        subjectName: _.subjectName,
                                      },
                                      callback: () => {
                                        dispatch(
                                          getTeacherDetails({
                                            teacherId: user.user.id,
                                          }) as any
                                        );
                                      },
                                    }) as any
                                  );
                                }}
                                label={
                                  <Typography fontSize={"12px"}>
                                    {_.subjectName}
                                  </Typography>
                                }
                              />
                            )
                        )}
                    </>
                  )}
                </Stack>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}

      {subjectsMapping.map((_, i) => (
        <Stack
          direction={"row"}
          useFlexGap
          flexWrap={"wrap"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"center"}
          border={"1px solid gray"}
          p={1}
          borderRadius={1}
          mb={.5}
        >
          <CustomInput
            sx={{ width: "100%" }}
            fullWidth
            inputLabel={"Board"}
            required
            select
            defaultValue={"CBSE"}
            onChange={(e) => captureData("boardCode", e.target.value, i)}
          >
            <MenuItem value={"CBSE"}>CBSE</MenuItem>
            <MenuItem value={"ICSE"}>ICSE</MenuItem>
            <MenuItem value={"BSEB"}>BSEB</MenuItem>
          </CustomInput>
          <CustomInput
            sx={{ width: "100%" }}
            inputLabel={"Class"}
            required
            select
            fullWidth
            defaultValue={"Class 1-5"}
            onChange={(event: any) => {
              captureData("gradeCode", event.target.value, i);
            }}
          >
            {classes.map((_) => (
              <MenuItem value={_}>Class {_}</MenuItem>
            ))}
          </CustomInput>
          <Stack direction={"column"} flex={1.5} spacing={1}>
              <Typography>
                Subjects <span style={{ color: "red" }}>*</span>
              </Typography>
            <MultiSelect
              options={subjects.filter(
                (_: any) =>
                  _.gradeCode === subjectsMapping[i].gradeCode &&
                  _.boardCode === subjectsMapping[i].boardCode
              )}
              sx={{
                width: "100%",
                minWidth: '240px',
                marginBottom: 0,
                marginTop: 1,
                "& .MuiFormControl-root-MuiTextField-root": { marginBottom: 0 },
              }}
              optionField="subjectName"
              getOptionDisabled={(option: any) =>
                Boolean(
                  selectedSubjects.find(
                    (_) =>
                      _.gradeCode === option.gradeCode &&
                      _.boardCode === option.boardCode &&
                      _.subjectName === option.subjectName
                  )
                )
              }
              disabled={!subjectsMapping[i].gradeCode}
              //value={selectedAreas || []}
              onChange={(e: any, v: any) => {
                captureSubject(
                  v.map((_: any) => {
                    return {
                      subjectName: _.subjectName,
                      subjectCode: _.subjectCode,
                    };
                  }),
                  i
                );
              }}
              //error={error && !data.teacherServiceAreaMappings?.length}
            />
          </Stack>
          <IconButton
            sx={{ alignSelf: "end" }}
            disabled={subjectsMapping.length === 1}
            onClick={() => deleteSubject(i)}
          >
            <Delete />
          </IconButton>
        </Stack>
      ))}
      <Button
        sx={{ mt: 1, alignSelf: "baseline" }}
        onClick={addNewSubject}
        variant="text"
      >
        Add new subject
      </Button>
    </>
  );
}
