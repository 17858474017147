import { useSelector } from "react-redux";
import { getUserDetails } from "../reducers/authSlice";

const useIsAuthorized = (roles: any[]) => {
  const userDetails = useSelector(getUserDetails);

  if (Array.isArray(roles) && roles.length === 0) return true;
	
  return roles.some((userRole) => userDetails?.roles?.includes(userRole));
};

export default useIsAuthorized;
