import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Avatar, Button, CardActionArea } from "@mui/material";
import { East } from "@mui/icons-material";

interface IUsersCard {
  imageSrc: any;
  header: string;
  body: string;
  onClick: any;
  btnText: string;
}

export default function UsersCard({
  imageSrc,
  header,
  body,
  onClick,
  btnText,
}: IUsersCard) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        bgcolor: "none",
        borderRadius: 2,
        boxShadow: "none",
        //border: "1px solid #3d4f58",
        //":hover": { background: "#061621" },
      }}
    >
      {/* <CardMedia
        component="img"
        sx={{ width: 151, maxHeight: "150px", padding: 2, borderRadius: "50%" }}
        src={imageSrc}
        alt="Live from space album cover"
      /> */}
      <Avatar
        sx={{
          width: 150,
          height: 150,
          borderRadius: "8px",
          objectFit: "none",
          p: 2
        }}
        variant="square"
        src={imageSrc}
      />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography width={"max-content"} variant="body2">
            {header}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {body}
          </Typography>
          <Button
            sx={{ mt: 1 }}
            disableRipple
            onClick={onClick}
            variant="text"
            color="primary"
            endIcon={<East />}
          >
            {btnText}
          </Button>
        </CardContent>
      </Box>
    </Card>
  );
}
