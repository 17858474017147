// src/features/snackbar/snackbarSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateImage, updateProfile } from "./homeSlice";
import { forgotPassword, login, register, resetPassword } from "./authSlice";
import { saveBooking } from "./bookingSlice";

interface SnackbarNotification {
  id: number;
  message: string;
  severity: "info" | "success" | "warning" | "error";
}

interface SnackbarState {
  notifications: SnackbarNotification[];
}

const initialState: SnackbarState = {
  notifications: [],
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    addSnackbar: (
      state,
      action: PayloadAction<{
        message: string;
        severity?: "info" | "success" | "warning" | "error";
      }>
    ) => {
      state.notifications.push({
        id: new Date().getTime(),
        message: action.payload.message,
        severity: action.payload.severity || "info",
      });
    },
    removeSnackbar: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      return {
        ...state,
        notifications: state.notifications.concat({
          id: state.notifications.length,
          message: payload.message,
          severity: "success",
        }),
      };
    }).addCase(login.rejected, (state, { payload }) => {      
      return {
        ...state,
        notifications: state.notifications.concat({
          id: state.notifications.length,
          message: payload as string,
          severity: "error",
        }),
      };
    }).addCase(saveBooking.fulfilled, (state, { payload }) => {      
      return {
        ...state,
        notifications: state.notifications.concat({
          id: state.notifications.length,
          message: payload.message as string,
          severity: "success",
        }),
      };
    }).addCase(register.rejected, (state, { payload }) => {      
      return {
        ...state,
        notifications: state.notifications.concat({
          id: state.notifications.length,
          message: payload as string,
          severity: "error",
        }),
      };
    }).addCase(register.fulfilled, (state, { payload }) => {      
      return {
        ...state,
        notifications: state.notifications.concat({
          id: state.notifications.length,
          message: payload.message as string,
          severity: "success",
        }),
      };
    }).addCase(forgotPassword.rejected, (state, { payload }) => {      
      return {
        ...state,
        notifications: state.notifications.concat({
          id: state.notifications.length,
          message: payload as string,
          severity: "error",
        }),
      };
    }).addCase(forgotPassword.fulfilled, (state, { payload }) => {      
      return {
        ...state,
        notifications: state.notifications.concat({
          id: state.notifications.length,
          message: payload.message as string,
          severity: "success",
        }),
      };
    }).addCase(resetPassword.rejected, (state, { payload }) => {      
      return {
        ...state,
        notifications: state.notifications.concat({
          id: state.notifications.length,
          message: payload as string,
          severity: "error",
        }),
      };
    }).addCase(resetPassword.fulfilled, (state, { payload }) => {      
      return {
        ...state,
        notifications: state.notifications.concat({
          id: state.notifications.length,
          message: payload.message as string,
          severity: "success",
        }),
      };
    }).addCase(updateImage.rejected, (state, { payload }) => {      
      return {
        ...state,
        notifications: state.notifications.concat({
          id: state.notifications.length,
          message: payload as string,
          severity: "error",
        }),
      };
    }).addCase(updateImage.fulfilled, (state, { payload }) => {      
      return {
        ...state,
        notifications: state.notifications.concat({
          id: state.notifications.length,
          message: payload?.message as string,
          severity: "success",
        }),
      };
    });
  },
});
export const getNotifications = (state: { snackbar: SnackbarState }) =>
  state.snackbar.notifications;
export const { addSnackbar, removeSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
