import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";

export default function CustomInput({
  inputLabel,
  required,
  ...props
}: { inputLabel?: string } & TextFieldProps) {
  const theme = useTheme();

  return (
    <Stack direction={"column"} flex={1} spacing={1}>
      <Typography>
        {inputLabel} {inputLabel && required && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <TextField
        size="small"
        variant="outlined"
        //sx={{color: "red"}}
        {...props}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                // background: "#f4f4f4",
                // border: "1px solid black",
                // borderRadius: "2px"
              },
            },
          },
        }}
      />
    </Stack>
  );
}
