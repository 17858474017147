import React from 'react';

// Define the PrivacyPolicy component
const PrivacyPolicy = () => {
  return (
    <div style={{ backgroundColor: '#ffffff', padding: '42pt' }}>
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}></p>
      
      <p style={{ margin: '0', fontSize: '26pt', fontFamily: 'Arial', color: '#000000', paddingBottom: '3pt', lineHeight: '1.15' }}>
        Privacy Policy
      </p>
      
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        Effective Date: 30/08/2024
      </p>
      
      <br />
      
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        Welcome to TUTOR FOR SURE (TFS). This Privacy Policy outlines how we collect, use, and protect your personal information when you use our services to connect with tutors and students for home tuition. By accessing or using our Website, you agree to the terms of this Privacy Policy.
      </p>
      
      <br />
      
      <p style={{ margin: '0', fontSize: '14pt', fontFamily: 'Arial', color: '#000000', fontWeight: '700' }}>
        1. Information We Collect
      </p>
      
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        We collect the following types of information to provide our services:
      </p>
      
      <ul style={{ margin: '0', paddingLeft: '20pt', fontSize: '11pt', fontFamily: 'Arial' }}>
        <li><strong>Personal Information:</strong> This may include your name, email address, phone number, and location when you register on our Website.</li>
        <li><strong>Usage Data:</strong> We may collect information about how you interact with our Website, such as your IP address, browser type, and pages visited.</li>
      </ul>
      
      <br />
      
      <p style={{ margin: '0', fontSize: '14pt', fontFamily: 'Arial', color: '#000000', fontWeight: '700' }}>
        2. How We Use Your Information
      </p>
      
      <ul style={{ margin: '0', paddingLeft: '20pt', fontSize: '11pt', fontFamily: 'Arial' }}>
        <li>To connect students with suitable tutors.</li>
        <li>To communicate with you regarding your account, services, or inquiries.</li>
        <li>To improve our Website and services based on user feedback and usage patterns.</li>
      </ul>
      
      <br />
      
      <p style={{ margin: '0', fontSize: '14pt', fontFamily: 'Arial', color: '#000000', fontWeight: '700' }}>
        3. Data Sharing and Disclosure
      </p>
      
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        We do not sell, trade, or rent your personal information to third parties. However, we may share your information with:
      </p>
      
      <ul style={{ margin: '0', paddingLeft: '20pt', fontSize: '11pt', fontFamily: 'Arial' }}>
        <li><strong>Tutors/Students:</strong> To facilitate the connection between tutors and students, we may share your contact details with potential tutors/students.</li>
        <li><strong>Legal Obligations:</strong> We may disclose your information if required by law or to protect our rights, property, or safety.</li>
      </ul>
      
      <br />
      
      <p style={{ margin: '0', fontSize: '14pt', fontFamily: 'Arial', color: '#000000', fontWeight: '700' }}>
        4. Data Security
      </p>
      
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        We implement reasonable security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
      </p>
      
      <br />
      
      <p style={{ margin: '0', fontSize: '14pt', fontFamily: 'Arial', color: '#000000', fontWeight: '700' }}>
        5. Limitation of Liability
      </p>
      
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        TUTOR FOR SURE serves as a platform to connect tutors and students. We do not take responsibility for the character, behavior, or actions of any tutor or student. Users are encouraged to conduct their own assessments and exercise caution when engaging with others through our platform.
      </p>
      
      <br />
      
      <p style={{ margin: '0', fontSize: '14pt', fontFamily: 'Arial', color: '#000000', fontWeight: '700' }}>
        6. Your Rights
      </p>
      
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        You have the right to access, correct, or delete your personal information stored on our Website. If you wish to exercise these rights, please contact us at <a href="mailto:info.tutorforsure@gmail.com" style={{ color: '#000000' }}>info.tutorforsure@gmail.com</a> or contact no- <a href="tel:+9241550824" style={{ color: '#000000' }}>9241550824</a>.
      </p>
      
      <br />
      
      <p style={{ margin: '0', fontSize: '14pt', fontFamily: 'Arial', color: '#000000', fontWeight: '700' }}>
        7. Changes to This Privacy Policy
      </p>
      
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated effective date.
      </p>
      
      <br />
      
      <p style={{ margin: '0', fontSize: '14pt', fontFamily: 'Arial', color: '#000000', fontWeight: '700' }}>
        8. Contact Us
      </p>
      
      <p style={{ margin: '0', fontSize: '11pt', fontFamily: 'Arial' }}>
        If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info.tutorforsure@gmail.com" style={{ color: '#000000' }}>info.tutorforsure@gmail.com</a> or contact no- <a href="tel:+9241550824" style={{ color: '#000000' }}>9241550824</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;