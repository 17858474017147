import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { DialogContent, IconButton, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";

const emails = ["username@gmail.com", "user02@gmail.com"];

export interface BookingDetailsProps {
  open: boolean;
  bookingDetails: any;
  id: any;
  onClose: () => void;
}

export default function BookingDetails(props: BookingDetailsProps) {
  const { onClose, bookingDetails, open, id } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value: string) => {
    onClose();
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialogTitle-root": {
          p: 2,
        },
      }}
      onClose={handleClose}
      open={open}
			fullWidth
    >
      <DialogTitle p={1} borderBottom={"1px solid grey"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography>Booking ID: {id}</Typography>
          <IconButton onClick={handleClose} disableRipple sx={{ p: 0 }}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        {Object.entries(bookingDetails).map(([key, value]) => (
          <Typography>
            {key}: {value?.toString()}
          </Typography>
        ))}
      </DialogContent>
    </Dialog>
  );
}
