import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { rows } from "./BookingsTable";
import { IconButton, Stack } from "@mui/material";
import { Check, Close, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const data = rows.map((_) => ({
  ..._,
  subject: "Maths",
  status: "Pending",
  class: "1-5",
  address: "Exhibition Road, 800001",
}));

export default function TutorsTable({ searchText }: { searchText: string }) {
  const navigate = useNavigate();

  const columns: GridColDef<(typeof rows)[number]>[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "tutorName",
      headerName: "Tutor name",
      width: 200,
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
    },
  
    {
      field: "subject",
      headerName: "Subject",
      width: 150,
    },
    {
      field: "class",
      headerName: "Class",
      width: 100,
    },
    {
      field: "date",
      headerName: "Registered On",
      width: 150,
    },
    {
      field: "status",
      headerName: "Confirmation",
      width: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      headerAlign: "center",
      renderCell: (params: any) => {
        return (
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <IconButton onClick={()=>navigate("/update-profile")} size="small" disableRipple sx={{ color: "#177694" }}>
              <Edit />
            </IconButton>
            <IconButton disableRipple sx={{ color: "green" }}>
              <Check />
            </IconButton>
            <IconButton disableRipple sx={{ color: "red" }}>
              <Close />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <DataGrid
      sx={{
        color: "black",
        "-webkit-text-fill-color": "black",
        fontSize: "1rem",
        fontWeight: "normal",
        mt: 2,
        "& .MuiDataGrid-footerContainer": {
          height: "40px",
        },
      }}
      filterModel={{
        items: [
          { field: "tutorName", operator: "contains", value: searchText },
        ],
      }}
      rows={data}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      autoHeight
      pageSizeOptions={[10]}
      disableRowSelectionOnClick
      disableColumnMenu
      rowHeight={40}
      columnHeaderHeight={40}
    />
  );
}
