// authSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { serverUrl } from "../common/types";

export interface TeacherSubjectGradeMapping {
  id?: string | number;
  userId?: string;
  subjectCode: string;
  subjectName: string;
  gradeCode: string;
  boardCode?: string;
  popularityLevel?: string;
  isDeleted?: string;
}

export interface TeacherAreaMapping {
  id?: string | number;
  userId?: string;
  teachingAreaCode: string;
  teachingAreaName: string;
  teachingAreaPinCode: string;
  teachingAreaCity?: string;
  teachingAreaState?: string;
  teachingAreaCountry?: string;
  teachingAreaCountryCode?: string;
  isDeleted?: string;
  createDate?: string;
  updateDate?: string;
  createUserId?: string;
  updateUserId?: string;
}

export interface TeacherState {
  teacherDetails: any;
  teacherSubjectGradeMappings: TeacherSubjectGradeMapping[];
  teacherAreaMappings: TeacherAreaMapping[];
  teacherLoading: boolean;
}

export const getTeacherDetails = createAsyncThunk(
  "teacher/getTeacherDetails",
  async (
    {
      teacherId,
      callback,
    }: {
      teacherId: string;
      callback?: any;
    },
    { rejectWithValue }
  ) => {
    const res = await fetch(
      `${serverUrl}/api/Admin/user/GetTeacherDetails/${teacherId}`
    );
    const data = await res.json();
    if (callback) callback();
    return data;
  }
);

export const deleteArea = createAsyncThunk(
  "teacher/deleteArea",
  async (
    { id, data, callback }: { id: any; data: any; callback?: any },
    { rejectWithValue }
  ) => {
    try {
      const url = `${serverUrl}/api/Admin/user/UpdateTeacherAreaMapping/${id}`;
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
        }),
      });
      const result = await res.json();
      if (callback) callback();
      return result;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

export const addArea = createAsyncThunk(
  "teacher/addArea",
  async (
    { id, data, callback }: { id: any; data: any; callback?: any },
    { rejectWithValue }
  ) => {
    try {
      const url = `${serverUrl}/api/Admin/user/AddTeacherAreaMapping/${id}`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await res.json();
      if (callback) callback();
      return result;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

export const addNewSubjects = createAsyncThunk(
  "teacher/addNewSubjects",
  async (
    { id, data, callback }: { id: any; data: any; callback?: any },
    { rejectWithValue }
  ) => {
    try {
      const url = `${serverUrl}/api/Admin/user/AddTeacherSubjectMappings/${id}`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await res.json();
      if (callback) callback();
      return result;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

export const deleteSelectedSubject = createAsyncThunk(
  "teacher/deleteSelectedSubject",
  async (
    { id, data, callback }: { id: any; data: any; callback?: any },
    { rejectWithValue }
  ) => {
    try {
      const url = `${serverUrl}/api/Admin/user/UpdateTeacherSubjectMappings/${id}`;
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
        }),
      });
      const result = await res.json();
      if (callback) callback();
      return result;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

const initialState: TeacherState = {
  teacherDetails: {},
  teacherAreaMappings: [],
  teacherSubjectGradeMappings: [],
  teacherLoading: false,
};

const teacherSlice = createSlice({
  name: "teacher",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeacherDetails.pending, (state, { payload }) => {
        return { ...state, teacherLoading: true };
      })
      .addCase(getTeacherDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          teacherDetails: payload?.response?.userDetails,
          teacherAreaMappings: payload?.response?.teacherAreaMappings,
          teacherSubjectGradeMappings:
            payload?.response?.teacherSubjectGradeMappings,
          teacherLoading: false,
        };
      })
      .addCase(getTeacherDetails.rejected, (state, { payload }) => {
        return { ...state, teacherLoading: false };
      })
      .addCase(deleteArea.pending, (state, { payload }) => {
        return { ...state, teacherLoading: true };
      })
      .addCase(deleteArea.fulfilled, (state, { payload }) => {
        return {
          ...state,
          teacherLoading: false,
        };
      })
      .addCase(deleteArea.rejected, (state, { payload }) => {
        return { ...state, teacherLoading: false };
      })
      .addCase(addArea.pending, (state, { payload }) => {
        return { ...state, teacherLoading: true };
      })
      .addCase(addArea.fulfilled, (state, { payload }) => {
        return {
          ...state,
          teacherLoading: false,
        };
      })
      .addCase(addArea.rejected, (state, { payload }) => {
        return { ...state, teacherLoading: false };
      })
      .addCase(deleteSelectedSubject.pending, (state, { payload }) => {
        return { ...state, teacherLoading: true };
      })
      .addCase(deleteSelectedSubject.fulfilled, (state, { payload }) => {
        return {
          ...state,
          teacherLoading: false,
        };
      })
      .addCase(deleteSelectedSubject.rejected, (state, { payload }) => {
        return { ...state, teacherLoading: false };
      })
      .addCase(addNewSubjects.pending, (state, { payload }) => {
        return { ...state, teacherLoading: true };
      })
      .addCase(addNewSubjects.fulfilled, (state, { payload }) => {
        return {
          ...state,
          teacherLoading: false,
        };
      })
      .addCase(addNewSubjects.rejected, (state, { payload }) => {
        return { ...state, teacherLoading: false };
      });
  },
});

export const getTeacher = (state: { teacher: TeacherState }) =>
  state.teacher.teacherDetails;
export const getTeacherArea = (state: { teacher: TeacherState }) =>
  state.teacher.teacherAreaMappings;
export const getTeacherSubjects = (state: { teacher: TeacherState }) =>
  state.teacher.teacherSubjectGradeMappings;
export const getTeacherLoading = (state: { teacher: TeacherState }) =>
  state.teacher.teacherLoading;

export default teacherSlice.reducer;
