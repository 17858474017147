import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import { ArrowBack, CloudUpload, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import TutorAvatar from "../../assets/avatar.jpg";
import { useState } from "react";
import General from "../common/editTutor/details/General";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../reducers/authSlice";
import imageCompression from 'browser-image-compression';
import { updateImage } from "../../reducers/homeSlice";
import { serverUrl } from "../../common/types";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function UpdateProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUserDetails);
  const [image, setImage] = useState<any>(`${serverUrl}/api/Files/image/${user.user.id}` || TutorAvatar);

  const handleImageChange = async (event:any) => {
    const imageFile = event.target.files[0];
    const options = {
      maxSizeMB: .3, 
      maxWidthOrHeight: 400, 
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      setImage(URL.createObjectURL(compressedFile));
      dispatch(
        updateImage({
          userId: user.user.id,
          data: compressedFile,
          callback: () => window.location.reload(),
        }) as any
      );
    } catch (error) {
      console.error('Error while compressing image:', error);
    }
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
      flexDirection={"row"}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: { xs: 2, sm: 2, md: 3 },
          borderRadius: 1,
          maxWidth: 900,
        }}
        bgcolor={"primary.main"}
        spacing={4}
        direction={{ xs: "column", sm: "column", md: "row" }}
      >
        <Stack direction={"column"} alignItems={"center"}>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            sx={{ width: "max-content", mb: 1, alignSelf: "flex-start" }}
            variant="text"
          >
            <ArrowBack fontSize="small" /> Back
          </Button>
          <Badge
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <IconButton
                sx={{
                  background: "linear-gradient(to right, #177694, #9c3)",
                  ":hover": { color: "rgb(0, 237, 100)" },
                }}
                color="secondary"
                size="small"
                component="label"
                role={undefined}
                tabIndex={-1}
              >
                <Edit />
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </IconButton>
            }
          >
            <Avatar
              sx={{ width: 150, height: 150, borderRadius: "8px" }}
              variant="square"
              src={image}
            />
          </Badge>
        </Stack>
        <Divider
          sx={{ bgcolor: "lightgrey", width: { sm: "100%", md: "1px" } }}
        />
        <General data={user} />
      </Stack>
    </Box>
  );
}
