import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Link, MenuItem, Stack } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import { login, resetPassword } from "../../reducers/authSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";
  const email = searchParams.get("email") || "";
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(false);

  const captureData = (type: string, value: string) => {
    setData((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const reset = () => {
    if (data.password !== data.confirmPassword) {
      setError(true);
    } else {
      setError(false);
      dispatch(
        resetPassword({
          email,
          password: data.password,
          confirmPassword: data.confirmPassword,
          token,
          callBack: () => {
            navigate("/login");
          },
        }) as any
      );
    }
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          justifyContent: "space-around",
          maxWidth: 500,
          width: "100%",
          //boxShadow: 2,
          borderRadius: 1,
          //border: "1px solid #3d4f58"
        }}
        spacing={2}
        bgcolor={"primary.main"}
      >
        <Stack>
          <Typography variant="h6">Reset Your Password!</Typography>
        </Stack>
        <CustomInput
          inputLabel="Email"
          required
          placeholder="Enter email"
          value={email}
          disabled
        />
        <CustomInput
          inputLabel="Password"
          required
          type="password"
          placeholder="Password"
          onChange={(e) => captureData("password", e.target.value)}
        />
        <CustomInput
          inputLabel="Confirm Password"
          required
          type="password"
          placeholder="Confirm Password"
          onChange={(e) => captureData("confirmPassword", e.target.value)}
        />
        {error &&
          <Typography color={"red"}>Passwords do not match</Typography>}
        <Button
          sx={{ mt: 1 }}
          fullWidth
          variant="contained"
          color="secondary"
          onClick={reset}
        >
          Reset Password
        </Button>
        <Typography marginTop={2} color="text.disabled" fontSize={"14px"}>
          Don't have an account?{" "}
          <Button onClick={() => navigate("/signup")}>Sign up</Button>
        </Typography>
      </Stack>
    </Box>
  );
}
