import { Box, Grid, Stack, Typography } from "@mui/material";
import Carousel from "../../components/Carousel";
import { useNavigate } from "react-router-dom";
import UsersCard from "../../components/UsersCard";
import Teacher from "../../assets/teacher.png";
import Student from "../../assets/student.png";
import TutorCard from "../../components/TutorCard";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../reducers/authSlice";
import SearchTutors from "../common/SearchTutors";
import {
  fetchFeaturedTutors,
  getFeaturedTutors,
} from "../../reducers/homeSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTeacherDetails } from "../../reducers/teacherSlice";
import { getAllBookings } from "../../reducers/bookingSlice";
import { serverUrl } from "../../common/types";

export const tutors = [
  {
    name: "Tutor 1",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 4,
  },
  {
    name: "Tutor 2",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 5,
  },
  {
    name: "Tutor 3",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 3.5,
  },
  {
    name: "Tutor 4",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 4,
  },
  {
    name: "Tutor 5",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 4.5,
  },
  {
    name: "Tutor 6",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 5,
  },
  {
    name: "Tutor 7",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 4,
  },
];

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const featuredTutors = useSelector(getFeaturedTutors);
  const { isAuthenticated, name, roles, user } = useSelector(getUserDetails);

  const goToDashboard = () => {
    roles[0] === "Student"
      ? navigate("/student")
      : roles[0] === "Teacher"
      ? navigate("/tutor")
      : dispatch(getAllBookings({ callback: () => navigate("/admin") }) as any);
  };

  // useEffect(() => {
  //   //dispatch(getListOfValues() as any);
  //   dispatch(fetchFeaturedTutors({data: "abc"}) as any);
  // }, []);

  return (
    <Stack alignItems={"center"} direction={"column"}>
      <Carousel />
      <Stack
        direction={"column"}
        width={{ xs: "100%", sm: "100%", md: "auto" }}
        spacing={1}
        position={{ xs: "relative", sm: "relative", md: "absolute" }}
        top={{ xs: 20, sm: 20, md: 270 }}
      >
        {/* <Typography alignSelf={"center"} variant="h6" color={"white"}>
        Search tutors near you
      </Typography> */}
        <SearchTutors />
      </Stack>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        top={{ xs: "40px", sm: "40px", md: "20px" }}
        position={"relative"}
      >
        {!isAuthenticated ? (
          <>
            <Grid item xs={12} sm={4} md={6}>
              <UsersCard
                onClick={() => navigate("/student")}
                imageSrc={Student}
                header="Are you a student?"
                body="Please click on get started to search and book tutors."
                btnText="Get Started"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={6}>
              <UsersCard
                onClick={() => navigate("/login")}
                imageSrc={Teacher}
                header="Are you a tutor?"
                body="Please click on get started to sign up or login as a tutor."
                btnText="Get Started"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={4} md={6}>
            <UsersCard
              onClick={goToDashboard}
              imageSrc={`${serverUrl}/api/Files/image/${user.id}`}
              header={name}
              body=""
              btnText="Dashboard"
            />
          </Grid>
        )}
      </Grid>
      <Stack
        mt={{ xs: "60px", sm: "60px", md: "40px" }}
        borderRadius={2}
        width={"100%"}
        direction={"column"}
        bgcolor={"linear-gradient(to right, #177694, #9c3)"}
        sx={{background: "linear-gradient(to right, #177694, #9c3)"}}
        p={2}
      >
        {featuredTutors.length > 0 && (
          <Typography alignSelf={"center"} fontSize={"24px"} sx={{color: "white"}} fontWeight={700}>
            Top-Rated Tutors:
          </Typography>
        )}
        <Grid
          container
          sx={{ paddingTop: "12px" }}
          spacing={{ xs: 1, sm: 2, md: 3 }}
          columns={{ xs: 4, sm: 9, md: 12 }}
        >
          {featuredTutors?.map(
            (_) =>
              _?.roleType === "Teacher" && (
                <Grid key={_.name} item xs={2} sm={3} md={2}>
                  <TutorCard
                    name={_.fullName}
                    experience={_.teacherQualification}
                    locality={_.areaName}
                    rating={5}
                    key={_.id}
                    imageSrc={`${serverUrl}/api/Files/image/${_.id}`}
                    onClick={() => {
                      dispatch(
                        getTeacherDetails({
                          teacherId: _.id,
                          callback: () => navigate(`/book-tutor?id=${_.id}`),
                        }) as any
                      );
                    }}
                  />
                </Grid>
              )
          )}
          
        </Grid>
      </Stack>
    </Stack>
  );
}

export default Home;
