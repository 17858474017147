import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Grid, Stack } from "@mui/material";
import BookingDetails from "../../components/BookingDetails";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../reducers/authSlice";

export default function Bookings({ bookings }: { bookings: any[] }) {
  const [open, setOpen] = React.useState(false);
  const [selectedBooking, setSelectedBooking] = React.useState<any>({});
  const { roles } = useSelector(getUserDetails);

  const handleClickOpen = (booking: any) => {
    setSelectedBooking(() => booking);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const details = {
    "Booked By": selectedBooking?.studentFullName,
    "Tutor Name": selectedBooking?.teacherFullName,
    Grade: selectedBooking?.requestedStudentGrade,
    Subject: selectedBooking?.requestedSubjectName,
    Board: selectedBooking?.studentBoard,
    Area: `${selectedBooking?.requestedAreaName}-${selectedBooking?.requestedAreaCode}`,
    "Booked on": selectedBooking?.createDate,
    Status: selectedBooking?.bookingStatus,
  };
  return (
    <>
      <Grid
        container
        //sx={{ paddingTop: "12px" }}
        spacing={{ xs: 1, sm: 1, md: 1 }}
        columns={{ xs: 4, sm: 9, md: 12 }}
      >
        <Grid item xs={4} sm={9} md={12}>
          <Stack
            p={2}
            borderBottom={"1px solid grey"}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography flex={1} textAlign={"center"}>
              {roles[0] === "Student" ? "Tutor Name" : "Booked By"}
            </Typography>
            <Typography flex={1} textAlign={"center"}>
              Booked On
            </Typography>
            <Typography flex={1} textAlign={"center"}>
              Status
            </Typography>
          </Stack>
        </Grid>
        {bookings.map((_) => (
          <Grid
            sx={{ display: _.isDeleted === "Y" ? "none" : "auto" }}
            key={_.name}
            item
            xs={4}
            sm={9}
            md={12}
          >
            <Stack
              onClick={() => {
                handleClickOpen(_);
              }}
              p={2}
              bgcolor={"background.default"}
              borderRadius={1}
              direction={"row"}
              sx={{
                ":hover": {
                  // opacity: 1,
                  bgcolor: "#177694",
                  cursor: "pointer",
                  color: "white",
                },
                opacity: 0.75,
              }}
            >
              <Typography flex={1} textAlign={"center"}>
                {roles[0] === "Student" ? _.teacherFullName : _.studentFullName}
              </Typography>
              <Typography flex={1} textAlign={"center"}>
                {new Date(_.createDate).toLocaleDateString()}
              </Typography>
              <Typography flex={1} textAlign={"center"}>
                {_.bookingStatus}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <BookingDetails
        open={open}
        bookingDetails={details}
        id={selectedBooking?.id}
        onClose={handleClose}
      />
    </>
  );
}
