// CustomAutocomplete.tsx
import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteProps,
} from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

interface CustomAutocompleteProps<T> {
  options: T[];
  getOptionLabel: (option: T) => string;
  onChange?: (event: React.SyntheticEvent, value: T | null) => void;
  value?: T | null;
  label?: string;
  variant?: "standard" | "outlined" | "filled";
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T
  ) => React.ReactNode;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  inputLabel?: string;
  required: boolean;
  disabled?: boolean;
  getOptionDisabled?: (option: T) => boolean;
}

const CustomAutocomplete = <T,>({
  options,
  getOptionLabel,
  renderOption,
  renderInput,
  onChange,
  value,
  label = "Select an option",
  variant = "outlined",
  inputLabel,
  required,
  disabled = false,
  getOptionDisabled,
  ...props
}: CustomAutocompleteProps<T>) => {
  return (
    <Stack direction={"column"} flex={1} spacing={1}>
      <Typography>
        {inputLabel}{" "}
        {inputLabel && required && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <Autocomplete<T, false, false, false>
        options={options}
        size="small"
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={(params) =>
          renderInput ? (
            renderInput(params)
          ) : (
            <TextField {...params} variant={variant} fullWidth />
          )
        }
        onChange={onChange}
        value={value}
        disabled={disabled}
        getOptionDisabled={getOptionDisabled}
        sx={{ "& .MuiTextField-root": { marginBottom: 0 }, marginBottom: 0 }}
        {...props}
      />
    </Stack>
  );
};

export default CustomAutocomplete;
