export interface RegisterationData {
  username: string;
  email: string;
  password: string;
  roles: string[];
  phoneNumber: string;
  twoFactorEnabled: boolean;
  teacherSubjectMappings?: SubjectsMappingData[];
  teacherServiceAreaMappings?: AreaMappingData[];
  addressLine1?: string;
  addressLine2?: string;
  pinCode: string;
  state: string;
  country: string;
  areaCode: string;
  areaName: string;
  city: string;
  countryCode: string;
  roleType: string;
  teacherQualification?: string;
  studentGrade?: string;
  profilePicture?: string;
  resume?: string;
  isActive?: string;
  isApproved?: string;
  dateOfBirth: string;
  gender: string;
  fullName: string;
  boardCode: string;
}

export interface SubjectsMappingData {
  gradeCode: string;
  popularityLevel: string;
  boardCode: string;
  isDeleted: string;
  subjects: { subjectName: string; subjectCode: string }[];
}

export interface AreaMappingData {
  teachingAreaCode: string;
  teachingAreaName: string;
  teachingAreaPinCode: string;
  teachingAreaCity: string;
  teachingAreaState: string;
  teachingAreaCountry: string;
  teachingAreaCountryCode: string;
  isDeleted: string;
  createDate: string;
  updateDate: string;
  createUserId: string;
  updateUserId: string;
}

export interface BookingDetailsData {
  studentUserId: string;
  studentUserName: string;
  studentFullName: string;
  teacherUserId: string;
  teacherUserName: string;
  teacherFullName: string;
  requestedSubjectId: string;
  requestedSubjectName: string;
  requestedAreaName: string;
  requestedAreaCode: string;
  requestedPinCode: string;
  requestedState: string;
  requestedCity: string;
  requestedCountryCode: string;
  requestedCountry: string;
  requestedStudentGrade: string;
  studentEmail: string;
  studentPhoneNumber: string;
  teacherEmail: string;
  teacherPhoneNumber: string;
  bookingStatus: string;
  registrationAmount: string;
  registrationStatus: string;
  createDate: string;
  updateDate: string;
  createUserId: string;
  updateUserId: string;
  paymentStartDate: string;
  paymentEndDate: string;
  sentToTeacher: string;
  enrollmentFee: number;
  enrollmentStatus: string;
  demoStatus: string;
  studentBoard: string;
  showHideFlag: string;
  adminNotes: string;
  teacherRating: number;
  teacherFeedback: string;
  isDeleted: string;
}

export const serverUrl = "https://api.tutorforsure.com";

export const masterData = {
  isSuccess: true,
  message: "Master tables details fetched successfully",
  statusCode: 200,
  response: {
    subjectGradeMappings: [
      {
        id: 1,
        subjectCode: "SC",
        subjectName: "Science",
        subjectDescription: "Science",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 6-8",
      },
      {
        id: 2,
        subjectCode: "SC",
        subjectName: "Science",
        subjectDescription: "Science",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 3,
        subjectCode: "SC",
        subjectName: "Science",
        subjectDescription: "Science",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 10",
      },
      {
        id: 4,
        subjectCode: "PH",
        subjectName: "Physics",
        subjectDescription: "Physics",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 5,
        subjectCode: "PH",
        subjectName: "Physics",
        subjectDescription: "Physics",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 6,
        subjectCode: "CH",
        subjectName: "Chemistry",
        subjectDescription: "Chemistry",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 7,
        subjectCode: "CH",
        subjectName: "Chemistry",
        subjectDescription: "Chemistry",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 8,
        subjectCode: "BI",
        subjectName: "Biology",
        subjectDescription: "Biology",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 9,
        subjectCode: "BI",
        subjectName: "Biology",
        subjectDescription: "Biology",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 10,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 11,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 12,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 13,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 14,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 15,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 16,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 17,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 18,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 19,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 20,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 21,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 22,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 23,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 24,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 25,
        subjectCode: "SST",
        subjectName: "Social Science",
        subjectDescription: "Social Science",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 26,
        subjectCode: "SST",
        subjectName: "Social Science",
        subjectDescription: "Social Science",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 27,
        subjectCode: "SST",
        subjectName: "Social Science",
        subjectDescription: "Social Science",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 28,
        subjectCode: "HS",
        subjectName: "History",
        subjectDescription: "History",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 29,
        subjectCode: "HS",
        subjectName: "History",
        subjectDescription: "History",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 30,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 31,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 32,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 33,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 34,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 35,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 36,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 37,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 9",
      },
      {
        id: 38,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 39,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 42,
        subjectCode: "AL",
        subjectName: "All Subjects",
        subjectDescription: "All Subjects",
        gradeCode: "1 to 5",
        gradeName: "Class 1-5",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 1-5",
      },
      {
        id: 43,
        subjectCode: "AC",
        subjectName: " Accountancy ",
        subjectDescription: "Accountancy",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 44,
        subjectCode: "AC",
        subjectName: "Accountancy",
        subjectDescription: "Accountancy",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 45,
        subjectCode: "BS",
        subjectName: "Business Studies",
        subjectDescription: "Business Studies",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 46,
        subjectCode: "BS",
        subjectName: "Business Studies",
        subjectDescription: "Business Studies",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 47,
        subjectCode: "EC",
        subjectName: "Economics",
        subjectDescription: "Economics",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 48,
        subjectCode: "EC",
        subjectName: "Economics",
        subjectDescription: "Economics",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 49,
        subjectCode: "EN",
        subjectName: "Entrepreneurship ",
        subjectDescription: "Entrepreneurship",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 50,
        subjectCode: "EN",
        subjectName: "Entrepreneurship",
        subjectDescription: "Entrepreneurship",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 51,
        subjectCode: "GE",
        subjectName: "Geography ",
        subjectDescription: "Geography",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 52,
        subjectCode: "GE",
        subjectName: "Geography",
        subjectDescription: "Geography",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 53,
        subjectCode: "PO",
        subjectName: "Political science ",
        subjectDescription: "Political science ",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 54,
        subjectCode: "PO",
        subjectName: "Political science ",
        subjectDescription: "Political science ",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 55,
        subjectCode: "SO",
        subjectName: "Sociology ",
        subjectDescription: "Sociology",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 56,
        subjectCode: "SO",
        subjectName: "Sociology",
        subjectDescription: "Sociology",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 57,
        subjectCode: "PH",
        subjectName: "Philosophy ",
        subjectDescription: "Philosophy",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 58,
        subjectCode: "PH",
        subjectName: "Philosophy",
        subjectDescription: "Philosophy",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 59,
        subjectCode: "PS",
        subjectName: "Psychology ",
        subjectDescription: "Psychology",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 11",
      },
      {
        id: 60,
        subjectCode: "PS",
        subjectName: "Psychology",
        subjectDescription: "Psychology",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "CBSE",
        boardName: "CBSE",
        gradeDescription: "Class 12",
      },
      {
        id: 61,
        subjectCode: "SC",
        subjectName: "Science",
        subjectDescription: "Science",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 6-8",
      },
      {
        id: 62,
        subjectCode: "SC",
        subjectName: "Science",
        subjectDescription: "Science",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 63,
        subjectCode: "SC",
        subjectName: "Science",
        subjectDescription: "Science",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 10",
      },
      {
        id: 64,
        subjectCode: "PH",
        subjectName: "Physics",
        subjectDescription: "Physics",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 65,
        subjectCode: "PH",
        subjectName: "Physics",
        subjectDescription: "Physics",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 66,
        subjectCode: "CH",
        subjectName: "Chemistry",
        subjectDescription: "Chemistry",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 67,
        subjectCode: "CH",
        subjectName: "Chemistry",
        subjectDescription: "Chemistry",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 68,
        subjectCode: "BI",
        subjectName: "Biology",
        subjectDescription: "Biology",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 69,
        subjectCode: "BI",
        subjectName: "Biology",
        subjectDescription: "Biology",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 70,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 71,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 72,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 73,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 74,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 75,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 76,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 77,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 78,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 79,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 80,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 81,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 82,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 83,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 84,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 85,
        subjectCode: "SST",
        subjectName: "Social Science",
        subjectDescription: "Social Science",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 86,
        subjectCode: "SST",
        subjectName: "Social Science",
        subjectDescription: "Social Science",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 87,
        subjectCode: "SST",
        subjectName: "Social Science",
        subjectDescription: "Social Science",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 88,
        subjectCode: "HS",
        subjectName: "History",
        subjectDescription: "History",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 89,
        subjectCode: "HS",
        subjectName: "History",
        subjectDescription: "History",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 90,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 91,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 92,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 93,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 94,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 95,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 96,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 97,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 9",
      },
      {
        id: 98,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 101,
        subjectCode: "SC",
        subjectName: "Science",
        subjectDescription: "Science",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 10",
      },
      {
        id: 102,
        subjectCode: "AL",
        subjectName: "All Subjects",
        subjectDescription: "All Subjects",
        gradeCode: "1 to 5",
        gradeName: "Class 1-5",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 1-5",
      },
      {
        id: 103,
        subjectCode: "AC",
        subjectName: " Accountancy ",
        subjectDescription: "Accountancy",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 104,
        subjectCode: "AC",
        subjectName: "Accountancy",
        subjectDescription: "Accountancy",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 105,
        subjectCode: "BS",
        subjectName: "Business Studies",
        subjectDescription: "Business Studies",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 106,
        subjectCode: "BS",
        subjectName: "Business Studies",
        subjectDescription: "Business Studies",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 107,
        subjectCode: "EC",
        subjectName: "Economics",
        subjectDescription: "Economics",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 108,
        subjectCode: "EC",
        subjectName: "Economics",
        subjectDescription: "Economics",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 109,
        subjectCode: "EN",
        subjectName: "Entrepreneurship ",
        subjectDescription: "Entrepreneurship",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 110,
        subjectCode: "EN",
        subjectName: "Entrepreneurship",
        subjectDescription: "Entrepreneurship",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 111,
        subjectCode: "GE",
        subjectName: "Geography ",
        subjectDescription: "Geography",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 112,
        subjectCode: "GE",
        subjectName: "Geography",
        subjectDescription: "Geography",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 113,
        subjectCode: "PO",
        subjectName: "Political science ",
        subjectDescription: "Political science ",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 114,
        subjectCode: "PO",
        subjectName: "Political science ",
        subjectDescription: "Political science ",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 115,
        subjectCode: "SO",
        subjectName: "Sociology ",
        subjectDescription: "Sociology",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 116,
        subjectCode: "SO",
        subjectName: "Sociology",
        subjectDescription: "Sociology",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 117,
        subjectCode: "PH",
        subjectName: "Philosophy ",
        subjectDescription: "Philosophy",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 118,
        subjectCode: "PH",
        subjectName: "Philosophy",
        subjectDescription: "Philosophy",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 119,
        subjectCode: "PS",
        subjectName: "Psychology ",
        subjectDescription: "Psychology",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 11",
      },
      {
        id: 120,
        subjectCode: "PS",
        subjectName: "Psychology",
        subjectDescription: "Psychology",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "ICSE",
        boardName: "ICSE",
        gradeDescription: "Class 12",
      },
      {
        id: 121,
        subjectCode: "SC",
        subjectName: "Science",
        subjectDescription: "Science",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 6-8",
      },
      {
        id: 122,
        subjectCode: "SC",
        subjectName: "Science",
        subjectDescription: "Science",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 123,
        subjectCode: "SC",
        subjectName: "Science",
        subjectDescription: "Science",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 10",
      },
      {
        id: 124,
        subjectCode: "PH",
        subjectName: "Physics",
        subjectDescription: "Physics",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 125,
        subjectCode: "PH",
        subjectName: "Physics",
        subjectDescription: "Physics",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 126,
        subjectCode: "CH",
        subjectName: "Chemistry",
        subjectDescription: "Chemistry",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 127,
        subjectCode: "CH",
        subjectName: "Chemistry",
        subjectDescription: "Chemistry",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 128,
        subjectCode: "BI",
        subjectName: "Biology",
        subjectDescription: "Biology",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 129,
        subjectCode: "BI",
        subjectName: "Biology",
        subjectDescription: "Biology",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 130,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 131,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 132,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 133,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 134,
        subjectCode: "MT",
        subjectName: "Mathematics",
        subjectDescription: "Mathematics",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 135,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 136,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 137,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 138,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 139,
        subjectCode: "EN",
        subjectName: "English",
        subjectDescription: "English",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 140,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 141,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 142,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 143,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 144,
        subjectCode: "CS",
        subjectName: "Computer Science",
        subjectDescription: "Computer Science",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 145,
        subjectCode: "SST",
        subjectName: "Social Science",
        subjectDescription: "Social Science",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 146,
        subjectCode: "SST",
        subjectName: "Social Science",
        subjectDescription: "Social Science",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 147,
        subjectCode: "SST",
        subjectName: "Social Science",
        subjectDescription: "Social Science",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 148,
        subjectCode: "HS",
        subjectName: "History",
        subjectDescription: "History",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 149,
        subjectCode: "HS",
        subjectName: "History",
        subjectDescription: "History",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 150,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 151,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 152,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 153,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 154,
        subjectCode: "HN",
        subjectName: "Hindi",
        subjectDescription: "Hindi",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 155,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "6 to 8",
        gradeName: "Class 6-8",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 6 to 8",
      },
      {
        id: 156,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "9",
        gradeName: "Class 9",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 157,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 9",
      },
      {
        id: 158,
        subjectCode: "SN",
        subjectName: "Sanskrit",
        subjectDescription: "Sanskrit",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 161,
        subjectCode: "SC",
        subjectName: "Science",
        subjectDescription: "Science",
        gradeCode: "10",
        gradeName: "Class 10",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 10",
      },
      {
        id: 162,
        subjectCode: "AL",
        subjectName: "All Subjects",
        subjectDescription: "All Subjects",
        gradeCode: "1 to 5",
        gradeName: "Class 1-5",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 1-5",
      },
      {
        id: 163,
        subjectCode: "AC",
        subjectName: " Accountancy ",
        subjectDescription: "Accountancy",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 164,
        subjectCode: "AC",
        subjectName: "Accountancy",
        subjectDescription: "Accountancy",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 165,
        subjectCode: "BS",
        subjectName: "Business Studies",
        subjectDescription: "Business Studies",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 166,
        subjectCode: "BS",
        subjectName: "Business Studies",
        subjectDescription: "Business Studies",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 167,
        subjectCode: "EC",
        subjectName: "Economics",
        subjectDescription: "Economics",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 168,
        subjectCode: "EC",
        subjectName: "Economics",
        subjectDescription: "Economics",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 169,
        subjectCode: "EN",
        subjectName: "Entrepreneurship ",
        subjectDescription: "Entrepreneurship",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 170,
        subjectCode: "EN",
        subjectName: "Entrepreneurship",
        subjectDescription: "Entrepreneurship",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 171,
        subjectCode: "GE",
        subjectName: "Geography ",
        subjectDescription: "Geography",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 172,
        subjectCode: "GE",
        subjectName: "Geography",
        subjectDescription: "Geography",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 173,
        subjectCode: "PO",
        subjectName: "Political science ",
        subjectDescription: "Political science ",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 174,
        subjectCode: "PO",
        subjectName: "Political science ",
        subjectDescription: "Political science ",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 175,
        subjectCode: "SO",
        subjectName: "Sociology ",
        subjectDescription: "Sociology",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 176,
        subjectCode: "SO",
        subjectName: "Sociology",
        subjectDescription: "Sociology",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 177,
        subjectCode: "PH",
        subjectName: "Philosophy ",
        subjectDescription: "Philosophy",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 178,
        subjectCode: "PH",
        subjectName: "Philosophy",
        subjectDescription: "Philosophy",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
      {
        id: 179,
        subjectCode: "PS",
        subjectName: "Psychology ",
        subjectDescription: "Psychology",
        gradeCode: "11",
        gradeName: "Class 11",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 11",
      },
      {
        id: 180,
        subjectCode: "PS",
        subjectName: "Psychology",
        subjectDescription: "Psychology",
        gradeCode: "12",
        gradeName: "Class 12",
        boardCode: "BSEB",
        boardName: "BSEB",
        gradeDescription: "Class 12",
      },
    ],
    locationMasterDetails: [
      {
        id: 1,
        areaName: "Frasar Road",
        areaCode: "FR",
        areaPinCode: 800001,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 2,
        areaName: "Exhibition Road",
        areaCode: "EX",
        areaPinCode: 800001,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 3,
        areaName: "Boring Road",
        areaCode: "BO",
        areaPinCode: 800001,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 4,
        areaName: "Anandpuri",
        areaCode: "AND",
        areaPinCode: 800001,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 5,
        areaName: "Kidwaipuri",
        areaCode: "KID",
        areaPinCode: 800001,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 6,
        areaName: "Chajju Bagh",
        areaCode: "CH",
        areaPinCode: 800001,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 7,
        areaName: "Gardanibagh Jakkanpur",
        areaCode: "GAJ",
        areaPinCode: 800001,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 8,
        areaName: "Chiraiyantand",
        areaCode: "CHI",
        areaPinCode: 800001,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 9,
        areaName: "Gardanibagh patna sadar",
        areaCode: "GAP",
        areaPinCode: 800001,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 10,
        areaName: "Gardanibagh Old Jakkanpur",
        areaCode: "GAO",
        areaPinCode: 800001,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 11,
        areaName: "Hotel Republic",
        areaCode: "HR",
        areaPinCode: 800001,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 12,
        areaName: "Jai Prakash Nagar",
        areaCode: "JPN",
        areaPinCode: 800001,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 13,
        areaName: "Anishabad",
        areaCode: "ANI",
        areaPinCode: 800002,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 14,
        areaName: "Aitwarpur",
        areaCode: "AIT",
        areaPinCode: 800002,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 15,
        areaName: "Beur Patna Sadar",
        areaCode: "BURP",
        areaPinCode: 800002,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 16,
        areaName: "Beur Patna",
        areaCode: "BUR",
        areaPinCode: 800002,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 17,
        areaName: "Beur Kisan Colony",
        areaCode: "BURK",
        areaPinCode: 800002,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 18,
        areaName: "Chitkohra",
        areaCode: "CHK",
        areaPinCode: 800002,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 19,
        areaName: "Gayatri Nagar",
        areaCode: "GN",
        areaPinCode: 800002,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 20,
        areaName: "Phulwari Sharif Police Colony",
        areaCode: "PSPC",
        areaPinCode: 800002,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 21,
        areaName: "Sipara Darsratha",
        areaCode: "SD",
        areaPinCode: 800002,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 22,
        areaName: "Anishabad Patna Sadar",
        areaCode: "ANIP",
        areaPinCode: 800002,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 23,
        areaName: "Lohanipur",
        areaCode: "LHN",
        areaPinCode: 800003,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 24,
        areaName: "Rajendra Nagar",
        areaCode: "RN",
        areaPinCode: 800003,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 25,
        areaName: "Kadamkuan Patna",
        areaCode: "KP",
        areaPinCode: 800003,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 26,
        areaName: "Bakarganj",
        areaCode: "BA",
        areaPinCode: 800004,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 27,
        areaName: "Bhikhana Pahari",
        areaCode: "BHI",
        areaPinCode: 800004,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 28,
        areaName: "Bari Path",
        areaCode: "BP",
        areaPinCode: 800004,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 29,
        areaName: "Khazanchi Road",
        areaCode: "KHR",
        areaPinCode: 800004,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 30,
        areaName: "Nayatola",
        areaCode: "NT",
        areaPinCode: 800004,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 31,
        areaName: "Bahadurpur",
        areaCode: "BAH",
        areaPinCode: 800004,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 32,
        areaName: "Bakarganj Sabjibagh",
        areaCode: "BAS",
        areaPinCode: 800004,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 33,
        areaName: "Bankipore",
        areaCode: "BAN",
        areaPinCode: 800004,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 34,
        areaName: "Machhuatoli",
        areaCode: "MA",
        areaPinCode: 800004,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 35,
        areaName: "Machhuatoli Patna Sadar",
        areaCode: "MAP",
        areaPinCode: 800004,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 36,
        areaName: "Lalbagh",
        areaCode: "LAL",
        areaPinCode: 800005,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 37,
        areaName: "Patna University",
        areaCode: "PU",
        areaPinCode: 800005,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 38,
        areaName: "M.Y.Sandalpur Patna Sadar",
        areaCode: "MYS",
        areaPinCode: 800006,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 39,
        areaName: "Rajendra Nagar 06",
        areaCode: "RJB",
        areaPinCode: 800006,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 40,
        areaName: "Tripolia",
        areaCode: "TRI",
        areaPinCode: 800006,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 41,
        areaName: "Mahendru Patna Sadar",
        areaCode: "MPS",
        areaPinCode: 800006,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 42,
        areaName: "J6F9+WV Terasiy,Terasiya ",
        areaCode: "JT",
        areaPinCode: 800006,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 43,
        areaName: "Muhammadpur",
        areaCode: "MDPR",
        areaPinCode: 800006,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 44,
        areaName: "New Azimbad Colony",
        areaCode: "NAC",
        areaPinCode: 800006,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 45,
        areaName: "Gulbi Ghat",
        areaCode: "GG",
        areaPinCode: 800006,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 46,
        areaName: "Bajrangpuri",
        areaCode: "BJP",
        areaPinCode: 800007,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 47,
        areaName: "Guljarbagh",
        areaCode: "GUL",
        areaPinCode: 800007,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 48,
        areaName: "Alamganj",
        areaCode: "ALM",
        areaPinCode: 800007,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 49,
        areaName: "Nadghat",
        areaCode: "NAD",
        areaPinCode: 800007,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 50,
        areaName: "Nanmuhiya Patna Sadar",
        areaCode: "NAN",
        areaPinCode: 800007,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 51,
        areaName: "Pahari Patna Sadar",
        areaCode: "PHR",
        areaPinCode: 800007,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 52,
        areaName: "Sardar Patel Colony",
        areaCode: "SPC",
        areaPinCode: 800007,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 53,
        areaName: "Sharp Extention colony",
        areaCode: "SEC",
        areaPinCode: 800007,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 54,
        areaName: "SH 1 Sampatchak Gopalpur",
        areaCode: "SHG",
        areaPinCode: 800007,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 55,
        areaName: "Bairia Patna Sadar",
        areaCode: "BRA",
        areaPinCode: 800007,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 56,
        areaName: "Bihari Mills Colony",
        areaCode: "BMC",
        areaPinCode: 800008,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 57,
        areaName: "Gurhatta Hatta",
        areaCode: "GH",
        areaPinCode: 800008,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 58,
        areaName: "Chaughara Patna Sadar",
        areaCode: "CPS",
        areaPinCode: 800008,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 59,
        areaName: "Diwan Mohalla Patna Sadar",
        areaCode: "DIP",
        areaPinCode: 800008,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 60,
        areaName: "Mangal Talab Road Patna",
        areaCode: "MTR",
        areaPinCode: 800008,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 61,
        areaName: "Marufganj Patna Sadar",
        areaCode: "MPS",
        areaPinCode: 800008,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 62,
        areaName: "Paschim Darwaja Patna Sadar",
        areaCode: "PDP",
        areaPinCode: 800008,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 63,
        areaName: "Patna City Patna Sadar",
        areaCode: "PCP",
        areaPinCode: 800008,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 64,
        areaName: "Deedarganj",
        areaCode: "DD",
        areaPinCode: 800008,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 65,
        areaName: "Begampur Karmali Chauk",
        areaCode: "BKC",
        areaPinCode: 800009,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 66,
        areaName: "Begumpur Patna Sadar",
        areaCode: "BPS",
        areaPinCode: 800009,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 67,
        areaName: "Kothia Patna Sadar",
        areaCode: "KPS",
        areaPinCode: 800009,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 68,
        areaName: "Punadih Patna Sadar",
        areaCode: "PPS",
        areaPinCode: 800009,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 69,
        areaName: "Sabalpur Patna Sadar",
        areaCode: "SPS",
        areaPinCode: 800009,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 70,
        areaName: "Simli Murarour Sambalpur",
        areaCode: "SMS",
        areaPinCode: 800009,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 71,
        areaName: "Maranchi Patna Sadar",
        areaCode: "MRP",
        areaPinCode: 800009,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 72,
        areaName: "Tillak Nagar Kothiya",
        areaCode: "TNK",
        areaPinCode: 800009,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 73,
        areaName: "Digha Ghat",
        areaCode: "DG",
        areaPinCode: 800010,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 74,
        areaName: "Kurji More",
        areaCode: "KM",
        areaPinCode: 800010,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 75,
        areaName: "Pataliputra Colony",
        areaCode: "PCP",
        areaPinCode: 800010,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 76,
        areaName: "Sadaquat Ashram Patna Sadar",
        areaCode: "SAP",
        areaPinCode: 800010,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 77,
        areaName: "Balapur",
        areaCode: "BAL",
        areaPinCode: 800010,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 78,
        areaName: "Pataliputra Colony Sadaquat Ashram",
        areaCode: "PSA",
        areaPinCode: 800010,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 79,
        areaName: "Digha",
        areaCode: "DI",
        areaPinCode: 800011,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 80,
        areaName: "Digha Ghat Patna Sadar",
        areaCode: "DGP",
        areaPinCode: 800011,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 81,
        areaName: "Makhdumpur Digha Patna Sadar",
        areaCode: "MDPS",
        areaPinCode: 800011,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 82,
        areaName: "Birla Colony",
        areaCode: "BC",
        areaPinCode: 800011,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 83,
        areaName: "Danapur Nizamat Mithila Colony",
        areaCode: "DNR",
        areaPinCode: 800011,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 84,
        areaName: "Yadav Colony",
        areaCode: "YC",
        areaPinCode: 800011,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 85,
        areaName: "TawariTola",
        areaCode: "TT",
        areaPinCode: 800011,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 86,
        areaName: "Akilpur",
        areaCode: "AKL",
        areaPinCode: 800012,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 87,
        areaName: "Danapur Main Road",
        areaCode: "DMR",
        areaPinCode: 800012,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 88,
        areaName: "Danapur Nizamat Defence Colony",
        areaCode: "DND",
        areaPinCode: 800012,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 89,
        areaName: "Danapur Nizamat Takiapar",
        areaCode: "DNT",
        areaPinCode: 800012,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 90,
        areaName: "Digha Patna Sadar",
        areaCode: "DPS",
        areaPinCode: 800012,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 91,
        areaName: "Naya Panapur",
        areaCode: "NAP",
        areaPinCode: 800012,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 92,
        areaName: "Panapur",
        areaCode: "PAN",
        areaPinCode: 800012,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 93,
        areaName: "North Sri Krishna Puri",
        areaCode: "NSK",
        areaPinCode: 800013,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 94,
        areaName: "Pataliputra Patna Sadar",
        areaCode: "PAS",
        areaPinCode: 800013,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 95,
        areaName: "Mainpura",
        areaCode: "MA",
        areaPinCode: 800013,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 96,
        areaName: "Bailey Road",
        areaCode: "BAR",
        areaPinCode: 800014,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 97,
        areaName: "Ashiayana More",
        areaCode: "AM",
        areaPinCode: 800014,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 98,
        areaName: "Bit Campus",
        areaCode: "BC",
        areaPinCode: 800014,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 99,
        areaName: "Indrapuri",
        areaCode: "IPR",
        areaPinCode: 800014,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 100,
        areaName: "Kapil Market Murlichak(jagdevpath)",
        areaCode: "KMM",
        areaPinCode: 800014,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 101,
        areaName: "Rajabazar Bailey Road",
        areaCode: "RBR",
        areaPinCode: 800014,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 102,
        areaName: "Rukanpura",
        areaCode: "RKN",
        areaPinCode: 800014,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 103,
        areaName: "Samanpura Fatehpur",
        areaCode: "SMN",
        areaPinCode: 800014,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 104,
        areaName: "Sheikhpura Patna Sadar",
        areaCode: "SHP",
        areaPinCode: 800014,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 105,
        areaName: "Sheikhpura Raja Bazar",
        areaCode: "SHR",
        areaPinCode: 800014,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 106,
        areaName: "Patna Sacretariat Patna Sadar",
        areaCode: "PATS",
        areaPinCode: 800015,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 107,
        areaName: "Sristabad",
        areaCode: "SRI",
        areaPinCode: 800015,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 108,
        areaName: "Rajbansi Nagar",
        areaCode: "RJBN",
        areaPinCode: 800015,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 109,
        areaName: "Rajendra Nagar",
        areaCode: "RJN",
        areaPinCode: 800016,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 110,
        areaName: "M.Kachhuara Patna Sadar",
        areaCode: "MKS",
        areaPinCode: 800016,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 111,
        areaName: "Rajendra Nagar Patna sadar",
        areaCode: "RJP",
        areaPinCode: 800016,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 112,
        areaName: "B.S.E.Board Patna Sadar",
        areaCode: "BSEB",
        areaPinCode: 800017,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 113,
        areaName: "Chhajju Bagh",
        areaCode: "CHJ",
        areaPinCode: 800017,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 114,
        areaName: "Bata Nagar Patna Sadar",
        areaCode: "BNP",
        areaPinCode: 800018,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 115,
        areaName: "Danapur Nizamat Patna",
        areaCode: "DNP",
        areaPinCode: 800018,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 116,
        areaName: "CDA Building Patna Sadar",
        areaCode: "CDA",
        areaPinCode: 800019,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 117,
        areaName: "Bankman Colony Kankarbagh",
        areaCode: "BCK",
        areaPinCode: 800020,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 118,
        areaName: "Kankarbagh ",
        areaCode: "KNK",
        areaPinCode: 800020,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 119,
        areaName: "Chitragupta Nagar",
        areaCode: "CGN",
        areaPinCode: 800020,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 120,
        areaName: "Ashok Nagar Patna Sadar",
        areaCode: "ASN",
        areaPinCode: 800020,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 121,
        areaName: "Kankarbagh Ashok Nagar",
        areaCode: "KNA",
        areaPinCode: 800020,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 122,
        areaName: "Kanti Factory Road",
        areaCode: "KFR",
        areaPinCode: 800020,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 123,
        areaName: "Kumhrar Bhagwat Nagar",
        areaCode: "KBN",
        areaPinCode: 800020,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 124,
        areaName: "Lohia Nagar Kankarbagh",
        areaCode: "LNK",
        areaPinCode: 800020,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 125,
        areaName: "New Aitwaepur",
        areaCode: "NEA",
        areaPinCode: 800020,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 126,
        areaName: "R.M.S Colony Patna Sadar",
        areaCode: "RMS",
        areaPinCode: 800020,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 127,
        areaName: "Vidyut Parisad Patna Sadar",
        areaCode: "VPP",
        areaPinCode: 800021,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 128,
        areaName: "B.G.Camp/Raj Bhawan Patna Sadar",
        areaCode: "BGC",
        areaPinCode: 800022,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 129,
        areaName: "Shivpuri",
        areaCode: "SVP",
        areaPinCode: 800023,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 130,
        areaName: "L.B.S Nagar Patna Sadar",
        areaCode: "LBS",
        areaPinCode: 800023,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 131,
        areaName: "North Shastri Nagar Adarsh Colony",
        areaCode: "NSA",
        areaPinCode: 800023,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 132,
        areaName: "Punaichak",
        areaCode: "PNC",
        areaPinCode: 800023,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 133,
        areaName: "Rajbansi Nagar 23",
        areaCode: "RJA",
        areaPinCode: 800023,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 134,
        areaName: "Shastri Nagar",
        areaCode: "SHN",
        areaPinCode: 800023,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 135,
        areaName: "Shivpuri Radha Krishna Mandir",
        areaCode: "RKM",
        areaPinCode: 800023,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 136,
        areaName: "North Shastri Nagar",
        areaCode: "NOS",
        areaPinCode: 800023,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 137,
        areaName: "Rajbanshi Nagar Moahanpur",
        areaCode: "RNM",
        areaPinCode: 800023,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 138,
        areaName: "Keshari Nagar",
        areaCode: "KN",
        areaPinCode: 800024,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 139,
        areaName: "Keshari nagar Patna Sadar",
        areaCode: "KNP",
        areaPinCode: 800024,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 140,
        areaName: "Rajeev Nagar Patna Sadar",
        areaCode: "RJN",
        areaPinCode: 800024,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 141,
        areaName: "Mahesh Nagar",
        areaCode: "MAN",
        areaPinCode: 800024,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 142,
        areaName: "Patel Nagar",
        areaCode: "PN",
        areaPinCode: 800024,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 143,
        areaName: "Ashiana Nagar Patna Sadar",
        areaCode: "ASH",
        areaPinCode: 800025,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 144,
        areaName: "Ram Nagari",
        areaCode: "RAM",
        areaPinCode: 800025,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 145,
        areaName: "Indrapuri 25",
        areaCode: "IDR",
        areaPinCode: 800025,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 146,
        areaName: "Jakariyapur",
        areaCode: "JAK",
        areaPinCode: 800025,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 147,
        areaName: "Rajeev Nagar",
        areaCode: "RAA",
        areaPinCode: 800025,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 148,
        areaName: "Rukanpura 25",
        areaCode: "RUKP",
        areaPinCode: 800025,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 149,
        areaName: "Magistrate Colony Rukanpura",
        areaCode: "MGC",
        areaPinCode: 800025,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 150,
        areaName: "Sheikhpura 25",
        areaCode: "SHH",
        areaPinCode: 800025,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 151,
        areaName: "AG colony",
        areaCode: "AGC",
        areaPinCode: 800025,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 152,
        areaName: "Ashiana Nagar Rukanpura",
        areaCode: "ANR",
        areaPinCode: 800025,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 153,
        areaName: "B.H.Colony Patna Sadar",
        areaCode: "BHC",
        areaPinCode: 800026,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 154,
        areaName: "Hanuman Nagar",
        areaCode: "HNM",
        areaPinCode: 800026,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 155,
        areaName: "Jai Prakash Nagar 26",
        areaCode: "JPR",
        areaPinCode: 800026,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 156,
        areaName: "Kumhrar Patna Sadar",
        areaCode: "KUP",
        areaPinCode: 800026,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 157,
        areaName: "Kumhrar",
        areaCode: "KUM",
        areaPinCode: 800026,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 158,
        areaName: "Vijay Nagar",
        areaCode: "VN",
        areaPinCode: 800026,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 159,
        areaName: "Gandhi Nagar",
        areaCode: "GN",
        areaPinCode: 800026,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 160,
        areaName: "Radha Krishna Nagar",
        areaCode: "RAK",
        areaPinCode: 800027,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 161,
        areaName: "Jaganpura",
        areaCode: "JAG",
        areaPinCode: 800027,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 162,
        areaName: "New Jaganpura Patna Sadar",
        areaCode: "NJG",
        areaPinCode: 800027,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 163,
        areaName: "Nayachak East Laxmie Nagar",
        areaCode: "NEL",
        areaPinCode: 800027,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 164,
        areaName: "Patna High Court",
        areaCode: "PHC",
        areaPinCode: 800028,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 165,
        areaName: "Shastri Nagar BSEB Colony",
        areaCode: "SNB",
        areaPinCode: 800029,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 166,
        areaName: "Rajbansi Nagar BSEB Colony",
        areaCode: "RNB",
        areaPinCode: 800029,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 167,
        areaName: "Gola Road",
        areaCode: "GB",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 168,
        areaName: "Near Gyan Niketan",
        areaCode: "GN",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 169,
        areaName: "DPS More",
        areaCode: "DPS",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 170,
        areaName: "Saguna More",
        areaCode: "SM",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 171,
        areaName: "BR Centre Khagaul",
        areaCode: "BRC",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 172,
        areaName: "Beapur Danapur",
        areaCode: "BED",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 173,
        areaName: "Chandmari Dinapur-Cum-Khagaul",
        areaCode: "CND",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 174,
        areaName: "Dilip Chak Danapur",
        areaCode: "DCD",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 175,
        areaName: "Danapur Bazar",
        areaCode: "DB",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 176,
        areaName: "Danapur Cantt.",
        areaCode: "DCT",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 177,
        areaName: "Jahankhan Road",
        areaCode: "JR",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 178,
        areaName: "Jamsaut Danapur",
        areaCode: "JD",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 179,
        areaName: "Jivrakhan Tola Danapur",
        areaCode: "JT",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 180,
        areaName: "Madhopur",
        areaCode: "MAD",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 181,
        areaName: "Lal Bengwa Danapur",
        areaCode: "LAL",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 182,
        areaName: "Ram Jaipal Nagar",
        areaCode: "RJD",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 183,
        areaName: "Sadikpur ",
        areaCode: "SAD",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 184,
        areaName: "Sherpur",
        areaCode: "SER",
        areaPinCode: 801503,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
      {
        id: 185,
        areaName: "Danapur Railway Station",
        areaCode: "DRS",
        areaPinCode: 801105,
        state: "Bihar",
        stateCode: "BR",
        country: "India",
        countryCode: "IN",
        city: "Patna",
        cityCode: "Pat",
      },
    ],
    featuredTeachers: [
      {
        refreshToken:
          "JyTn0papcSRw0YEe+L/hHaOQMVTKXlOLF8IwZo6NbF+kwGLa1+LC+8Aa0vu5fOs4ZAaLWvRA18nf1kLbKFtgaw==",
        refreshTokenExpiry: "2024-09-13T11:24:01.4193141",
        fullName: "Ambuj Kumar",
        addressLine1: "A 444",
        addressLine2: "",
        pinCode: "800025",
        state: "Bihar",
        country: "India",
        areaCode: "800025",
        areaName: "AG colony",
        city: "Patna",
        countryCode: "IN",
        roleType: "Teacher",
        teacherQualification: "PG",
        studentGrade: "",
        profilePicture: "blob_dd234d2c-04f6-4432-87f0-552c2f59d5dd",
        resume: "",
        isActive: "Y",
        isApproved: "N",
        createDate: "2024-09-06T10:28:23.4820161",
        updateDate: "2024-09-06T10:28:23.4820168",
        dateOfBirth: "22-3-1995",
        gender: "Male",
        createUserId: "TFS",
        updateUserId: "TFS",
        popularityLevel: 10,
        id: "d129ac75-1484-4a31-a88d-83180bc3f359",
        userName: "ambujdsc3@gmail.com",
        normalizedUserName: "AMBUJDSC3@GMAIL.COM",
        email: "ambujdsc3@gmail.com",
        normalizedEmail: "AMBUJDSC3@GMAIL.COM",
        emailConfirmed: false,
        passwordHash:
          "AQAAAAIAAYagAAAAEKUos+UbTGS5OQYn1NeqL3iVOD8HqE41N+wvwTHitySx6bA79R4lLbaSqBSZLSm5KA==",
        securityStamp: "EDN75OF4BWCHJFGOZS427T6AAMVBEZTM",
        concurrencyStamp: "ebd7141d-412d-411c-9d50-d515f74584a9",
        phoneNumber: "8882451255",
        phoneNumberConfirmed: false,
        twoFactorEnabled: false,
        lockoutEnd: null,
        lockoutEnabled: true,
        accessFailedCount: 0,
      },
      {
        refreshToken:
          "EcUXBOLFBkQUDi0bGw9q58p5/U4DhiHCeaLkU/wY1H1b8E6CKCO5NjrjXt69t0vu443CbGboeGHkHhdjhtNJ3Q==",
        refreshTokenExpiry: "2024-09-12T18:19:09.147308",
        fullName: "NISHANT KUMAR",
        addressLine1: "Exhibition Road",
        addressLine2: "salempur ahara",
        pinCode: "800001",
        state: "Bihar",
        country: "India",
        areaCode: "800001",
        areaName: "Exhibition Road",
        city: "Patna",
        countryCode: "IN",
        roleType: "Teacher",
        teacherQualification: "B.TECH, B.ed, STET, CTET",
        studentGrade: "",
        profilePicture: "blob_21292022-7911-48a2-821b-f2e81a9f434b",
        resume: "",
        isActive: "Y",
        isApproved: "N",
        createDate: "2024-09-05T18:18:32.1491397",
        updateDate: "2024-09-05T18:18:32.1491998",
        dateOfBirth: "3-7-1995",
        gender: "Male",
        createUserId: "TFS",
        updateUserId: "TFS",
        popularityLevel: 10,
        id: "e655f792-f71a-4d38-9bd0-18615ba14f15",
        userName: "nishantkumarsingh012@gmail.com",
        normalizedUserName: "NISHANTKUMARSINGH012@GMAIL.COM",
        email: "nishantkumarsingh012@gmail.com",
        normalizedEmail: "NISHANTKUMARSINGH012@GMAIL.COM",
        emailConfirmed: false,
        passwordHash:
          "AQAAAAIAAYagAAAAEJpzWj6zJnEpLnwTgrk2k7mwiLBwJIuRmsFLs2QvJZ2eMP/dMxEpadJqmKWHbABjwQ==",
        securityStamp: "F7H55H3NCU532QBV2D2R6XOVNVJV4KSN",
        concurrencyStamp: "3fa2e5e4-fa1a-4a47-86ad-f0b824579cf3",
        phoneNumber: "7060324993",
        phoneNumberConfirmed: false,
        twoFactorEnabled: false,
        lockoutEnd: null,
        lockoutEnabled: true,
        accessFailedCount: 0,
      },
    ],
  },
};
