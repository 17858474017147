import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StudentAvatar from "../../assets/student.png";
import { Avatar, Button, Divider, Stack } from "@mui/material";
import { ArrowBack, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomTabs from "../../components/CustomTabs";
import Bookings from "../bookings/Bookings";
import General from "../common/editTutor/details/General";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../reducers/authSlice";
import {
  getBookingDetails,
  getUsersBookings,
} from "../../reducers/bookingSlice";
import { useDispatch } from "react-redux";
import { serverUrl } from "../../common/types";

export default function Student() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(getUserDetails);
  const bookings = useSelector(getUsersBookings);
  const {
    fullName,
    areaName,
    studentGrade,
    areaCode,
    gender,
    addressLine1,
    addressLine2,
    email,
    phoneNumber,
    state,
    country,
    city,
    id,
    studentBoard
  } = user;

  const [activeTab, setActiveTab] = useState("about");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const tabs = [
    { label: "About", value: "about" },
    { label: "Bookings", value: "bookings" },
  ];

  useEffect(() => {
    dispatch(getBookingDetails({ id }) as any);
  }, []);

  return (
    <Box
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: { xs: 1, md: 2, sm: 2 },
          justifyContent: "space-around",
          maxWidth: 900,
          width: "100%",
          borderRadius: 1,
        }}
        bgcolor={"primary.main"}
        spacing={4}
        direction={{ xs: "column", sm: "row", md: "row" }}
      >
        <Stack direction={"column"} alignItems={"center"}>
          <Button
            onClick={() => {
              navigate("/");
            }}
            sx={{ width: "max-content", mb: 1, alignSelf: "flex-start" }}
            variant="text"
          >
            <ArrowBack fontSize="small" /> Back
          </Button>
          <Avatar
            sx={{ width: 150, height: 150, borderRadius: "8px" }}
            variant="square"
            src={`${serverUrl}/api/Files/image/${id}` || StudentAvatar}
          />
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          flex={1}
        >
          <Stack
            spacing={4}
            direction={"row"}
            //alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack>
              <Typography fontSize={"16px"}>{fullName}</Typography>
              <Typography fontSize={"12px"} color="text.secondary">
                {`${areaName}, ${city}-${areaCode}`}
              </Typography>
            </Stack>
            <Button
              disableRipple
              sx={{ height: "max-content", mt: 1, minWidth: "max-content" }}
              variant="outlined"
              startIcon={<Edit />}
              onClick={() => navigate("/update-profile")}
            >
              Edit Profile
            </Button>
          </Stack>
          <Divider sx={{ bgcolor: "#ffffff80", width: "100%", mt: 2 }} />
          <Stack
            sx={{
              flex: "1 0 auto",
              display: "flex",
              flexDirection: "column",
              padding: 0,
              paddingTop: 2,
            }}
          >
            <CustomTabs
              tabs={tabs}
              value={activeTab}
              onChange={handleTabChange}
            />
            <Divider sx={{ bgcolor: "#ffffff80", width: "100%", mb: 2 }} />
            {activeTab === "about" && (
              <>
                {/* <General data={user}/> */}
                <Typography fontWeight={700} color="text.secondary"><span style={{ fontWeight: 500 }}>Name:</span> {fullName}</Typography>
                <Typography fontWeight={700} color="text.secondary"><span style={{ fontWeight: 500 }}>Gender:</span>  {gender}</Typography>
                <Typography fontWeight={700} color="text.secondary">
                  <span style={{ fontWeight: 500 }}> Class:</span>  {studentGrade}
                </Typography>
                <Typography fontWeight={700} color="text.secondary"><span style={{ fontWeight: 500 }}>Board:</span>  CBSE</Typography>
                <Typography fontWeight={700} color="text.secondary">
                  <span style={{ fontWeight: 500 }}>Address:</span>  {`${areaName}, ${city}, ${state}, ${country}`}
                </Typography>
                <Typography fontWeight={700} color="text.secondary"><span style={{ fontWeight: 500 }}>Email:</span>  {email}</Typography>
                <Typography fontWeight={700} color="text.secondary">
                  <span style={{ fontWeight: 500 }}> Phone No.:</span>  {phoneNumber}
                </Typography>
              </>
            )}
            {activeTab === "bookings" && (
              <Box width={"100%"} flex={1}>
                <Bookings bookings={bookings} />
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

const bookings = [
  {
    name: "Tutor 1",
    address: "Exhibition Road, Patna - 800001",
    subject: "Maths",
    class: "Class 6",
    bookedOn: "04/04/2024",
    status: "pending",
    bookingId: "123456",
  },
  {
    name: "Tutor 2",
    address: "Exhibition Road, Patna - 800001",
    subject: "Maths",
    class: "Class 6",
    bookedOn: "04/04/2024",
    status: "pending",
    bookingId: "1234567",
  },
  {
    name: "Tutor 3",
    address: "Exhibition Road, Patna - 800001",
    subject: "Maths",
    class: "Class 6",
    bookedOn: "04/04/2024",
    status: "pending",
    bookingId: "1234568",
  },
  {
    name: "Tutor 4",
    address: "Exhibition Road, Patna - 800001",
    subject: "Maths",
    class: "Class 6",
    bookedOn: "04/04/2024",
    status: "pending",
    bookingId: "1234569",
  },
];
