import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  styled,
} from "@mui/material";
import { ArrowBack, CloudUpload, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../../components/CustomInput";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClasses } from "../../../../reducers/searchSlice";
import { getAreas, updateProfile } from "../../../../reducers/homeSlice";
import SubjectsMapping from "../../SubjectsMapping";
import CustomAutocomplete from "../../../../components/CustomAutoComplete";
import { fetchUserDetails } from "../../../../reducers/authSlice";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function General({ data }: { data: any }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useSelector(getClasses);
  const areas = useSelector(getAreas);
  const [state, setState] = useState<any>(data.user);

  const captureData = (type: string, value: string) => {
    setState((prev: any) => {
      return { ...prev, [type]: value };
    });
  };

  const captureArea = (areaCode: string, areaName: string) => {
    setState((prev: any) => {
      return { ...prev, areaCode: `${areaCode}`, areaName };
    });
  };

  const updateUserProfile = () => {
    dispatch(
      updateProfile({
        userId: state.id,
        data: { ...state },
        callback: () => dispatch(fetchUserDetails({ id: data.user.id }) as any),
      }) as any
    );
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Grid
        container
        spacing={{ xs: 1.5, sm: 1.5, md: 1.5 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        bgcolor={"primary.main"}
        pl={1}
        pr={2}
        pt={1}
        pb={2}
        borderRadius={1}
      >
        <Grid item xs={4} sm={8} md={4}>
          <CustomInput
            sx={{ width: "100%" }}
            fullWidth
            inputLabel="Full Name"
            required
            value={state?.fullName || ""}
            onChange={(e) => captureData("fullName", e.target.value)}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={4}>
          <CustomInput
            sx={{ width: "100%" }}
            fullWidth
            inputLabel="Username"
            required
            value={state?.userName || ""}
            onChange={(e) => captureData("userName", e.target.value)}
          />
        </Grid>
        {data.user.roleType === "Teacher" && (
          <Grid item xs={2} sm={4} md={6}>
            <CustomInput
              sx={{ width: "100%" }}
              fullWidth
              inputLabel="Qualifications"
              required
              value={state?.teacherQualification || ""}
              onChange={(e) =>
                captureData("teacherQualification", e.target.value)
              }
            />
          </Grid>
        )}

        {data.user.roleType === "Student" && (
          <>
            <Grid item xs={4 / 3} sm={8 / 3} md={4}>
              <CustomInput
                sx={{ width: "100%" }}
                fullWidth
                inputLabel="Board"
                required
                select
                defaultValue={"CBSE"}
                //onChange={(e) => captureData("username", e.target.value)}
              >
                <MenuItem value={"CBSE"}>CBSE</MenuItem>
                <MenuItem value={"ICSE"}>ICSE</MenuItem>
                <MenuItem value={"BSEB"}>BSEB</MenuItem>
              </CustomInput>
            </Grid>
            <Grid item xs={4 / 3} sm={8 / 3} md={4}>
              <CustomInput
                inputLabel="Class"
                required
                select
                fullWidth
                defaultValue={"Class 1-5"}
                value={state.studentGrade || ""}
                onChange={(event: any) => {
                  captureData("studentGrade", event.target.value);
                }}
              >
                {classes.map((_) => (
                  <MenuItem value={_}>Class {_}</MenuItem>
                ))}
              </CustomInput>
            </Grid>
          </>
        )}
        <Grid item xs={2} sm={4} md={6}>
          <CustomAutocomplete
            options={areas}
            getOptionLabel={(option) =>
              option?.areaName + " " + "(" + option?.areaPinCode + ")"
            }
            onChange={(e, value) =>
              captureArea(value?.areaPinCode, value?.areaName)
            }
            label="Select Area"
            variant="outlined"
            inputLabel="Area"
            value={
              areas.find(
                (_) =>
                  `${_.areaPinCode}` === state.areaCode &&
                  _.areaName === state.areaName
              ) || ""
            }
            required
          />
        </Grid>
        <Grid item xs={2} sm={4} md={6}>
          <CustomInput
            inputLabel="Postcode"
            required
            value={state?.pinCode || ""}
            placeholder="Enter postcode"
            onChange={(e) => captureData("pinCode", e.target.value)}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={6}>
          <CustomInput
            inputLabel="Address Line 1"
            required
            value={state?.addressLine1 || ""}
            placeholder="Enter Address Line 1"
            onChange={(e) => captureData("addressLine1", e.target.value)}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={6}>
          <CustomInput
            inputLabel="Address Line 2"
            required={false}
            value={state?.addressLine2 || ""}
            placeholder="Enter Address Line 2"
            onChange={(e) => captureData("addressLine2", e.target.value)}
          />
        </Grid>
        <Grid item xs={4 / 3} sm={8 / 3} md={4}>
          <CustomInput
            inputLabel="Country"
            defaultValue={"India"}
            disabled
            required
          />
        </Grid>
        <Grid item xs={4 / 3} sm={8 / 3} md={4}>
          <CustomInput inputLabel="State" defaultValue={"Bihar"} disabled />
        </Grid>
        <Grid item xs={4 / 3} sm={8 / 3} md={4}>
          <CustomInput inputLabel="City" defaultValue={"Patna"} disabled />
        </Grid>

        {/* {data.user.roleType === "Teacher" &&<Grid item xs={4 / 3} sm={8 / 3} md={4}>
          <Typography>Upload Your Resume</Typography>
          <Button
            sx={{ mt: 1 }}
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<CloudUpload />}
          >
            Upload file
            <VisuallyHiddenInput type="file" />
          </Button>
        </Grid>} */}
        <Grid item xs={4} sm={8} md={12}>
          <Button
            sx={{ mt: 1 }}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={updateUserProfile}
          >
            Update Profile
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
