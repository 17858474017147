import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button, Chip, Stack } from "@mui/material";
import { useState } from "react";
import CustomAutocomplete from "../../../../components/CustomAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { getAreas } from "../../../../reducers/homeSlice";
import {
  addArea,
  deleteArea,
  getTeacherArea,
  getTeacherDetails,
} from "../../../../reducers/teacherSlice";
import { getUserDetails } from "../../../../reducers/authSlice";

export default function Area() {
  const theme = useTheme();
  const user = useSelector(getUserDetails);
  const dispatch = useDispatch();
  const areas1 = useSelector(getAreas);
  const selectedAreas = useSelector(getTeacherArea);
  const [areas, setAreas] = useState<any>([]);
  //const [selectedAreas, setSelectedAreas] = useState<any>([]);

  const addNewArea = () => {
    const data = areas.map((_: any) => {
      return {
        teachingAreaCode: _.areaCode,
        teachingAreaName: _.areaName,
        teachingAreaPinCode: _.areaPinCode.toString(),
        teachingAreaCity: _.city,
        teachingAreaState: _.state,
        teachingAreaCountry: _.country,
        teachingAreaCountryCode: _.countryCode,
        isDeleted: "N",
        createDate: "2024-07-14T08:00:40.643Z",
        updateDate: "2024-07-14T08:00:40.643Z",
        createUserId: user.user.id,
        updateUserId: user.user.id,
      };
    });
    dispatch(
      addArea({
        id: user.user.id,
        data,
        callback: () =>{
          dispatch(getTeacherDetails({ teacherId: user.user.id }) as any);
          setAreas([])
        }
          

      }) as any
    );
  };

  return (
    <Stack direction={"column"} spacing={1}>
      <Typography>List of areas you will teach in:</Typography>
      <Stack
        useFlexGap
        flexWrap={"wrap"}
        direction={"row"}
        width={"100%"}
        spacing={"10px"}
      >
        {selectedAreas.map(
          (_) =>
            _.isDeleted === "N" && (
              <Chip
                sx={{
                  background: "#177694",
                  borderRadius: 1,
                  color: "#fff",
                  fontWeight: 600,
                  "& .MuiChip-deleteIcon": { color: "#fff" },
                }}
                onDelete={() => {
                  dispatch(
                    deleteArea({
                      id: _.id,
                      data: {
                        teachingAreaCode: _.teachingAreaCode,
                        teachingAreaName: _.teachingAreaName,
                        teachingAreaPinCode: _.teachingAreaPinCode,
                        teachingAreaCity: _.teachingAreaCity,
                        teachingAreaState: _.teachingAreaState,
                        teachingAreaCountry: _.teachingAreaCountry,
                        teachingAreaCountryCode: _.teachingAreaCountryCode,
                        isDeleted: "Y",
                        createDate: "2024-07-14T07:41:37.484Z",
                        updateDate: "2024-07-14T07:41:37.484Z",
                        createUserId: "TFS",
                        updateUserId: "TFS",
                      },
                      callback: () => {
                        dispatch(
                          getTeacherDetails({ teacherId: user.user.id }) as any
                        );
                      },
                    }) as any
                  );
                }}
                label={
                  _.teachingAreaName + " " + "(" + _?.teachingAreaPinCode + ")"
                }
              />
            )
        )}
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <CustomAutocomplete
          options={areas1}
          getOptionLabel={(option) =>
            option?.areaName + " " + "(" + option?.areaPinCode + ")"
          }
          getOptionDisabled={(option) =>
            option.areaCode === selectedAreas.find((_)=>_.teachingAreaCode===option.areaCode)?.teachingAreaCode
          }
          onChange={(event: any, newValue: any) => {
            setAreas((prev: any) => {
              return [newValue];
            });
          }}
          value={areas[0]||{}}
          label="Select Area"
          variant="outlined"
          inputLabel="Select new area"
          required
        />
        <Button
          variant="contained"
          sx={{ height: "fit-content", alignSelf: "end" }}
          size="small"
          onClick={addNewArea}
        >
          Add
        </Button>
      </Stack>
    </Stack>
  );
}
