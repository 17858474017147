import { configureStore } from '@reduxjs/toolkit'
import authSlice from './reducers/authSlice'
import searchSlice from './reducers/searchSlice'
import homeSlice from './reducers/homeSlice'
import snackbarSlice from './reducers/snackbarSlice'
import teacherSlice from './reducers/teacherSlice'
import bookingSlice from './reducers/bookingSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    search: searchSlice,
    home: homeSlice,
    snackbar: snackbarSlice,
    teacher: teacherSlice,
    booking: bookingSlice,
  },
})
