import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Link, MenuItem, Stack } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import { forgotPassword, login } from "../../reducers/authSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { serverUrl } from "../../common/types";

export default function ConfirmEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";
  const email = searchParams.get("email") || "";
  const [loading, setLoading] = useState(true);

  const handleConfirm = async () => {
    const res = await fetch(
      `${serverUrl}/api/Authentication/ConfirmEmail?token=${token}&email=${email}`
    );
    const data = await res.json();
    if (data.isSuccess) setLoading(false);
  };

  useEffect(() => {
    handleConfirm();
  }, []);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          justifyContent: "center",
          alignItems: "center",
          maxWidth: 500,
          width: "100%",
          //boxShadow: 2,
          borderRadius: 1,
          //border: "1px solid #3d4f58"
        }}
        spacing={2}
        bgcolor={"primary.main"}
      >
        {loading ? (
          <Typography>Confirming email, please wait...</Typography>
        ) : (
          <>
            <Typography>Email confirmed.</Typography>
            <Typography marginTop={2} color="text.disabled" fontSize={"14px"}>
              Login to continue.{" "}
              <Button onClick={() => navigate("/login")}>Login</Button>
            </Typography>
          </>
        )}
      </Stack>
    </Box>
  );
}
