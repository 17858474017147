// authSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { serverUrl } from "../common/types";

export const tutors = [
  {
    name: "Tutor 1",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 4,
  },
  {
    name: "Tutor 2",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 5,
  },
  {
    name: "Tutor 3",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 3.5,
  },
  {
    name: "Tutor 4",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 4,
  },
  {
    name: "Tutor 5",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 4.5,
  },
  {
    name: "Tutor 6",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 5,
  },
  {
    name: "Tutor 7",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 4,
  },
];

export const subjects = [
  { name: "Maths", id: 1 },
  { name: "Physics", id: 2 },
  { name: "Chemistry", id: 3 },
  { name: "Biology", id: 4 },
  { name: "English", id: 5 },
  { name: "Hindi", id: 6 },
  { name: "Science", id: 7 },
  { name: "Social Science", id: 8 },
  { name: "Sanskrit", id: 9 },
];

export const classes = ["1 to 5", "6 to 8", "9", "10", "11", "12"];

export interface Tutor {
  name: string;
  experience: string;
  locality: string;
  rating: number;
}

export interface SearchState {
  tutors: any[];
  classes: string[];
  selectedSubject: string;
  selectedClass: string;
  areaPinCode: string | number;
  areaCode: string | number;
  selectedBoard: string;
  areaName: string;
  subjectId: string;
  subjectName: string;
  searchLoading: boolean;
}

export const searchTutors = createAsyncThunk(
  "search/searchTutors",
  async (
    {
      areaCode, areaPinCode, subject, grade, board
    }: {
      areaPinCode: string;
      areaCode: string;
      subject: string;
      grade: string;
      board: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const url = `${serverUrl}/api/Admin/user/SearchTeacher`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({areaCode, areaPinCode, subject, grade, boardCode: board}),
      });
      const response = await res.json();
      return response;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

const initialState: SearchState = {
  tutors: [],
  classes: classes,
  selectedClass: "",
  selectedSubject: "",
  areaCode: "",
  areaPinCode: "",
  selectedBoard: "",
  areaName: "",
  subjectId: "",
  subjectName: "",
  searchLoading: false,
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchValues(state, { payload }) {      
      state.selectedClass = payload.class;
      state.selectedSubject = payload.subject;
      state.areaPinCode = payload.pincode;
      state.areaCode = payload.areaCode;
      state.selectedBoard = payload.board;
      state.areaName = payload.areaName;
      state.subjectId = payload.subjectId;
      state.subjectName = payload.subjectName;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchTutors.pending, (state, { payload }) => {
        return { ...state, searchLoading: true };
      })
      .addCase(searchTutors.fulfilled, (state, { payload }) => {
        return { ...state, tutors: payload?.response, searchLoading: false };
      })
      .addCase(searchTutors.rejected, (state, { payload }) => {
        return { ...state, searchLoading: false };
      });
  },
});

export const getTutors = (state: { search: SearchState }) =>
  state.search.tutors;
export const getClasses = (state: { search: SearchState }) =>
  state.search.classes;
export const getSelectedSubject = (state: { search: SearchState }) =>
  state.search.selectedSubject;
export const getSelectedClass = (state: { search: SearchState }) =>
  state.search.selectedClass;
export const getAreaCode = (state: { search: SearchState }) =>
  state.search.areaCode;
export const getSelectedBoard = (state: { search: SearchState }) =>
  state.search.selectedBoard;
export const getAreaPinCode = (state: { search: SearchState }) =>
  state.search.areaPinCode;
export const getAreaName = (state: { search: SearchState }) =>
  state.search.areaName;
export const getSubjectName = (state: { search: SearchState }) =>
  state.search.subjectName;
export const getSubjectId = (state: { search: SearchState }) =>
  state.search.subjectId;
export const getSearchLoading = (state: { search: SearchState }) =>
  state.search.searchLoading;

export const { setSearchValues } = searchSlice.actions;
export default searchSlice.reducer;
