import React from 'react';
import { Tabs, Tab, TabProps } from '@mui/material';

interface TabItem {
  label: string;
  value: string;
}

interface TabComponentProps {
  tabs: TabItem[];
  value: string;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

const CustomTabs: React.FC<TabComponentProps> = ({ tabs, value, onChange }) => {
  return (
    <Tabs textColor="inherit"
		 value={value} sx={{"& .MuiTabs-indicator": {bgcolor: "black"}}} onChange={onChange} aria-label="tabs">
      {tabs.map((tab) => (
        <Tab sx={{"& .Mui-selected": {color: "white"}}} key={tab.value} label={tab.label} value={tab.value} />
      ))}
    </Tabs>
  );
};

export default CustomTabs;
