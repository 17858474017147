import React from "react";

const ContactUs = () => {
  return (
    <div style={{ backgroundColor: '#ffffff', padding: '32pt' }}>
      <h1>Contact Us</h1>
      <p>
        We’re here to help you find the perfect tutor or answer any questions
        you might have. Whether you're a student, parent, or teacher, feel free
        to reach out to us using the information below.
      </p>

      <h3>Get in Touch</h3>
      <p>
        <strong>Email:</strong><br />
        For general inquiries or support:{" "}
        <a href="mailto:info.tutorforsure@gmail.com">info.tutorforsure@gmail.com</a>
      </p>

      <p>
        <strong>Phone:</strong><br />
        Have a quick question? Call or WhatsApp us at:{" "}
        <a href="tel:+919241550824">+91 9241550824</a><br />
        Available Monday to Sunday, 8 AM to 7 PM.
      </p>

      <p>
        <strong>Address:</strong><br />
        Exhibition Road<br />
        Patna, Bihar, India
      </p>

      <h3>Connect with Us Online</h3>
      <p>
        Follow us on social media to stay updated on the latest news and
        opportunities:<br />
        <strong>Facebook:</strong>{" "}
        <a href="https://www.facebook.com/share/zJ6wpuoCFRSEyh6y/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/share/zJ6wpuoCFRSEyh6y/?mibextid=LQQJ4d
        </a>
      </p>
      <p>
        <strong>Instagram:</strong>{" "}
        <a href="https://www.instagram.com/tutorforsure?igsh=Z3Vkcmc4OGMyam5o&utm_source=qr" target="_blank" rel="noopener noreferrer">
          https://www.instagram.com/tutorforsure
        </a>
      </p>
      <p>
        <strong>LinkedIn:</strong>{" "}
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">linkedin.com</a>
      </p>

      <p>We look forward to assisting you!</p>
    </div>
  );
};

export default ContactUs;