import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Link, MenuItem, Stack } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import { forgotPassword, login } from "../../reducers/authSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const prev = searchParams.get("prev") || "";
  const [data, setData] = useState({
    email: "",
  });

  const captureData = (type: string, value: string) => {
    setData((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const handleForgotPassword = () => {
    dispatch(
      forgotPassword({
        email: data.email,
        callBack: () => {
          navigate("/login");
        },
      }) as any
    );
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          justifyContent: "space-around",
          maxWidth: 500,
          width: "100%",
          //boxShadow: 2,
          borderRadius: 1,
          //border: "1px solid #3d4f58"
        }}
        spacing={2}
        bgcolor={"primary.main"}
      >
        <Stack>
          <Typography variant="h6">Forgot Password?</Typography>
          <Typography color="text.secondary" fontSize={"14px"}>
            Enter email to continue.
          </Typography>
        </Stack>
        <CustomInput
          inputLabel="Email"
          required
          placeholder="Enter email"
          onChange={(e) => captureData("email", e.target.value)}
        />
        <Button
          sx={{ mt: 1 }}
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleForgotPassword}
        >
          Submit
        </Button>
        <Typography marginTop={2} color="text.disabled" fontSize={"14px"}>
          Go back to login?{" "}
          <Button onClick={() => navigate("/login")}>Login</Button>
        </Typography>
      </Stack>
    </Box>
  );
}
