// authSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RegisterationData, serverUrl } from "../common/types";

interface AuthState {
  user: any;
  accessToken: string | null;
  refreshToken: string | null;
  error: string | null;
  roles: string[];
  isAuthenticated: boolean;
  name: string;
  loading: boolean;
}

export const login = createAsyncThunk(
  "auth/login",
  async (
    {
      username,
      password,
      callBack,
    }: { username: string; password: string; callBack?: any },
    { rejectWithValue }
  ) => {
    // Simulated authentication logic
    //const users = readUserFromStorage();
    try {
      const url = `${serverUrl}/api/Authentication/login`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await res.json();
      if (!data.isSuccess) return rejectWithValue(data.message);
      if (data.isSuccess && callBack) callBack();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (
    { userData, callBack }: { userData: RegisterationData; callBack: any },
    { rejectWithValue }
  ) => {
    try {
      const url = `${serverUrl}/api/Authentication`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });
      const data = await res.json();
      if (data.isSuccess) callBack();
      if (!data.isSuccess) return rejectWithValue(data.message);
      return data;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (
    { email, callBack }: { email: string; callBack?: any },
    { rejectWithValue }
  ) => {
    try {
      const url = `${serverUrl}/api/Authentication/forgot-password`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await res.json();
      if (data.isSuccess) callBack();
      if (!data.isSuccess) return rejectWithValue(data.message);
      return data;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (
    {
      email,
      password,
      confirmPassword,
      token,
      callBack,
    }: {
      email: string;
      password: string;
      confirmPassword: string;
      token: string;
      callBack?: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const url = `${serverUrl}/api/Authentication/ResetPassword`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password, confirmPassword, token }),
      });
      const data = await res.json();
      if (data.isSuccess) callBack();
      if (!data.isSuccess) return rejectWithValue(data.message);
      return data;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

export const fetchUserDetails = createAsyncThunk(
  "auth/fetchUserDetails",
  async (
    {
      id,
      callback,
    }: {
      id: string;
      callback?: any;
    },
    { rejectWithValue }
  ) => {
    const res = await fetch(
      `${serverUrl}/api/Admin/user/GetTeacherDetails/${id}`
    );
    const data = await res.json();
    if (callback) callback();
    return data;
  }
);

const initialState: AuthState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") as any)
    : null,
  accessToken: localStorage.getItem("accessToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  error: null,
  roles: JSON.parse(localStorage.getItem("user") as any)
    ? [JSON.parse(localStorage.getItem("user") as any).roleType]
    : [],
  isAuthenticated: Boolean(localStorage.getItem("isAuthenticated")) || false,
  name: JSON.parse(localStorage.getItem("user") as any)?.fullName || "",
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.user = null;
      state.error = null;
      state.name = "";
      state.roles = [];
      state.isAuthenticated = false;
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("isAuthenticated");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.name = payload.response?.userDetails.fullName;
      state.roles = [payload.response?.userDetails.roleType];
      state.isAuthenticated = payload.isSuccess;
      state.loading = false;
      state.user = payload.response?.userDetails;
      state.accessToken = payload.response?.accessToken.token;
      state.refreshToken = payload.response?.refreshToken.token;
      localStorage.setItem(
        "user",
        payload.response?.userDetails
          ? JSON.stringify(payload.response?.userDetails)
          : "{}"
      );
      localStorage.setItem("accessToken", payload.response?.accessToken.token);
      localStorage.setItem(
        "refreshToken",
        payload.response?.refreshToken.token
      );
      localStorage.setItem("isAuthenticated", payload.isSuccess);
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      state.user = null;
      state.name = "";
      state.roles = [];
      state.isAuthenticated = false;
      state.error = payload as string;
      state.loading = false;
    });
    builder.addCase(login.pending, (state, { payload }) => {
      state.user = null;
      state.name = "";
      state.roles = [];
      state.isAuthenticated = false;
      state.loading = true;
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, { payload }) => {
      state.user = payload.response?.userDetails;
      state.error = null;
    });
    builder.addCase(register.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(register.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(register.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
});

export const getUserDetails = (state: { auth: AuthState }) => state.auth;
export const getLoading = (state: { auth: AuthState }) => state.auth.loading;
export const { logout } = authSlice.actions;
export default authSlice.reducer;
