import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IconButton, Stack } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  getAllBookingDetails,
  getAllBookings,
  updateBooking,
} from "../../../reducers/bookingSlice";
import { useDispatch } from "react-redux";

export const rows = [
  {
    id: 1,
    bookedBy: "Conny Terzza",
    tutorName: "Conny Tearny",
    subject: "Conny",
    class: "Toho University",
    date: "5/5/2023",
    status: "MA",
  },
  {
    id: 2,
    bookedBy: "Ali Larmett",
    tutorName: "Ali Pamplin",
    subject: "Ali",
    class: "GC University",
    date: "6/2/2023",
    status: "ET",
  },
  {
    id: 3,
    bookedBy: "Shirline Steckings",
    tutorName: "Shirline Evetts",
    subject: "Shirline",
    class: "Bar-Ilan University",
    date: "8/5/2023",
    status: "AU",
  },
  {
    id: 4,
    bookedBy: "Celie Lamden",
    tutorName: "Celie Livings",
    subject: "Celie",
    class: "Instituts Supérieurs des Etudes Technologiques",
    date: "1/14/2024",
    status: "ZA",
  },
  {
    id: 5,
    bookedBy: "Cyndia O'Bradane",
    tutorName: "Cyndia Crosfeld",
    subject: "Cyndia",
    class: "Vancouver Community College",
    date: "1/1/2024",
    status: "CG",
  },
  {
    id: 6,
    bookedBy: "Lauretta Emanulsson",
    tutorName: "Lauretta Devigne",
    subject: "Lauretta",
    class: "University of Osijek",
    date: "5/2/2023",
    status: "US",
  },
  {
    id: 7,
    bookedBy: "Hortensia Clark",
    tutorName: "Hortensia Meneghelli",
    subject: "Hortensia",
    class: "University of Ruhuna",
    date: "2/26/2024",
    status: "PG",
  },
  {
    id: 8,
    bookedBy: "Elbertine Northcote",
    tutorName: "Elbertine Kaas",
    subject: "Elbertine",
    class: "Ilam University of Medical Sciences",
    date: "11/14/2023",
    status: "DZ",
  },
  {
    id: 9,
    bookedBy: "Charmion Ruddiman",
    tutorName: "Charmion Nansom",
    subject: "Charmion",
    class: "Islamic Azad University, Shirvan",
    date: "8/5/2023",
    status: "BR",
  },
  {
    id: 10,
    bookedBy: "Sherye Marlon",
    tutorName: "Sherye Slot",
    subject: "Sherye",
    class: "Jilin University of Technology",
    date: "8/6/2023",
    status: "GB",
  },
  {
    id: 11,
    bookedBy: "Lynsey Worsnap",
    tutorName: "Lynsey Scola",
    subject: "Lynsey",
    class: "Palm Beach Atlantic University",
    date: "9/20/2023",
    status: "CM",
  },
  {
    id: 12,
    bookedBy: "Becki Wreath",
    tutorName: "Becki Branchet",
    subject: "Becki",
    class: "University of Idaho",
    date: "3/23/2024",
    status: "PH",
  },
  {
    id: 13,
    bookedBy: "Wilow Ludewig",
    tutorName: "Wilow Bourley",
    subject: "Wilow",
    class: "Srinakharinwirot University",
    date: "5/23/2023",
    status: "FI",
  },
  {
    id: 14,
    bookedBy: "George Whiscard",
    tutorName: "George Dutson",
    subject: "George",
    class: "Universitas Bakrie",
    date: "10/10/2023",
    status: "US",
  },
  {
    id: 15,
    bookedBy: "Irena Lief",
    tutorName: "Irena Bridger",
    subject: "Irena",
    class: "University of the South Pacific Centre",
    date: "7/24/2023",
    status: "CN",
  },
  {
    id: 16,
    bookedBy: "Brett Wield",
    tutorName: "Brett Billett",
    subject: "Brett",
    class: "Universidad San Juan de la Cruz",
    date: "10/22/2023",
    status: "AU",
  },
  {
    id: 17,
    bookedBy: "Venus Minor",
    tutorName: "Venus Luno",
    subject: "Venus",
    class: "University of Oulu",
    date: "2/26/2024",
    status: "CO",
  },
  {
    id: 18,
    bookedBy: "Alison Skrine",
    tutorName: "Alison Metschke",
    subject: "Alison",
    class: "Universitas Madura",
    date: "3/10/2024",
    status: "CD",
  },
  {
    id: 19,
    bookedBy: "Clerissa Whifen",
    tutorName: "Clerissa Husby",
    subject: "Clerissa",
    class: "Pawel Wlodkowic University College in Plock",
    date: "9/18/2023",
    status: "BR",
  },
  {
    id: 20,
    bookedBy: "Alene Creedland",
    tutorName: "Alene Penkethman",
    subject: "Alene",
    class: "University of Pittsburgh at Bradford",
    date: "2/7/2024",
    status: "CO",
  },
  {
    id: 21,
    bookedBy: "Linnie Creeber",
    tutorName: "Linnie Gove",
    subject: "Linnie",
    class: "Arkansas State University, Newport",
    date: "8/2/2023",
    status: "US",
  },
  {
    id: 22,
    bookedBy: "Layney Wilkie",
    tutorName: "Layney Liccardo",
    subject: "Layney",
    class: "Al-Jabal Al-Gharbi University",
    date: "1/27/2024",
    status: "AF",
  },
  {
    id: 23,
    bookedBy: "Shawna Minget",
    tutorName: "Shawna Jager",
    subject: "Shawna",
    class: "Universidade Lusiada",
    date: "12/19/2023",
    status: "GR",
  },
  {
    id: 24,
    bookedBy: "Abigale Dreossi",
    tutorName: "Abigale Bertwistle",
    subject: "Abigale",
    class: "Hellenic Army Academy",
    date: "12/7/2023",
    status: "US",
  },
  {
    id: 25,
    bookedBy: "Mirabella Diben",
    tutorName: "Mirabella Royson",
    subject: "Mirabella",
    class: "Hiroshima Kokusai Gakuin University",
    date: "6/30/2023",
    status: "KZ",
  },
];

const columns: GridColDef<(typeof rows)[number]>[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "studentFullName",
    headerName: "Student name",
    width: 200,
  },
  {
    field: "requestedAreaName",
    headerName: "Address",
    width: 200,
  },
  {
    field: "teacherFullName",
    headerName: "Tutor name",
    width: 200,
  },
  {
    field: "requestedSubjectName",
    headerName: "Subject",
    width: 150,
  },
  {
    field: "requestedStudentGrade",
    headerName: "Class",
    width: 100,
  },
  {
    field: "createDate",
    headerName: "Booked On",
    width: 150,
  },
  {
    field: "bookingStatus",
    headerName: "Status",
    width: 100,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 150,
    headerAlign: "center",
    renderCell: (params: any) => {
      return (
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <IconButton sx={{ color: "green" }}>
            <Check />
          </IconButton>
          <IconButton sx={{ color: "red" }}>
            <Close />
          </IconButton>
        </Stack>
      );
    },
  },
];

const data = rows.map((_) => ({
  ..._,
  subject: "Maths",
  status: "Pending",
  class: "1-5",
  address: "Exhibition Road, 800001",
}));

export default function BookingsTable({ searchText }: { searchText: string }) {
  const dispatch = useDispatch();
  const data = useSelector(getAllBookingDetails);
  const columns: any[] = [
    ...Object.keys(data[0] || {}).map((_) => {
      return { field: _, headerName: _, width: 90 };
    }),
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      headerAlign: "center",
      renderCell: (params: any) => {
        return (
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <IconButton
              onClick={() => confirmBooking(params.row)}
              disabled={params.row.bookingStatus === "Confirmed"}
              sx={{ color: "green" }}
            >
              <Check />
            </IconButton>
            <IconButton
              onClick={() => cancelBooking(params.row)}
              disabled={params.row.isDeleted === "Y"}
              sx={{ color: "red" }}
            >
              <Close />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const confirmBooking = (data: any) => {
    dispatch(
      updateBooking({
        id: data.id,
        data: {
          ...data,
          bookingStatus: "Confirmed",
        },
        callback: () => {
          dispatch(getAllBookings({}) as any);
        },
      }) as any
    );
  };

  const cancelBooking = (data: any) => {
    dispatch(
      updateBooking({
        id: data.id,
        data: {
          ...data,
          isDeleted: "Y",
        },
        callback: () => {
          dispatch(getAllBookings({}) as any);
        },
      }) as any
    );
  };

  return (
    <DataGrid
      sx={{
        color: "black",
        "-webkit-text-fill-color": "black",
        fontSize: "1rem",
        fontWeight: "normal",
        mt: 2,
        "& .MuiDataGrid-footerContainer": {
          height: "40px",
        },
      }}
      filterModel={{
        items: [{ field: "studentFullName", operator: "contains", value: searchText }],
      }}
      rows={data}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      autoHeight
      pageSizeOptions={[10]}
      disableRowSelectionOnClick
      disableColumnMenu
      rowHeight={35}
      columnHeaderHeight={40}
    />
  );
}
