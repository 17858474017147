import { RouterProvider } from "react-router-dom";
import { router } from "./routes/Routes";
import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./store";
import { theme } from "./theme";
import SnackbarNotification from "./components/SnackbarNotification";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SnackbarNotification />
        <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
      </Provider>
    </ThemeProvider>
  );
}
