import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop";
import { useSelector } from "react-redux";
import { Avatar, Backdrop, CircularProgress, Stack } from "@mui/material";
import { getLoading, getUserDetails, logout } from "../../reducers/authSlice";
import { useDispatch } from "react-redux";
import { AccountCircle, Login } from "@mui/icons-material";
import { useEffect } from "react";
import {
  fetchFeaturedTutors,
  getHomeLoading,
  getListOfValues,
} from "../../reducers/homeSlice";
import Footer from "../footer/Footer";
import Logo from "../../assets/Logo.jpg";
import { getSearchLoading } from "../../reducers/searchSlice";
import { getTeacherLoading } from "../../reducers/teacherSlice";
import { getBookingsLoading } from "../../reducers/bookingSlice";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];

export default function Dashboard(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { isAuthenticated, roles } = useSelector(getUserDetails);
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const homeLoading = useSelector(getHomeLoading);
  const searchLoading = useSelector(getSearchLoading);
  const teacherLoading = useSelector(getTeacherLoading);
  const bookingsLoading = useSelector(getBookingsLoading);
  useEffect(() => {
    if (isAuthenticated && location.pathname === "/login") {
      navigate("/");
    }
  }, [location]);

  useEffect(() => {
    dispatch(getListOfValues() as any);
    //dispatch(fetchFeaturedTutors() as any);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const goToProfile = () => {
    roles[0] === "Student"
      ? navigate("/student")
      : roles[0] === "Teacher"
      ? navigate("/tutor")
      : navigate("/admin");
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", height: "100%", display: "contents" }}
    >
      <Typography
        variant="h6"
        fontWeight={"normal"}
        color={"darkslategrey"}
        sx={{
          my: 2,
          // background: "linear-gradient(to right, #177694, #9c3)",
          // "-webkit-background-clip": "text",
          // "-webkit-text-fill-color": "transparent",
        }}
      >
        TutorForSure
      </Typography>
      <Divider />
      <Stack
        justifyContent={"space-between"}
        direction={"column"}
        height={"100%"}
      >
        <List sx={{}}>
          {navItems.map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <Button
                  variant="text"
                  color="primary"
                  key={item}
                  onClick={() => navigate(`/${item}`)}
                >
                  {item}
                </Button>
              </ListItemButton>
            </ListItem>
          ))}
          {isAuthenticated && (
            <ListItem key={"profile"} disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <Button variant="text" color="secondary" onClick={goToProfile}>
                  Profile
                </Button>
              </ListItemButton>
            </ListItem>
          )}
        </List>
        <Stack p={2} spacing={2}>
          {isAuthenticated ? (
            <Button variant="contained" color="primary" onClick={handleLogout}>
              Logout
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </Button>
              <Divider />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Sign Up
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: 10000 }}
        open={
          loading ||
          homeLoading ||
          searchLoading ||
          teacherLoading ||
          bookingsLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: "flex" }}>
        <ScrollToTop />
        <CssBaseline />
        <AppBar
          sx={{
            bgcolor: "primary.main",
            boxShadow: "none",
            borderImage: "linear-gradient(to right, #177694, #9c3) 30",
            borderWidth: "4px",
            borderStyle: "solid",
            borderBottom: "0",
            borderRight: "0",
            borderLeft: "0",
          }}
          component="nav"
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Stack
              alignItems={"center"}
              direction={"row"}
              spacing={1}
              display={"flex"}
              flexGrow={1}
              py={1}
            >
              <Avatar
                sx={{
                  width: { sm: 30, md: 50 },
                  height: { sm: 30, md: 50 },
                  borderRadius: "8px",
                  objectFit: "none",
                }}
                variant="square"
                src={Logo}
              />
              <Typography
                fontSize={{ sm: "18px", md: "24px" }}
                fontWeight={"normal"}
                sx={{
                  width: "max-content",
                  display: { sm: "block" },
                  color: "#212f3c",
                  ":hover": { cursor: "pointer" },
                  // background: "linear-gradient(to right, #177694, #9c3)",
                  // "-webkit-background-clip": "text",
                  // "-webkit-text-fill-color": "transparent",
                  fontFamily: "Bungee",
                }}
                onClick={() => navigate("/")}
              >
                TutorForSure
              </Typography>
            </Stack>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                {isAuthenticated ? (
                  <>
                    <IconButton
                      onClick={goToProfile}
                      size="medium"
                      sx={{
                        background: "linear-gradient(to right, #177694, #9c3)",
                      }}
                    >
                      <AccountCircle />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    onClick={() => {
                      navigate("/login");
                    }}
                    size="medium"
                    sx={{
                      background: "linear-gradient(to right, #177694, #9c3)",
                    }}
                  >
                    <Login />
                  </IconButton>
                  // <Button
                  //   variant="contained"
                  //   color="primary"
                  //   sx={{ ml: 3 }}
                  //   onClick={() => {
                  //     navigate("/login");
                  //   }}
                  // >
                  //   Login / Sign Up
                  // </Button>
                )}
              </Stack>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                {navItems.map((item) => (
                  <Button
                    variant="text"
                    color="primary"
                    sx={{ fontWeight: "bold" }}
                    key={item}
                    onClick={() => navigate(`/${item}`)}
                  >
                    {item}
                  </Button>
                ))}
                {isAuthenticated ? (
                  <>
                    <Button
                      variant="text"
                      color="primary"
                      sx={{ ml: 3, fontWeight: "bold" }}
                      onClick={goToProfile}
                    >
                      Profile
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ ml: 3, fontWeight: "bold" }}
                      onClick={handleLogout}
                    >
                      Logout
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: 3 }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Login / Sign Up
                  </Button>
                )}
              </Stack>
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                bgcolor: "primary.main",
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
        <Box
          width={"100%"}
          minHeight={"100vh"}
          component="main"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <Box padding={{ xs: 1.5, sm: 2, md: 3 }}>
            <Toolbar />
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
}
