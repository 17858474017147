// authSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { masterData, serverUrl } from "../common/types";

export const tutors = [
  {
    name: "Tutor 1",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 4,
  },
  {
    name: "Tutor 2",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 5,
  },
  {
    name: "Tutor 3",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 3.5,
  },
  {
    name: "Tutor 4",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 4,
  },
  {
    name: "Tutor 5",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 4.5,
  },
  {
    name: "Tutor 6",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 5,
  },
  {
    name: "Tutor 7",
    experience: "4 years",
    locality: "Exhibition Road, Patna - 800001",
    rating: 4,
  },
];

export const subjects = [
  { name: "Maths", id: 1 },
  { name: "Physics", id: 2 },
  { name: "Chemistry", id: 3 },
  { name: "Biology", id: 4 },
  { name: "English", id: 5 },
  { name: "Hindi", id: 6 },
  { name: "Science", id: 7 },
  { name: "Social Science", id: 8 },
  { name: "Sanskrit", id: 9 },
];

export const classes = [
  "Class 1-5",
  "Class 6",
  "Class 7",
  "Class 8",
  "Class 9",
  "Class 10",
  "Class 11",
  "Class 12",
];

export interface Tutor {
  name: string;
  experience: string;
  locality: string;
  rating: number;
}

export interface HomeState {
  subjects: any[];
  areas: any[];
  featuredTutors: any[];
  homeLoading: boolean;
}

export const getListOfValues = createAsyncThunk(
  "home/getListOfValues",
  async () => {
    const res = await fetch(
      `${serverUrl}/api/Admin/user/GetMasterTablesData`
    );
    const data = await res.json();
    return data;
  }
);

export const fetchFeaturedTutors = createAsyncThunk(
  "home/fetchFeaturedTutors",
  async ({ data }: { data: string }, { rejectWithValue }) => {
    try {
      const url = `${serverUrl}/api/Admin/user/SearchTeacher`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          areaPinCode: "string",
          areaCode: "string",
          subject: "string",
          grade: "string",
          boardCode: "CBSE",
        }),
      });
      const data = await res.json();
      return data;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "home/updateProfile",
  async (
    { userId, data, callback }: { userId: string; data: any; callback?: any },
    { rejectWithValue }
  ) => {
    try {
      const url = `${serverUrl}/api/Admin/user/UpdateUserDetails/${userId}`;
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data || {}),
      });
      const response = await res.json();
      if (callback) callback();
      return response;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

export const updateImage = createAsyncThunk(
  "home/updateImage",
  async (
    { userId, data, callback }: { userId: string; data: any; callback?: any },
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append("file", data);
      const url = `${serverUrl}/api/Files/upload?user=${userId}`;
      const res = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const response = await res.json();
      if (response.fileUrl && callback) callback();
      if (!response.fileUrl) return rejectWithValue("Failed to upload image");
      return {message: "Image uploaded successfully."};
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

const initialState: HomeState = {
  subjects: [],
  areas: [],
  featuredTutors: [],
  homeLoading: false,
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListOfValues.pending, (state, { payload }) => {
        return { ...state };
      })
      .addCase(getListOfValues.fulfilled, (state, { payload }) => {
        return {
          ...state,
          subjects: payload?.response?.subjectGradeMappings,
          areas: payload?.response?.locationMasterDetails,
          featuredTutors: payload?.response?.featuredTeachers,
        };
      })
      .addCase(getListOfValues.rejected, (state, { payload }) => {
        return { ...state };
      })
      .addCase(fetchFeaturedTutors.pending, (state, { payload }) => {
        return {
          ...state,
          homeLoading: true,
        };
      })
      .addCase(fetchFeaturedTutors.fulfilled, (state, { payload }) => {
        return {
          ...state,
          featuredTutors: payload?.response,
          homeLoading: false,
        };
      })
      .addCase(fetchFeaturedTutors.rejected, (state, { payload }) => {
        return { ...state, homeLoading: false, };
      })
      .addCase(updateProfile.pending, (state, { payload }) => {
        return { ...state, homeLoading: true };
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          homeLoading: false,
        };
      })
      .addCase(updateProfile.rejected, (state, { payload }) => {
        return { ...state, homeLoading: false };
      })
      .addCase(updateImage.pending, (state, { payload }) => {
        return { ...state, homeLoading: true };
      })
      .addCase(updateImage.fulfilled, (state, { payload }) => {
        return {
          ...state,
          homeLoading: false,
        };
      })
      .addCase(updateImage.rejected, (state, { payload }) => {
        return { ...state, homeLoading: false };
      })
  },
});

export const getSubjects = (state: { home: HomeState }) => state.home.subjects;
export const getAreas = (state: { home: HomeState }) => state.home.areas;
export const getFeaturedTutors = (state: { home: HomeState }) =>
  state.home.featuredTutors;
export const getHomeLoading = (state: { home: HomeState }) =>
  state.home.homeLoading;

export default homeSlice.reducer;
