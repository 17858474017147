import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Link, MenuItem, Stack } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import { login } from "../../reducers/authSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const prev = searchParams.get("prev") || "";
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const captureData = (type: string, value: string) => {
    setData((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const loginUser = () => {
    dispatch(
      login({
        username: data.email,
        password: data.password,
        callBack: () => {
          if(prev==="book-tutor") navigate(-1)
          else navigate("/");
        },
      }) as any
    );
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          justifyContent: "space-around",
          maxWidth: 500,
          width: "100%",
          //boxShadow: 2,
          borderRadius: 1,
          //border: "1px solid #3d4f58"
        }}
        spacing={2}
        bgcolor={"primary.main"}
      >
        <Stack>
          <Typography variant="h6">Welcome!</Typography>
          <Typography color="text.secondary" fontSize={"14px"}>
            Sign in to continue.
          </Typography>
        </Stack>
        {/* <CustomInput
          inputLabel="Select Role"
          required
          select
          defaultValue={"student"}
        >
          <MenuItem value={"student"}>Student</MenuItem>
          <MenuItem value={"teacher"}>Tutor</MenuItem>
        </CustomInput> */}
        <CustomInput
          inputLabel="Email"
          required
          placeholder="Enter email"
          onChange={(e) => captureData("email", e.target.value)}
        />
        <CustomInput
          inputLabel="Enter Password"
          required
          type="password"
          placeholder="Enter Password"
          onChange={(e) => captureData("password", e.target.value)}
        />
        <Button
          sx={{ mt: 1 }}
          fullWidth
          variant="contained"
          color="secondary"
          onClick={loginUser}
        >
          Log In
        </Button>
        <Typography marginTop={2} color="text.disabled" fontSize={"14px"}>
          Don't have an account?{" "}
          <Button onClick={() => navigate("/signup")}>Sign up</Button>
        </Typography>
        <Typography marginTop={2} color="text.disabled" fontSize={"14px"}>
          Forgot password?{" "}
          <Button onClick={() => navigate("/forgot-password")}>Click Here</Button>
        </Typography>
      </Stack>
    </Box>
  );
}
