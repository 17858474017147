import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { rows } from "./BookingsTable";
import { IconButton, Stack } from "@mui/material";
import { Block, Check, Close } from "@mui/icons-material";

const columns: GridColDef<(typeof rows)[number]>[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "bookedBy",
    headerName: "Student name",
    width: 200,
  },
	{
    field: "address",
    headerName: "Address",
    width: 250,
  },
  
  {
    field: "board",
    headerName: "Board",
    width: 150,
  },
  {
    field: "class",
    headerName: "Class",
    width: 100,
  },
  {
    field: "date",
    headerName: "Registered On",
    width: 150,
  },
  {
    field: "bookings",
    headerName: "Bookings",
    width: 150,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 150,
    headerAlign: "center",
    renderCell: (params: any) => {
      return (
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <IconButton sx={{color: "red"}}>
            <Block />
          </IconButton>
        </Stack>
      );
    },
  },
];

const data = rows.map((_)=>({..._, board:"CBSE", bookings: 5, class: "1-5", address: "Exhibition Road, 800001"}))

export default function StudentsTable({ searchText }: { searchText: string }) {
  return (
    <DataGrid
      sx={{
        color: "black",
        "-webkit-text-fill-color": "black",
        fontSize: "1rem",
        fontWeight: "normal",
				mt: 2,
                "& .MuiDataGrid-footerContainer": {
					height: "40px"
				}
      }}
      filterModel={{
        items: [{ field: 'bookedBy', operator: 'contains', value: searchText }],
      }}
      rows={data}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      autoHeight
      pageSizeOptions={[10]}
      disableRowSelectionOnClick
      disableColumnMenu
      rowHeight={40}
      columnHeaderHeight={40}
    />
  );
}
