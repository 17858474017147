import { useEffect, useState } from "react";
import CustomTabs from "../../components/CustomTabs";
import { Box, Button, IconButton, Stack, TextField } from "@mui/material";
import BookingsTable from "./tables/BookingsTable";
import TutorsTable from "./tables/TutorsTable";
import StudentsTable from "./tables/StudentsTable";
import { Add, ArrowBack, Cancel, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllBookings } from "../../reducers/bookingSlice";

export default function Admin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("bookings");
  const [searchText, setSearchText] = useState("");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const tabs = [
    { label: "Bookings", value: "bookings" },
    { label: "Tutors", value: "tutors" },
    { label: "Students", value: "students" },
  ];

  //  useEffect(() => {
  //   dispatch(getAllBookings() as any);
  // }, []);
  
  return (
    <Box bgcolor={"primary.main"} p={2} borderRadius={2}>
      <Stack spacing={1} direction={"row"} justifyContent={"space-between"}>
        <Button
          onClick={() => {
            navigate("/");
          }}
          sx={{ width: "max-content", mb: 1, alignSelf: "flex-start" }}
          variant="text"
        >
          <ArrowBack fontSize="small" /> Back
        </Button>
        <TextField
          InputProps={{
            endAdornment: searchText && (
              <IconButton
                onClick={() => {
                  setSearchText("");
                }}
                disableRipple
                sx={{ p: 0 }}
              >
                <Cancel />
              </IconButton>
            ),
          }}
          size="small"
          fullWidth
          sx={{ maxWidth: 500 }}
          onChange={(e) => {
            setSearchText(() => e.target.value);
          }}
          value={searchText}
        />
        {/* <Button
          variant="outlined"
          size="small"
          sx={{ height: "max-content", minWidth: 80 }}
          startIcon={<Add />}
          onClick={() => navigate("/add-tutor")}
        >
          Tutor
        </Button> */}
      </Stack>
      <CustomTabs tabs={tabs} value={activeTab} onChange={handleTabChange} />
      {activeTab === "bookings" && <BookingsTable searchText={searchText} />}
      {activeTab === "tutors" && <TutorsTable searchText={searchText} />}
      {activeTab === "students" && <StudentsTable searchText={searchText} />}
    </Box>
  );
}
