import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { ArrowBack, LocationCity, Place, Search } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import { login } from "../../reducers/authSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { tutors } from "../home/Home";
import TutorCard from "../../components/TutorCard";
import { useSelector } from "react-redux";
import {
  SearchState,
  getClasses,
  getAreaCode,
  getSelectedClass,
  getSelectedSubject,
  searchTutors,
  setSearchValues,
  getSelectedBoard,
  getAreaPinCode,
  getSubjectId,
  getSubjectName,
  getAreaName,
} from "../../reducers/searchSlice";
import { getAreas, getSubjects } from "../../reducers/homeSlice";

export default function SearchTutors() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchValues = {
    class: useSelector(getSelectedClass),
    subject: useSelector(getSelectedSubject),
    areaCode: useSelector(getAreaCode),
    board: useSelector(getSelectedBoard),
    pincode: useSelector(getAreaPinCode),
    subjectId:useSelector(getSubjectId),
    subjectName: useSelector(getSubjectName),
    areaName: useSelector(getAreaName),
  };
  const classes = useSelector(getClasses);
  const subjects = useSelector(getSubjects);
  const areas = useSelector(getAreas);

  const captureData = (type: string, value: string) => {
    dispatch(
      setSearchValues({
        ...searchValues,
        [type]: value,
      })
    );
  };

  const captureLocationData = (areaCode: string, pincode: string, areaName: string) => {
    dispatch(
      setSearchValues({
        ...searchValues,
        areaCode,
        pincode,
        areaName
      })
    );
  };

  const captureSubjectsData = (subject: string, subjectId: string, subjectName: string) => {
    dispatch(
      setSearchValues({
        ...searchValues,
        subject,
        subjectId,
        subjectName
      })
    );
  };

  const search = () => {
    if (location.pathname !== "search-tutors") navigate("/search-tutors");
    dispatch(
      searchTutors({
        subject: searchValues.subject,
        grade: searchValues.class,
        board: searchValues.board,
        areaCode: searchValues.areaCode.toString(),
        areaPinCode: searchValues.pincode.toString(),
      }) as any
    );
  };

  return (
    <Stack
      direction={{ xs: "column", sm: "column", md: "row" }}
      p={{ xs: 3, sm: 3, md: 1 }}
      border={{ xs: "none", sm: "none", md: "6px solid cadetblue" }}
      borderRadius={{ xs: 2, sm: 2, md: "999px" }}
      spacing={1.5}
      sx={{
        background: {
          xs: "rgb(23,118,148,.9)",
          sm: "rgb(23,118,148,.9)",
          md: "rgba(255,255,255,0.8)",
        },
      }}
    >
      <Typography
        display={{ xs: "initial", sm: "initial", md: "none" }}
        fontSize={"20px"}
        fontWeight={700}
        color={"white"}
      >
        Search Tutors
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        spacing={1.5}
        width={{ xs: "100%", sm: "100%", md: "auto" }}
      >
        <Autocomplete
          fullWidth
          disablePortal
          id="combo-box-demo"
          options={boards as any}
          size="medium"
          getOptionLabel={(Option) => Option}
          renderInput={(params) => (
            <TextField {...params} placeholder="Board" />
          )}
          ListboxProps={{
            sx: {
              background: "#ffffff",
              border: "1px solid white",
              borderRadius: "2px",
            },
          }}
          sx={{
            background: { xs: "white", sm: "white", md: "none" },
            borderRadius: "10px",
            "& .MuiFormControl-root": {
              mb: 0,
              //border: {xs: "1px solid", sm: "1px solid", md: "none"},
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderRadius: "10px",
            },
            minWidth: 180,
          }}
          value={boards.find((_) => _ === searchValues.board)}
          onChange={(event: any, newValue: any) => {
            captureData("board", newValue);
          }}
        />
        <Divider
          sx={{
            bgcolor: "white",
            width: "1px",
            display: { xs: "none", sm: "none", md: "initial" },
          }}
        />
        {/* </Stack> */}
        <Autocomplete
          fullWidth
          disablePortal
          id="combo-box-demo"
          options={classes as any}
          size="medium"
          getOptionLabel={(Option) => Option}
          renderInput={(params) => (
            <TextField {...params} placeholder="Class" />
          )}
          ListboxProps={{
            sx: {
              background: "#ffffff",
              border: "1px solid white",
              borderRadius: "2px",
            },
          }}
          sx={{
            background: { xs: "white", sm: "white", md: "none" },
            borderRadius: "10px",
            "& .MuiFormControl-root": {
              mb: 0,
              //border: {xs: "1px solid", sm: "1px solid", md: "none"},
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderRadius: "10px",
            },
            minWidth: 180,
          }}
          value={classes.find((_) => _ === searchValues.class)}
          onChange={(event: any, newValue: any) => {
            captureData("class", newValue);
          }}
          disabled={!searchValues.board}
        />
      </Stack>
      <Divider
        sx={{
          bgcolor: "white",
          width: "1px",
          display: { xs: "none", sm: "none", md: "initial" },
        }}
      />
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        spacing={1.5}
        width={{ xs: "100%", sm: "100%", md: "auto" }}
      >
        <Autocomplete
          fullWidth
          disablePortal
          id="combo-box-demo"
          options={
            subjects.filter(
              (_) =>
                _.boardCode === searchValues.board &&
                _.gradeCode === searchValues.class
            ) as any
          }
          size="medium"
          getOptionLabel={(Option) => Option.subjectName}
          renderInput={(params) => (
            <TextField {...params} placeholder="Subject" />
          )}
          ListboxProps={{
            sx: {
              background: "#ffffff",
              border: "1px solid white",
              borderRadius: "2px",
            },
          }}
          sx={{
            background: { xs: "white", sm: "white", md: "none" },
            borderRadius: "10px",
            "& .MuiFormControl-root": {
              mb: 0,
              //border: {xs: "1px solid", sm: "1px solid", md: "none"},
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderRadius: "10px",
            },
            minWidth: 180,
          }}
          value={subjects.find(
            (_) =>
              _.subjectCode.toString() === searchValues.subject &&
              _.boardCode === searchValues.board &&
              _.gradeCode === searchValues.class
          )}
          onChange={(event: any, newValue: any) => {
            captureSubjectsData(newValue?.subjectCode.toString(), newValue?.id.toString(), newValue?.subjectName.toString());
          }}
          disabled={!searchValues.board || !searchValues.class}
        />
        <Divider
          sx={{
            bgcolor: "white",
            width: "1px",
            display: { xs: "none", sm: "none", md: "initial" },
          }}
        />
        <Autocomplete
          fullWidth
          disablePortal
          id="combo-box-demo"
          options={areas as any}
          size="medium"
          getOptionLabel={(Option) =>
            Option.areaName + " " + Option.areaPinCode
          }
          renderInput={(params) => <TextField {...params} placeholder="Area" />}
          ListboxProps={{
            sx: {
              background: "#ffffff",
              border: "1px solid white",
              borderRadius: "2px",
            },
          }}
          sx={{
            background: { xs: "white", sm: "white", md: "none" },
            borderRadius: "10px",
            "& .MuiFormControl-root": {
              mb: 0,
              //border: {xs: "1px solid", sm: "1px solid", md: "none"},
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderRadius: "10px",
            },
            minWidth: 180,
          }}
          value={areas.find((_) => _.areaCode === searchValues.areaCode)}
          onChange={(event: any, newValue: any) => {
            captureLocationData(newValue.areaCode, newValue.areaPinCode, newValue.areaName);
          }}
          disabled={
            !searchValues.board || !searchValues.class || !searchValues.subject
          }
        />
      </Stack>
      <IconButton
        sx={{
          // height: "max-content",
          width: "max-content",
          // flex: { xs: 1, sm: 0.5, md: 0.5 },
          alignSelf: "center",
          // marginTop: 4,
          background: "linear-gradient(to right, #177694, #9c3)",
          color: "white",
          display: { xs: "none", sm: "none", md: "flex" },
          ":disabled": {
            background: "gray",
            cursor: "not-allowed",
          },
        }}
        //size="small"
        disabled={
          !searchValues.class ||
          !searchValues.subject ||
          !searchValues.areaCode ||
          !searchValues.board
        }
        onClick={search}
      >
        <Search sx={{ color: "white" }} />
      </IconButton>
      <Button
        //startIcon={<Search />}
        sx={{
          height: "56px",
          display: { xs: "flex", sm: "flex", md: "none" },
          background: "#00ad5f",
          fontWeight: 700,
          borderRadius: "10px",
          fontSize: "20px",
          ":hover": { background: "#00994b", fontSize: "24px" },
        }}
        fullWidth
        variant="contained"
        color="secondary"
        onClick={search}
        disabled={
          !searchValues.class ||
          !searchValues.subject ||
          !searchValues.areaCode ||
          !searchValues.board
        }
      >
        <Search /> Search
      </Button>
    </Stack>
    // </Stack>
  );
}

const boards = ["CBSE", "ICSE", "BSEB"];
