import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Teacher from "../../assets/teacher.jpg";
import TutorAvatar from "../../assets/avatar.jpg";
import { Avatar, Button, Chip, Rating, Stack } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../reducers/authSlice";
import {
  getTeacher,
  getTeacherArea,
  getTeacherDetails,
  getTeacherSubjects,
} from "../../reducers/teacherSlice";
import { useEffect, useState } from "react";
import ConfirmBooking from "./ConfirmBooking";
import { BookingDetailsData, serverUrl } from "../../common/types";
import { useDispatch } from "react-redux";
import { saveBooking, sendMail } from "../../reducers/bookingSlice";
import {
  getSelectedClass,
  getSelectedSubject,
  getSelectedBoard,
  getAreaCode,
  getAreaPinCode,
  getAreaName,
  getSubjectName,
  getSubjectId,
} from "../../reducers/searchSlice";
import GuestBooking from "./GuestBooking";

const sampleText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

export default function BookTutor() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, name, roles } = useSelector(getUserDetails);
  const imageSrc = Teacher;
  const teacher = useSelector(getTeacher);
  const selectedAreas = useSelector(getTeacherArea);
  const selectedSubjects = useSelector(getTeacherSubjects);
  const [confirm, setConfirm] = useState(false);
  const { user } = useSelector(getUserDetails);
  const [searchParams] = useSearchParams();
  const teacherId = searchParams.get("id") || "";
  const [open, setOpen] = useState(false);
  const {
    addressLine1,
    addressLine2,
    areaCode,
    areaName,
    city,
    country,
    dateOfBirth,
    email,
    fullName,
    gender,
    phoneNumber,
    pinCode,
    state,
    teacherQualification,
    userName,
    id,
  } = teacher;
  const searchValues = {
    class: useSelector(getSelectedClass),
    subject: useSelector(getSelectedSubject),
    areaCode: useSelector(getAreaCode),
    board: useSelector(getSelectedBoard),
    pincode: useSelector(getAreaPinCode),
    areaName: useSelector(getAreaName),
    subjectName: useSelector(getSubjectName),
    subjectId: useSelector(getSubjectId),
  };

  const [data, setData] = useState<BookingDetailsData>({
    studentUserId: user?.id || "",
    studentUserName: user?.userName || "",
    studentFullName: user?.fullName || "",
    teacherUserId: id || "",
    teacherUserName: userName,
    teacherFullName: fullName,
    requestedSubjectId: searchValues?.subjectId || "",
    requestedSubjectName: searchValues?.subjectName || "",
    requestedAreaName: searchValues?.areaName || user?.areaName,
    requestedAreaCode: searchValues?.areaCode.toString() || user?.areaCode,
    requestedPinCode: searchValues?.pincode.toString() || user?.pinCode,
    requestedState: "Bihar",
    requestedCity: "Patna",
    requestedCountryCode: "IN",
    requestedCountry: "India",
    requestedStudentGrade: searchValues?.class || user?.studentGrade,
    studentEmail: user?.email || "",
    studentPhoneNumber: user?.phoneNumber || "",
    teacherEmail: email || "",
    teacherPhoneNumber: phoneNumber || "",
    bookingStatus: "Pending",
    registrationAmount: "",
    registrationStatus: "",
    createDate: new Date().toISOString(),
    updateDate: new Date().toISOString(),
    createUserId: "",
    updateUserId: "",
    paymentStartDate: new Date().toISOString(),
    paymentEndDate: new Date().toISOString(),
    sentToTeacher: "",
    enrollmentFee: 0,
    enrollmentStatus: "",
    demoStatus: "Pending",
    studentBoard: searchValues?.board || "CBSE",
    showHideFlag: "",
    adminNotes: "",
    teacherRating: 0,
    teacherFeedback: "",
    isDeleted: "N",
  });

  const confirmBooking = () => {
    const subject = "New Booking Request";
    const content1 = `<html>
                      <body>
                          <table>
                              <tr>
                                  <h2>TutorForSure</h2>
                              </tr>
                          </table>
                          <div style="background: linear-gradient(to right, #177694, #9c3); width: 100%; height: 8px;"></div>
                          <p>Hi,</p>
                          <p>We received a new booking request from ${data.studentFullName}</p>
                          <p>Booking Details:</p>
                          <p>Booked By: ${data.studentFullName}</p>
                          <p>Tutor Name: ${data.teacherFullName}</p>
                          <p>Board: ${data.studentBoard}</p>
                          <p>Class: ${data.requestedStudentGrade}</p>
                          <p>Subject: ${data.requestedSubjectName}</p>
                          <p>Booked On: ${data.createDate}</p>
                          </br>
                          <p>Please contact the Support Team to get the student's contact details.</p>
                          <p>+91-9241550824</p>
                          <a href="mailto:info.tutorforsure@gmail.com">info.tutorforsure@gmail.com</a>
                          </br>
                          <p>TutorForSure will contact you shortly to confirm this booking.</p>
                          </br>
                          </br>
                          <p style="font-weight: bold">Thank & Regards</p>
                          </br>
                          <p style="font-weight: bold">TutorForSure</p>
                      </body>
                  </html>`;

    const content2 = `<html>
                  <body>
                      <table>
                          <tr>
                              <h2>TutorForSure</h2>
                          </tr>
                      </table>
                      <div style="background: linear-gradient(to right, #177694, #9c3); width: 100%; height: 8px;"></div>
                      <p>Hi,</p>
                      <p>There is a new booking request from ${data.studentFullName}</p>
                      <p>Booking Details:</p>
                      <p>Booked By: ${data.studentFullName}</p>
                      <p>Tutor Name: ${data.teacherFullName}</p>
                      <p>Board: ${data.studentBoard}</p>
                      <p>Class: ${data.requestedStudentGrade}</p>
                      <p>Subject: ${data.requestedSubjectName}</p>
                      <p>Student Email: ${data.studentEmail}</p>
                      <p>Student Phone No.: ${data.studentPhoneNumber}</p>
                      <p>Tutor Email: ${data.teacherEmail}</p>
                      <p>Tutor Phone No.: ${data.teacherPhoneNumber}</p>
                      <p>Booked On: ${data.createDate}</p>
                      </br>
                      <p>Please contact the tutor and share the student's contact details to confirm this booking.</p>
                      </br
                      <p>Thank you!</p>
                  </body>
              </html>`;
    dispatch(
      saveBooking({
        data,
        callback: () => {
          setConfirm(false);
          dispatch(
            sendMail({
              email: data.teacherEmail,
              subject,
              content: content1,
              callback: () => {
                dispatch(
                  sendMail({
                    email: "info.tutorforsure@gmail.com",
                    subject,
                    content: content2,
                  }) as any
                );
              },
            }) as any
          );
        },
      }) as any
    );
  };

  const handleClose = () => {
    setOpen(false);
  }

  return confirm ? (
    <ConfirmBooking
      bookingDetails={data}
      captureBookingDetails={setData}
      onCancel={() => {
        setConfirm(false);
        navigate("/");
      }}
      onConfirm={confirmBooking}
    />
  ) : (
    <Box
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          justifyContent: "space-around",
          maxWidth: 900,
          width: "100%",
          borderRadius: 1,
        }}
        bgcolor={"primary.main"}
      >
        <Button
          onClick={() => {
            navigate(-1);
          }}
          sx={{ width: "max-content", mb: 1 }}
          variant="text"
        >
          <ArrowBack fontSize="small" /> Back
        </Button>
        <Stack spacing={4} direction={"row"} alignItems={"center"}>
          <Avatar
            sx={{
              width: 150,
              height: 150,
              borderRadius: "8px",
              objectFit: "none",
            }}
            variant="square"
            src={`${serverUrl}/api/Files/image/${id}` || TutorAvatar}
          />
          <Stack>
            <Typography fontSize={"16px"}>{fullName}</Typography>
            <Typography fontSize={"12px"} color="text.secondary">
              Qualification: {teacherQualification}
            </Typography>
            <Typography fontSize={"12px"} color="text.secondary">
              Locality: {areaName} - {pinCode}
            </Typography>
            <Rating size="small" name="read-only" value={10} readOnly />
            <Stack
              direction={{ sm: "column", xs: "column", md: "row" }}
              alignItems={"center"}
              justifyContent={"center"}
              spacing={1}
              mt={1}
              width={"min-content"}
            >
              <Button
                disableRipple
                sx={{ width: "max-content", mt: 1 }}
                variant="outlined"
                onClick={() => {
                  if (!isAuthenticated) navigate("/login?prev=book-tutor");
                  else setConfirm(true);
                }}
                disabled={roles.includes("Teacher")}
              >
                {roles.length > 0 ? "Book Now" : "Login to book now"}
              </Button>
              {!roles.length && (
                <>
                  <Typography>Or</Typography>
                  <Button
                    disableRipple
                    sx={{ width: "max-content", mt: 1 }}
                    variant="text"
                    onClick={() => {
                      setOpen(true);
                    }}
                    disabled={roles.includes("Teacher")}
                  >
                    Book as a guest
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            flex: "1 0 auto",
            display: "flex",
            flexDirection: "column",
            padding: 0,
            paddingTop: 2,
          }}
          spacing={1}
        >
          <>
            <Typography>
              <span style={{ color: "gray" }}>Name:</span> {fullName}
            </Typography>
            <Typography>
              <span style={{ color: "gray" }}>Qualification:</span>{" "}
              {teacherQualification}
            </Typography>
            <Typography>
              <span style={{ color: "gray" }}>Experience:</span> 4+ Years
            </Typography>
            <Typography>
              <span style={{ color: "gray" }}>Gender:</span> {gender}
            </Typography>
          </>

          <Stack
            useFlexGap
            flexWrap={"wrap"}
            direction={"row"}
            width={"100%"}
            spacing={"10px"}
          >
            <Typography color={"gray"}>Preferred teaching areas:</Typography>
            {selectedAreas.map(
              (_) =>
                _.isDeleted === "N" && (
                  <Chip
                    sx={{
                      background: "#177694",
                      borderRadius: 1,
                      color: "#fff",
                      fontWeight: 600,
                      "& .MuiChip-deleteIcon": { color: "#fff" },
                    }}
                    key={
                      _.teachingAreaName +
                      " " +
                      "(" +
                      _?.teachingAreaPinCode +
                      ")"
                    }
                    label={
                      _.teachingAreaName +
                      " " +
                      "(" +
                      _?.teachingAreaPinCode +
                      ")"
                    }
                  />
                )
            )}
          </Stack>
          <Stack
            useFlexGap
            flexWrap={"wrap"}
            direction={"row"}
            width={"100%"}
            spacing={"10px"}
            //mt={1}
          >
            <Typography color={"gray"}>Subjects expertise:</Typography>
            {selectedSubjects.map(
              (_, i) =>
                _.isDeleted === "N" && (
                  <Chip
                    sx={{
                      background: "#177694",
                      borderRadius: 1,
                      color: "#fff",
                      fontWeight: 600,
                      "& .MuiChip-deleteIcon": { color: "#fff" },
                      height: "auto",
                      py: 1,
                    }}
                    label={
                      <Stack direction={"row"} spacing={2}>
                        <Stack>
                          <Typography fontSize={"10px"}>Board</Typography>
                          <Typography fontSize={"12px"}>
                            {_.boardCode}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography fontSize={"10px"}>Class</Typography>
                          <Typography fontSize={"12px"}>
                            {_.gradeCode}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography fontSize={"10px"}>Subject</Typography>
                          <Typography fontSize={"12px"}>
                            {_.subjectName}
                          </Typography>
                        </Stack>
                      </Stack>
                    }
                  />
                )
            )}
          </Stack>
        </Stack>
      </Stack>
      <GuestBooking
        open={open}
        grade={searchValues.class}
        board={searchValues.board}
        area={searchValues.areaName}
        subject={searchValues.subjectName}
        teacherName={fullName}
        teacherEmail={email}
        teacherPhoneNumber={phoneNumber}
        onClose={handleClose}
      />
    </Box>
  );
}
