import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Teacher from "../../../assets/teacher.jpg";
import TutorAvatar from "../../../assets/avatar.jpg";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Grid,
  IconButton,
  Rating,
  Stack,
} from "@mui/material";
import { ArrowBack, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CustomTabs from "../../../components/CustomTabs";
import General from "./details/General";
import Area from "./details/Area";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../../reducers/authSlice";
import { SubjectsMappingData, serverUrl } from "../../../common/types";
import SubjectsMapping from "../SubjectsMapping";
import {
  addNewSubjects,
  getTeacherDetails,
} from "../../../reducers/teacherSlice";
import imageCompression from "browser-image-compression";
import { updateImage } from "../../../reducers/homeSlice";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function EditTutor() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUserDetails);
  const [activeTab, setActiveTab] = useState("general");
  const [subjectsMapping, setSubjectsMapping] = useState<SubjectsMappingData[]>(
    [
      {
        subjects: [],
        gradeCode: "",
        popularityLevel: "9",
        boardCode: "CBSE",
        isDeleted: "N",
      },
    ]
  );
  const [image, setImage] = useState<any>(
    `${serverUrl}/api/Files/image/${user.user.id}` || TutorAvatar
  );

  const handleImageChange = async (event: any) => {
    const imageFile = event.target.files[0];
    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 400,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      setImage(URL.createObjectURL(compressedFile));
      dispatch(
        updateImage({
          userId: user.user.id,
          data: compressedFile,
          callback: () => window.location.reload(),
        }) as any
      );
    } catch (error) {
      console.error("Error while compressing image:", error);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const tabs = [
    { label: "General", value: "general" },
    { label: "Area", value: "area" },
    { label: "Subjects", value: "subjects" },
  ];

  const addSubjects = () => {
    let data: any[] = [];
    subjectsMapping.forEach((_) => {
      _.subjects.forEach((s) => {
        data.push({
          subjectCode: s.subjectCode,
          gradeCode: _.gradeCode,
          popularityLevel: _.popularityLevel,
          boardCode: _.boardCode,
          isDeleted: "N",
          subjectName: s.subjectName,
        });
      });
    });
    dispatch(
      addNewSubjects({
        id: user.user.id,
        data,
        callback: () => {
          dispatch(getTeacherDetails({ teacherId: user.user.id }) as any);
          setSubjectsMapping([]);
        },
      }) as any
    );
  };

  return (
    <Box
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: 3,
          justifyContent: "space-around",
          maxWidth: 900,
          width: "100%",
          borderRadius: 1,
        }}
        bgcolor={"primary.main"}
        spacing={4}
        direction={{ xs: "column", sm: "row", md: "row" }}
      >
        <Stack direction={"column"} alignItems={"center"}>
          <Button
            onClick={() => {
              navigate("/");
            }}
            sx={{ width: "max-content", mb: 1, alignSelf: "flex-start" }}
            variant="text"
          >
            <ArrowBack fontSize="small" /> Back
          </Button>
          <Badge
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <IconButton
                sx={{
                  background: "linear-gradient(to right, #177694, #9c3)",
                  ":hover": { color: "rgb(0, 237, 100)" },
                }}
                color="secondary"
                size="small"
                component="label"
                role={undefined}
                tabIndex={-1}
              >
                <Edit />
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </IconButton>
            }
          >
            <Avatar
              sx={{ width: 150, height: 150, borderRadius: "8px" }}
              variant="square"
              src={image}
            />
          </Badge>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          flex={1}
        >
          <Stack
            sx={{
              flex: "1 0 auto",
              display: "flex",
              flexDirection: "column",
              padding: 0,
              paddingTop: 2,
            }}
          >
            <CustomTabs
              tabs={tabs}
              value={activeTab}
              onChange={handleTabChange}
            />
            <Divider sx={{ bgcolor: "#ffffff80", width: "100%", mb: 2 }} />
            {activeTab === "general" && <General data={user} />}
            {activeTab === "area" && <Area />}
            {activeTab === "subjects" && (
              <Stack direction={"column"} spacing={1}>
                <SubjectsMapping
                  subjectsMapping={subjectsMapping}
                  setSubjectsMapping={setSubjectsMapping}
                />
                <Button
                  onClick={addSubjects}
                  sx={{ width: "max-content" }}
                  variant="contained"
                >
                  Update
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

const bookings = [
  {
    name: "Student 1",
    address: "Exhibition Road, Patna - 800001",
    subject: "Maths",
    class: "Class 6",
    bookedOn: "04/04/2024",
    status: "pending",
    bookingId: "123456",
    phoneNo: "**********",
  },
  {
    name: "Student 1",
    address: "Exhibition Road, Patna - 800001",
    subject: "Maths",
    class: "Class 6",
    bookedOn: "04/04/2024",
    status: "pending",
    bookingId: "1234567",
    phoneNo: "**********",
  },
  {
    name: "Student 1",
    address: "Exhibition Road, Patna - 800001",
    subject: "Maths",
    class: "Class 6",
    bookedOn: "04/04/2024",
    status: "pending",
    bookingId: "1234568",
    phoneNo: "**********",
  },
  {
    name: "Student 1",
    address: "Exhibition Road, Patna - 800001",
    subject: "Maths",
    class: "Class 6",
    bookedOn: "04/04/2024",
    status: "pending",
    bookingId: "1234569",
    phoneNo: "**********",
  },
];
