import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { IconButton } from "@mui/material";
import { Banner1 } from "../pages/banners/Banner1";

//const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ImageBox = ({ text }: { text: string }) => {
  return (
    <Box
      sx={{
        background: "linear-gradient(to right, #177694, #9c3)",
        fontSize: "50px",
        fontWeight: "bold",
        height: "350px",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "text.secondary",
      }}
    >
      {text}
    </Box>
  );
};

const images = [
  {
    label: "Image 1",
  },
  // {
  //   label: "Image 2",
  // },
  // {
  //   label: "Image 3",
  // },
  // {
  //   label: "Image 4",
  // },
];

function Carousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        autoPlay={false}
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {index===0?<Banner1 />:
            <ImageBox text={step.label} />}
          </div>
        ))}
      </SwipeableViews>
      {/* <MobileStepper
        sx={{ position: "relative", top: "48px", background: "none", display: "none" }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <IconButton
            sx={{ bgcolor: "rgba(0, 0, 0, 0.04)", color: "white" }}
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
        }
        backButton={
          <IconButton
            sx={{ bgcolor: "rgba(0, 0, 0, 0.04)", color: "white" }}
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
        }
      /> */}
    </Box>
  );
}

export default Carousel;
