// authSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { serverUrl } from "../common/types";

export interface BookingsState {
  bookingDetails: any[];
  allBookings: any[];
  bookingsLoading: boolean;
}

export const getBookingDetails = createAsyncThunk(
  "bookings/getBookingDetails",
  async (
    {
      id,
    }: {
      id: string;
    },
    { rejectWithValue }
  ) => {
    const res = await fetch(`${serverUrl}/api/Admin/bookings/${id}`);
    const data = await res.json();
    return data;
  }
);

export const getAllBookings = createAsyncThunk(
  "bookings/getAllBookings",
  async ({ callback }: { callback?: any }) => {
    const res = await fetch(`${serverUrl}/api/Admin/bookings`);
    const data = await res.json();
    if(callback) callback();
    return data;
  }
);

export const saveBooking = createAsyncThunk(
  "bookings/saveBooking",
  async (
    { data, callback }: { data: any; callback?: any },
    { rejectWithValue }
  ) => {
    try {
      const url = `${serverUrl}/api/Admin/bookings/saveDetails`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
        }),
      });
      const result = await res.json();
      if (callback && result?.isSuccess) callback();
      return result;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

export const updateBooking = createAsyncThunk(
  "bookings/updateBooking",
  async (
    { id, data, callback }: { id: any; data: any; callback?: any },
    { rejectWithValue }
  ) => {
    try {
      const url = `${serverUrl}/api/Admin/bookings/UpdateBookingDetails/${id}`;
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await res.json();
      if (callback) callback();
      return result;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

export const sendMail = createAsyncThunk(
  "bookings/sendMail",
  async (
    { email, subject, content, callback }: { email: string; subject: string; content: string; callback?: any },
    { rejectWithValue }
  ) => {
    try {
      const url = `${serverUrl}/api/Admin/user/SendEmail`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({to: email, subject, content}),
      });
      const result = await res.json();
      if (callback) callback();
      return result;
    } catch (error: any) {
      rejectWithValue(error.message);
    }
  }
);

const initialState: BookingsState = {
  bookingDetails: [],
  allBookings: [],
  bookingsLoading: false,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBookingDetails.pending, (state, { payload }) => {
        return { ...state, bookingsLoading: true };
      })
      .addCase(getBookingDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          bookingDetails: payload?.response || [],
          bookingsLoading: false
        };
      })
      .addCase(getBookingDetails.rejected, (state, { payload }) => {
        return { ...state, bookingDetails: [], bookingsLoading: false };
      })
      .addCase(getAllBookings.pending, (state, { payload }) => {
        return { ...state, bookingsLoading: true };
      })
      .addCase(getAllBookings.fulfilled, (state, { payload }) => {
        return {
          ...state,
          allBookings: payload?.response || [],
          bookingsLoading: false,
        };
      })
      .addCase(getAllBookings.rejected, (state, { payload }) => {
        return { ...state, allBookings: [], bookingsLoading: false };
      })
      .addCase(saveBooking.pending, (state, { payload }) => {
        return { ...state, bookingsLoading: true };
      })
      .addCase(saveBooking.fulfilled, (state, { payload }) => {
        return {
          ...state,
          bookingsLoading: false,
        };
      })
      .addCase(saveBooking.rejected, (state, { payload }) => {
        return { ...state, allBookings: [], bookingsLoading: false };
      })
      .addCase(updateBooking.pending, (state, { payload }) => {
        return { ...state, bookingsLoading: true };
      })
      .addCase(updateBooking.fulfilled, (state, { payload }) => {
        return {
          ...state,
          bookingsLoading: false,
        };
      })
      .addCase(updateBooking.rejected, (state, { payload }) => {
        return { ...state, allBookings: [], bookingsLoading: false };
      })
      .addCase(sendMail.pending, (state, { payload }) => {
        return { ...state, bookingsLoading: true };
      })
      .addCase(sendMail.fulfilled, (state, { payload }) => {
        return {
          ...state,
          bookingsLoading: false,
        };
      })
      .addCase(sendMail.rejected, (state, { payload }) => {
        return { ...state, bookingsLoading: false };
      });
  },
});

export const getUsersBookings = (state: { booking: BookingsState }) =>
  state.booking.bookingDetails;
export const getAllBookingDetails = (state: { booking: BookingsState }) =>
  state.booking.allBookings;
export const getBookingsLoading = (state: { booking: BookingsState }) =>
  state.booking.bookingsLoading;

export default bookingSlice.reducer;
