/**  * Multi Select dropdown styles - It is used to create styles for Multi Select Dropdown component.  */ 
//Third party imports
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

export const StyledAutocomplete = styled(Autocomplete)(
  ({ theme, disabled }) => ({
    border: `1px solid `,
    borderRadius: "4px",
    outline: "none",
    alignItems: "left",
    justifyContent: "center",
    fontSize: "14px",
    background: disabled ? "none" : "#ffffff",
    "& .MuiTextField-root": { height: "100%" },
    ":hover": {
      border: disabled
        ? `1px solid grey`
        : `1px solid #177694`,
    },
    "& .MuiInputBase-root": { color: "darkgrey" },
    "& ..Mui-disabled": { background: "transparent" },
    "& .MuiAutocomplete-inputRoot": {
      paddingLeft: "10px",
      height: "inherit",
      minHeight: "40px",
      maxHeight: "70px",
      overflowX: "hidden",
      overflowY: "auto",
    },
    "& .MuiAutocomplete-tag": {
      border: "1px solid #177694",
      borderRadius: "4px",
      background: "#F3F4F6",
      height: "28px",
    },
    "& .MuiChip-label": { color: "#5A5A72", fontSize: "12px", fontWeight: 500 },
    "& .MuiChip-deleteIcon": {
      color: "#5A5A72 !important",
      height: "12px",
      width: "12px",
    },
    "& .MuiAutocomplete-option[data-focus='true']": {
      backgroundColor: "#DDE2F1",
    },
    "& .MuiAutocomplete-endAdornment": { right: "10px" },
    "& .MuiAutocomplete-clearIndicator": { marginRight: 0 },
  })
);

