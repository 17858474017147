import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import { ArrowBack, CloudUpload, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../components/CustomInput";
import TutorAvatar from "../../../assets/avatar.jpg";
import { useState } from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AddTutor() {
  const navigate = useNavigate();
  const [image, setImage] = useState<any>(TutorAvatar);

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: { xs: 2, sm: 2, md: 3 },
          borderRadius: 1,
        }}
        bgcolor={"primary.main"}
        spacing={4}
        direction={{ xs: "column", sm: "column", md: "row" }}
      >
        <Stack direction={"column"} alignItems={"center"}>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            sx={{ width: "max-content", mb: 1, alignSelf: "flex-start" }}
            variant="text"
          >
            <ArrowBack fontSize="small" /> Back
          </Button>
          <Badge
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <IconButton
                sx={{
                  background: "linear-gradient(to right, #177694, #9c3)",
                  ":hover": { color: "rgb(0, 237, 100)" },
                }}
                color="secondary"
                size="small"
                component="label"
                role={undefined}
                tabIndex={-1}
              >
                <Edit />
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </IconButton>
            }
          >
            <Avatar
              sx={{ width: 150, height: 150, borderRadius: "8px" }}
              variant="square"
              src={image}
            />
          </Badge>
        </Stack>
        <Divider
          sx={{ bgcolor: "lightgrey", width: { sm: "100%", md: "1px" } }}
        />
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          spacing={2}
        >
          <Typography>General Settings</Typography>
          <Stack direction={"row"} display={"flex"} width={"100%"} spacing={2}>
            <CustomInput
              sx={{ width: "100%" }}
              fullWidth
              inputLabel="First Name"
              required
            />
            <CustomInput
              sx={{ width: "100%" }}
              fullWidth
              inputLabel="Last Name"
            />
          </Stack>
          <CustomInput
            inputLabel="Phone No."
            required
            placeholder="Enter Phone No."
            contentEditable="false"
          />
          <CustomInput
            inputLabel="Area"
            required
            placeholder="Enter area name"
          />
          <CustomInput
            inputLabel="Postcode"
            required
            placeholder="Enter postcode"
          />
          <Stack direction={"row"} spacing={2}>
            <CustomInput
              inputLabel="Country"
              defaultValue={"India"}
              disabled
              required
            />
            <CustomInput inputLabel="State" defaultValue={"Bihar"} disabled />
          </Stack>
        </Stack>
        <Divider
          sx={{ bgcolor: "lightgrey", width: { sm: "100%", md: "1px" } }}
        />
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          spacing={2}
        >
          <Typography>Professional Settings</Typography>
          <CustomInput
            inputLabel="About"
            required
            multiline
            rows={3}
            placeholder="Enter your professional details"
          />
          <Stack direction={"row"} spacing={2}>
            <CustomInput
              inputLabel="Experience"
              required
              placeholder="Enter experience"
            />
            <CustomInput inputLabel="Board" defaultValue={"CBSE"} required />
          </Stack>
          <CustomInput
            inputLabel="Education"
            required
            placeholder="Enter education details"
          />
          {/* <Stack direction={"column"} spacing={2}>
            <Typography>Upload Resume</Typography>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUpload />}
            >
              Upload file
              <VisuallyHiddenInput type="file" />
            </Button>
          </Stack> */}
          <Button
            sx={{ mt: 1 }}
            fullWidth
            variant="contained"
            color="secondary"
          >
            Add Tutor
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
