import React from "react";
import { StyledAutocomplete } from "./MultiSelect.Style";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" sx={{ fill: "#177694" }} />;

const MultiSelect = ({
  optionField,
  options,
  loading = false,
  getOptionDisabled,
  ...props
}: {
  optionField: string;
  loading?: boolean;
  options: any[];
  getOptionDisabled?: (option: any) => boolean;
} & any): JSX.Element => {
  return (
    <StyledAutocomplete
      multiple
      getOptionDisabled={getOptionDisabled}
      disableCloseOnSelect
      options={options}
      getOptionLabel={(option: any) => option[optionField]}
      renderOption={(props, option: any, { selected }) => (
        <li {...props}>
          {" "}
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />{" "}
          {option[optionField]}{" "}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#177694",
              },
            },
            marginBottom: 0
          }}
        />
      )}
      slotProps={{
        popupIndicator: { disableRipple: true },
        clearIndicator: { disableRipple: true },
      }}
      {...props}
    />
  );
};
export default MultiSelect;
