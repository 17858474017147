// src/components/SnackbarNotification.tsx
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { getNotifications, removeSnackbar } from "../reducers/snackbarSlice";
import { red, orange, blue, green } from '@mui/material/colors';
import { Typography } from "@mui/material";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getStyles = (severity: 'success' | 'info' | 'warning' | 'error') => {
    switch (severity) {
      case 'success':
        return { background: green[700], color: '#fff' };
      case 'info':
        return { background: blue[700], color: '#fff' };
      case 'warning':
        return { background: orange[700], color: '#fff' };
      case 'error':
        return { background: red[700], color: '#fff' };
      default:
        return {};
    }
  };

const SnackbarNotification: React.FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);

  const handleClose =
    (id: number) => (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      dispatch(removeSnackbar(id));
    };

  return (
    <>
      {notifications.map(({ id, message, severity }) => (
        <Snackbar
          key={id}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open
          autoHideDuration={1000}
          onClose={() => handleClose(id)}
        >
          <Alert onClose={handleClose(id)} severity={severity} style={getStyles(severity)}>
            <Typography sx={{"-webkit-text-fill-color": "white"}}>{message}</Typography>
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

export default SnackbarNotification;
