import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import TutorCard from "../../components/TutorCard";
import SearchTutors from "../common/SearchTutors";
import { useSelector } from "react-redux";
import { getTutors } from "../../reducers/searchSlice";
import { ArrowBack } from "@mui/icons-material";
import { useRef, useEffect } from "react";
import { getTeacherDetails } from "../../reducers/teacherSlice";
import { serverUrl } from "../../common/types";

export default function SearchResults() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tutors = useSelector(getTutors);
  const ref = useRef<any>(null);
  const executeScroll = () =>
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });

  useEffect(() => {
    executeScroll();
  }, [ref.current]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Button
        onClick={() => {
          navigate("/");
        }}
        sx={{ width: "max-content", mb: 1, alignSelf: "flex-start" }}
        variant="text"
      >
        <ArrowBack fontSize="small" /> Back
      </Button>
      <SearchTutors />
      <Box ref={ref} width={"100%"}>
        <Typography mt={2}>Search Results:</Typography>
        {!tutors?.length ? (
          <Box
            sx={{
              mt: 2,
              height: 100,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              width: "100%",
              borderRadius: 1,
            }}
            bgcolor={"primary.main"}
          >
            No results found.
          </Box>
        ) : (
          <Grid
            container
            sx={{ paddingTop: "12px" }}
            spacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 4, sm: 9, md: 12 }}
          >
            {tutors.map((_) => (
              <Grid key={_.name} item xs={2} sm={3} md={2}>
                <TutorCard imageSrc={`${serverUrl}/api/Files/image/${_.userId}`}
                  name={_.teacherName}
                  experience={_.teacherQualification}
                  locality={_.areaName}
                  rating={5}
                  onClick={() => {
                    dispatch(getTeacherDetails({
                      teacherId: _.userId,
                      callback: () => navigate(`/book-tutor?id=${_.userId}`),
                    }) as any)
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
}
