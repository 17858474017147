import { createTheme } from "@mui/material";
import { red, orange, blue, green } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff", // your primary color
    },
    secondary: {
      main: "#ffffff", // your secondary color
    },
    text: {
      primary: "#000000", // primary text color
      secondary: "#000000B3", // secondary text color
      disabled: "#00000080",
    },
    background: {
      default: "#f4f4f4", // default background color
    },
  },
  typography: {
    fontFamily: "Nunito",
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.8rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.6,
    },
    body2: {
      fontSize: "1.375rem",
			fontWeight: 700,
      lineHeight: 1.4,
			background: "linear-gradient(to right, #177694, #9c3)",
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
    },
  },
  shape: {
    borderRadius: 8, // default border radius for components
  },
  spacing: 8, // default spacing value (e.g., 8px)
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardError': {
            backgroundColor: red[700],
            color: '#fff',
          },
          '&.MuiAlert-standardWarning': {
            backgroundColor: orange[700],
            color: '#fff',
          },
          '&.MuiAlert-standardInfo': {
            backgroundColor: blue[700],
            color: '#fff',
          },
          '&.MuiAlert-standardSuccess': {
            backgroundColor: green[700],
            color: '#fff',
          },
        },
        filledError: {
          backgroundColor: red[700],
        },
        filledWarning: {
          backgroundColor: orange[700],
        },
        filledInfo: {
          backgroundColor: blue[700],
        },
        filledSuccess: {
          backgroundColor: green[700],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize", // disable text transformation
          fontWeight: 500, // button font weight
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            background: "linear-gradient(to right, #177694, #9c3)", // custom background color for primary contained button
            color: "#ffffff",
            fontSize: "16px",
						boxShadow: "none",
            ":hover": {
              borderRadius: "999px",
              backgroundColor: "rgb(0, 30, 43)",
            },
            borderRadius: "999px",
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            background: "linear-gradient(to right, #177694, #9c3)", // custom background color for primary contained button
            color: "#ffffff",
            fontSize: "16px",
						boxShadow: "none",
            ":hover": {
              borderRadius: "999px",
              backgroundColor: "rgb(0, 30, 43)",
							boxShadow: "none",
            },
            borderRadius: "2px",
          },
        },
        {
          props: { variant: "text", color: "primary" },
          style: {
            backgroundColor: "linear-gradient(to right, #177694, #9c3)", // custom background color for primary contained button
            color: "#177694",
            fontSize: "16px",
						fontWeight: "bold",
            padding: 0,
          },
        },
        {
          props: { variant: "text", color: "secondary" },
          style: {
            backgroundColor: "none", // custom background color for primary contained button
            color: "#9c3",
            fontSize: "16px",
						fontWeight: 600,
            padding: 0,
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            backgroundColor: "none", // custom background color for primary contained button
            color: "#177694",
            fontSize: "16px",
            border: "1px solid #177694",
            borderRadius: "2px",
            ":hover": {
              color: "rgb(0, 30, 43)",
              background: "linear-gradient(to right, #177694, #9c3)",
              border: "1px solid",
              borderRadius: "999px",
            },
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: "16px", // spacing between text fields
          borderRadius: "4px",
					color: 'black',
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "4px",
          },
					"& .MuiOutlinedInput-root": {
						"&.Mui-focused fieldset": {
							borderColor: "#177694"
						}
					}
        },
      },
    },
  },
});
