import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Teacher from "../../assets/teacher.jpg";
import TutorAvatar from "../../assets/avatar.jpg";
import { Avatar, Button, Divider, Grid, Rating, Stack } from "@mui/material";
import { ArrowBack, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomTabs from "../../components/CustomTabs";
import Bookings from "../bookings/Bookings";
import { useDispatch, useSelector } from "react-redux";
import { getTeacherDetails } from "../../reducers/teacherSlice";
import { getUserDetails } from "../../reducers/authSlice";
import { getBookingDetails, getUsersBookings } from "../../reducers/bookingSlice";
import { serverUrl } from "../../common/types";

const sampleText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

export default function Tutor() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookings = useSelector(getUsersBookings);
  const user = useSelector(getUserDetails);
  const {
    addressLine1,
    addressLine2,
    areaCode,
    areaName,
    city,
    country,
    dateOfBirth,
    email,
    fullName,
    gender,
    phoneNumber,
    pinCode,
    state,
    teacherQualification,
    id,
  } = user.user;
  const imageSrc = Teacher;
  const name = "Nishant Kumar";
  const experience = "4 years";
  const rating = 4;
  const locality = "Exhibition Road, Patna - 800001";

  const [activeTab, setActiveTab] = useState("about");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const tabs = [
    { label: "About", value: "about" },
    { label: "Booking Requests", value: "bookings" },
  ];

  useEffect(() => {
    dispatch(getBookingDetails({ id }) as any);
  }, []);
  
  return (
    <Box
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: 3,
          justifyContent: "space-around",
          maxWidth: 900,
          width: "100%",
          borderRadius: 1,
        }}
        bgcolor={"primary.main"}
        spacing={4}
        direction={{ xs: "column", sm: "row", md: "row" }}
      >
        <Stack direction={"column"} alignItems={"center"}>
          <Button
            onClick={() => {
              navigate("/");
            }}
            sx={{ width: "max-content", mb: 1, alignSelf: "flex-start" }}
            variant="text"
          >
            <ArrowBack fontSize="small" /> Back
          </Button>
          <Avatar
            sx={{ width: 150, height: 150, borderRadius: "8px" }}
            variant="square"
            src={`${serverUrl}/api/Files/image/${id}`}
          />
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          flex={1}
        >
          <Stack
            spacing={4}
            direction={"row"}
            //alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack>
              <Typography fontSize={"16px"}>{fullName}</Typography>
              <Typography fontSize={"12px"} color="text.secondary">
                Qualification: {teacherQualification}
              </Typography>
              <Typography fontSize={"12px"} color="text.secondary">
                Locality: {areaName} - {pinCode}
              </Typography>
              <Rating size="small" name="read-only" value={rating} readOnly />
            </Stack>
            <Button
              disableRipple
              sx={{ height: "max-content", mt: 1, minWidth: "max-content" }}
              variant="outlined"
              startIcon={<Edit />}
              onClick={() => {
                dispatch(getTeacherDetails({ teacherId: id }) as any);
                navigate("/edit-tutor-profile");
              }}
            >
              Edit Profile
            </Button>
          </Stack>
          <Divider sx={{ bgcolor: "#ffffff80", width: "100%", mt: 2 }} />
          <Stack
            sx={{
              flex: "1 0 auto",
              display: "flex",
              flexDirection: "column",
              padding: 0,
              paddingTop: 2,
            }}
          >
            <CustomTabs
              tabs={tabs}
              value={activeTab}
              onChange={handleTabChange}
            />
            <Divider sx={{ bgcolor: "#ffffff80", width: "100%", mb: 2 }} />
            {activeTab === "about" && (
              <>
                <Typography>
                  <span style={{ color: "gray" }}>Name:</span> {fullName}
                </Typography>
                <Typography>
                  <span style={{ color: "gray" }}>Email:</span> {email}
                </Typography>
                <Typography>
                  <span style={{ color: "gray" }}>Phone No.:</span>{" "}
                  {phoneNumber}
                </Typography>
                <Typography>
                  <span style={{ color: "gray" }}>Address:</span> {addressLine1}
                  , {addressLine2}, {areaName}, {city} - {pinCode}, {state},{" "}
                  {country}
                </Typography>
                <Typography>
                  <span style={{ color: "gray" }}>Qualification:</span>{" "}
                  {teacherQualification}
                </Typography>
                <Typography>
                  <span style={{ color: "gray" }}>Gender:</span> {gender}
                </Typography>
                <Typography>
                  <span style={{ color: "gray" }}>DOB:</span> {dateOfBirth}
                </Typography>
              </>
            )}
            {activeTab === "bookings" && (
              <Box width={"100%"} flex={1}>
                <Bookings bookings={bookings} />
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
